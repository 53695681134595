import {
  Badge,
  Button,
  Datepicker,
  formatPatientNameStd,
  IconLink,
  InlineAlert,
  InvoiceItems,
  InvoiceItemsProps,
  Link,
  ThemeProvider,
  Typography,
} from "@herohealthsoftware/ui";
import ld from "lodash";
import React, { useEffect, useState } from "react";

import { fetchCustomer } from "../../../../lib/customer";
import { formatISODateTime } from "../../../../lib/datetime";
import { translate } from "../../../../lib/i18n";
import { hasStripeStatus, searchStripe } from "../../../../lib/stripe";
import {
  Coupon,
  Customer,
  PatientRecord,
  PatientRecordCustomer,
  Practitioner,
} from "../../../../lib/types";
import * as Routes from "../../../../routes";
import PatientEntityCard from "../../../shared/PatientEntityCard";
import CustomerInput, {
  CustomerInputProps,
} from "../../stripe/form/CustomerInput";
import AdditionalOption from "./AdditionalOption";
import DiagnosisInput from "./DiagnosisInput";
import InsuranceDetailsInput from "./InsuranceDetailsInput";
import ItemsFields from "./ItemsFields";
import MemoInput from "./MemoInput";
import PaymentFields, { PaymentFieldsProps } from "./PaymentFields";
import PractitionerInput, { PractitionerInputProps } from "./PractitionerInput";

type InvoiceFormProps = PaymentFieldsProps &
  CustomerInputProps &
  InvoiceItemsProps &
  PractitionerInputProps & {
    customer_field_name: string;
    set_default_customer_field_name: string;
    set_default_customer_field_value: boolean;
    customer_field_editable: boolean;
    stripe_base_url: string;
    patient_record: PatientRecord;
    invoice: {
      diagnosis: string;
      memo: string;
      footer: string;
      confidential: boolean;
      insuranceDetails: string;
      service_date: string;
      insurer: string;
      authentication_code: string;
      membership_number: string;
      practitioner_id: number;
      appointment_id: number;
    };
    practitioner_options: Practitioner[];
    appointment_service_date: string;
    current_admin_practitioner_id: number;
    patient_record_customer: PatientRecordCustomer;
    default_customer: Customer;
    default_patient_record_customer: PatientRecordCustomer;
    invoice_errors: boolean;
    smart_invoice_matching: boolean;
    practitioner: Practitioner;
    location?: { name: string };
    practitioner_field_name: string;
  };

export type CollectionMethod = "request_payment" | "charge_customer";

export default function InvoiceForm(props: InvoiceFormProps) {
  const [loading, setLoading] = useState<string>("");
  const [customer, setCustomer] = useState(props.customer);
  const [defaultCustomer, setDefaultCustomer] = useState(
    props.default_customer
  );
  const [practitioner, setPractitioner] = useState(props.practitioner);
  const [items, setItems] = useState(props.items || []);
  const [coupons, setCoupons] = useState(props.coupons || []);
  const [couponOptions, setCouponOptions] = useState<Coupon[]>([]);
  const [collectionMethod, setCollectionMethod] = useState<CollectionMethod>(
    props.invoice.collection_method as CollectionMethod
  );

  const isInvoiceBillingDetailsPresent = [
    props.invoice.insurer,
    props.invoice.authentication_code,
    props.invoice.membership_number,
  ].every((value) => value === null);
  const invoiceErrors = props.invoice_errors;
  const billingNote =
    props.default_patient_record_customer?.invoice_setting_billing_note;

  const invoiceBillingDetailsDefaultValues = {
    insurer:
      props.invoice.id || invoiceErrors
        ? props.invoice.insurer
        : props.default_patient_record_customer.invoice_setting_insurer,
    authentication_code:
      props.invoice.id || invoiceErrors
        ? props.invoice.authentication_code
        : props.default_patient_record_customer
            .invoice_setting_authentication_code,
    membership_number:
      props.invoice.id || invoiceErrors
        ? props.invoice.membership_number
        : props.default_patient_record_customer
            .invoice_setting_membership_number,
  };

  const [insuranceDetailsOpen, setInsuranceDetailsOpen] = useState(
    !!props.invoice.insuranceDetails ||
      !!props.invoice.insurer ||
      !!props.invoice.authentication_code ||
      !!props.invoice.membership_number ||
      (!props.invoice.id &&
        ((isInvoiceBillingDetailsPresent &&
          !!props.default_patient_record_customer.invoice_setting_insurer) ||
          !!props.default_patient_record_customer
            .invoice_setting_authentication_code ||
          !!props.default_patient_record_customer
            .invoice_setting_membership_number))
  );
  const [insuranceDetails, setInsuranceDetails] = useState(
    invoiceBillingDetailsDefaultValues
  );
  const [insuranceDetailsError, setInsuranceDetailsError] = useState("");

  const [diagnosis, setDiagnosis] = useState(props.invoice.diagnosis || "");
  const [diagnosisOpen, setDiagnosisOpen] = useState<boolean>(
    (props.invoice.id && !!props.invoice.diagnosis) ||
      (!props.invoice.id &&
        (props.invoice_errors
          ? !!props.invoice.diagnosis
          : props.default_patient_record_customer?.invoice_setting_diagnosis))
  );
  const [diagnosisError, setDiagnosisError] = useState("");

  const [memoError, setMemoError] = useState("");
  const [memo, setMemo] = useState(props.invoice.memo || "");
  const [memoOpen, setMemoOpen] = useState<boolean>(
    (props.invoice.id && !!props.invoice.memo) ||
      (!props.invoice.id && props.invoice_errors && !!props.invoice.memo)
  );

  const [appointment, setAppointment] = useState(props.appointment);

  const defaultServiceDate = props.invoice.id
    ? props.invoice.service_date
    : appointment
    ? props.appointment_service_date
    : new Date().toDateString();
  const [serviceDate, setServiceDate] = useState<Date>(
    new Date(defaultServiceDate)
  );

  const onReloadSourcesClick = async () => {
    const data = await fetchCustomer(customer);
    setCustomer(data);
  };

  const handleInsuranceDetailsChange = (fieldName, value) => {
    setInsuranceDetails({
      ...insuranceDetails,
      [fieldName]: value,
    });
  };

  const handleFormSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    let isError = false;
    const mode = e.currentTarget.value;

    if (insuranceDetailsOpen) {
      if (
        !insuranceDetails.insurer &&
        !insuranceDetails.authentication_code &&
        !insuranceDetails.membership_number
      ) {
        isError = true;
        e.preventDefault();
        setInsuranceDetailsError(
          translate("partners.stripe.insuranceDetailsError")
        );
      } else {
        setInsuranceDetailsError("");
      }
    }

    if (diagnosisOpen) {
      if (!diagnosis.trim()) {
        isError = true;
        e.preventDefault();
        setDiagnosisError(translate("partners.stripe.diagnosisError"));
      } else {
        setDiagnosisError("");
      }
    }

    if (memoOpen) {
      if (!memo.trim()) {
        isError = true;
        e.preventDefault();
        setMemoError(translate("partners.stripe.memoError"));
      } else {
        setMemoError("");
      }
    }

    if (!isError) {
      setLoading(mode);
      const form = document.getElementById(
        "stripe-invoice-form"
      ) as HTMLFormElement;
      if (form) {
        const modeParam = form.querySelector(
          "#mode-param-field"
        ) as HTMLInputElement;
        if (modeParam) {
          modeParam.value = mode;
        }

        form.submit();
      }
    }
  };

  const emisItemsFetcher = async () => {
    try {
      const response = await fetch(
        Routes.partners_stripe_admins_emis_path({
          patient_record_id: props.patient_record.id,
        })
      );
      const items = await response.json();

      return items;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  useEffect(() => {
    searchStripe<Coupon>("", { type: "coupon" }).then((response) =>
      setCouponOptions(response.data)
    );
  }, []);

  return (
    <ThemeProvider>
      <div className="flex flex-col gap-5 mb-4">
        {billingNote && (
          <div>
            <InlineAlert
              variant="info"
              label={translate("partners.stripe.billingNote")}
            >
              <div className="break-word">
                <p>{billingNote}</p>
              </div>
            </InlineAlert>
          </div>
        )}

        <PatientEntityCard
          label={translate("base.patient")}
          patient_record={props.patient_record}
        />

        <CustomerInput
          customer={customer}
          setCustomer={setCustomer}
          defaultCustomer={defaultCustomer}
          name={props.customer_field_name}
          defaultFieldName={props.set_default_customer_field_name}
          defaultFieldValue={props.set_default_customer_field_value}
          editable={props.customer_field_editable}
          stripe_base_url={props.stripe_base_url}
          isStatusOpenOrPaid={hasStripeStatus(props.invoice, ["open", "paid"])}
        />
      </div>

      <h4 className="text-xl font-bold mb-5">
        {translate("partners.stripe.items")}
      </h4>

      <InvoiceItems
        items={items}
        coupons={coupons}
        onChange={({ items, coupons }) => {
          setItems(items);
          setCoupons(coupons);
        }}
        config={{
          resource: "invoice",
          editable: hasStripeStatus(props.invoice, "draft"),
          oneTimeItems: true,
          totals: true,
          coupons: couponOptions,
          fetcher: async (search) => searchStripe(search, { type: "product" }),
          emisItems: {
            enabled: props.patient_record.ehr_partner === "emis",
            fetcher: emisItemsFetcher,
            relevantMatchesLimit: 3,
          },
        }}
      />

      <ItemsFields
        items={items}
        coupons={coupons}
        namePrefix={{
          items: "partners_stripe_invoice[invoice_items_attributes]",
          coupons: "partners_stripe_invoice[coupons]",
        }}
      />

      {hasStripeStatus(props.invoice, [
        "draft",
        "uncollectible",
        "unknown",
        "void",
      ]) && (
        <PaymentFields
          customer={customer}
          setCustomer={setCustomer}
          invoice={props.invoice}
          defaultCustomer={defaultCustomer}
          setDefaultCustomer={setDefaultCustomer}
          collectionMethod={collectionMethod}
          setCollectionMethod={setCollectionMethod}
          collection_method_field_name={props.collection_method_field_name}
          payment_source={props.payment_source}
          payment_source_field_name={props.payment_source_field_name}
          stripe_publishable_key={props.stripe_publishable_key}
          stripe_account={props.stripe_account}
          onReloadSourcesClick={onReloadSourcesClick}
          stripeBaseUrl={props.stripe_base_url}
        />
      )}

      <div className="py-6">
        <div className="flex align-center text-xl leading-7 font-bold text-hero-blue-700 mb-4 gap-x-2">
          {translate("partners.stripe.additionalOptions")}
        </div>

        <InlineAlert
          variant="info"
          label={translate("partners.stripe.additionalOptionsWarning")}
          className="mb-4"
        />

        <InsuranceDetailsInput
          values={insuranceDetails}
          onChange={handleInsuranceDetailsChange}
          open={insuranceDetailsOpen}
          onOpenChange={setInsuranceDetailsOpen}
          error={insuranceDetailsError}
          disabled={!hasStripeStatus(props.invoice, ["draft", "open", "paid"])}
        />

        <DiagnosisInput
          value={diagnosis}
          onChange={(value) => setDiagnosis(value)}
          open={diagnosisOpen}
          onOpenChange={(value) => {
            hasStripeStatus(props.invoice, [
              "draft",
              "uncollectible",
              "unknown",
              "void",
              "open",
              "paid",
            ]) && setDiagnosisOpen(value);
          }}
          error={diagnosisError}
          disabled={!hasStripeStatus(props.invoice, ["draft", "open", "paid"])}
        />

        <MemoInput
          value={memo}
          onChange={(value) => setMemo(value)}
          open={memoOpen}
          onOpenChange={(value) => setMemoOpen(value)}
          error={memoError}
          disabled={!hasStripeStatus(props.invoice, ["draft", "open", "paid"])}
        />

        <input
          value="0"
          type="hidden"
          name="partners_stripe_invoice[confidential]"
        />
        <AdditionalOption
          id="partners_stripe_invoice_confidential"
          name="partners_stripe_invoice[confidential]"
          value="1"
          label={translate("partners.stripe.confidentialOnInvoice")}
          subtext={translate(
            "partners.stripe.patientInformationWillNotBeShown"
          )}
          defaultChecked={
            (props.invoice.id && props.invoice.confidential) ||
            (!props.invoice.id &&
              (props.invoice_errors
                ? props.invoice.confidential
                : props.default_patient_record_customer
                    ?.invoice_setting_confidential))
          }
          disabled={hasStripeStatus(props.invoice, ["paid", "open"])}
        />
      </div>
      <div className="py-4">
        <div className="flex align-center text-xl leading-7 font-bold text-hero-blue-700 gap-x-2">
          {translate("partners.stripe.linkToAService")}
        </div>
        <div className="text-base leading-7 text-hero-blue-500">
          {translate("partners.stripe.linkInvoice")}
        </div>
      </div>
      <div className="flex gap-3 mb-5">
        <div className="flex flex-col gap-1">
          <div className="flex align-center gap-x-2">
            <Typography size="sm" weight="bold" color="primary">
              <label>{translate("partners.stripe.practitioner")}</label>
            </Typography>

            {!props.smart_invoice_matching && (
              <span className="flex my-auto font-normal">
                <Badge size={"sm"}>{translate("base.optional")}</Badge>
              </span>
            )}
          </div>

          <div>
            <PractitionerInput
              setPractitioner={setPractitioner}
              practitioner={practitioner}
              appointment={appointment}
              practitionerOptions={props.practitioner_options}
              practitionerFieldName={props.practitioner_field_name}
              currentAdminPractitionerId={props.current_admin_practitioner_id}
              isStatusDraftOrOpen={hasStripeStatus(props.invoice, [
                "draft",
                "open",
              ])}
              smartInvoiceMatching={props.smart_invoice_matching}
            />
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <Typography size="sm" weight="bold" color="primary">
            <label htmlFor="partners_stripe_invoice_service_date">
              {translate("partners.stripe.serviceDate")}
            </label>
          </Typography>

          <div>
            <Datepicker
              id="partners_stripe_invoice_service_date"
              name="partners_stripe_invoice[service_date]"
              selected={serviceDate}
              onChange={(date) => setServiceDate(date)}
              readOnly={
                !!appointment || !hasStripeStatus(props.invoice, ["open", "draft"])
              }
            />
          </div>
        </div>
        <input
          value={appointment ? appointment.id : ""}
          type="hidden"
          name="partners_stripe_invoice[appointment_id]"
        />
        <input value="" type="hidden" name="mode" id="mode-param-field" />
      </div>

      {props.smart_invoice_matching && !appointment && (
        <InlineAlert
          variant="info"
          label={translate("partners.stripe.smartInvoicingEnabled")}
          className="mb-8"
        />
      )}

      <div>
        {appointment && practitioner && location && (
          <>
            {!props.smart_invoice_matching && (
              <div className="mb-4">
                <InlineAlert
                  variant="info"
                  label={translate("partners.stripe.alertInfo", {
                    description: appointment.description,
                  })}
                />
              </div>
            )}

            <div className="p-4 border border-hero-blue-200 font-medium rounded-lg mb-8">
              <div className="grid grid-cols-8">
                <div className="col-span-2 flex flex-col justify-center">
                  <div>{formatISODateTime(appointment.start_time, "date")}</div>

                  <div>{formatISODateTime(appointment.start_time, "time")}</div>

                  <div className="flex font-normal">
                    <Badge size={"sm"}>
                      {appointment.deleted_at
                        ? translate("base.cancelled")
                        : appointment.booking_confirmed_at
                        ? translate("base.confirmed")
                        : translate("base.reserved")}
                    </Badge>
                  </div>
                </div>
                <div
                  className={`flex flex-col justify-between
                  ${
                    props.smart_invoice_matching ? "col-span-4" : "col-span-6"
                  }`}
                >
                  <Link variant="secondary" className="-herolib-text-lg mb-1">
                    <a href={Routes.admin_appointment_path(appointment.id)}>
                      {appointment.description}
                    </a>
                  </Link>
                  <Typography weight="normal" color="light" size="sm">
                    <p>
                      {ld.capitalize(translate("base.for"))}{" "}
                      <Link variant="secondary">
                        <a
                          href={Routes.patient_dashboard_path(
                            props.patient_record.client_id
                          )}
                        >
                          {formatPatientNameStd({
                            title: ld.capitalize(props.patient_record.title),
                            firstName: props.patient_record.first_name,
                            lastName: props.patient_record.last_name,
                          })}
                        </a>
                      </Link>{" "}
                      {translate("base.with")}{" "}
                      {translate("base.practitionerAtLocation", {
                        practitioner: practitioner.type_and_full_name,
                        location: props.location.name,
                      })}
                    </p>
                  </Typography>
                </div>
                {props.smart_invoice_matching && (
                  <div
                    className="col-span-2 flex justify-end my-auto"
                    onClick={() => {
                      setAppointment(null);
                      setPractitioner(null);
                    }}
                  >
                    <Button
                      variant="white"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setAppointment(null);
                        setPractitioner(null);
                      }}
                      prepend={<IconLink />}
                    >
                      {translate("partners.stripe.unlink")}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <div className="flex justify-end gap-2">
        <Button
          type="submit"
          name="mode"
          value="draft"
          variant={`${
            hasStripeStatus(props.invoice, ["paid"]) ? "primary" : "white"
          }`}
          onClick={handleFormSubmit}
          loading={loading === "draft"}
          disabled={!!loading}
        >
          {hasStripeStatus(props.invoice, ["open", "paid"])
            ? translate("partners.stripe.updateInvoice")
            : translate("partners.stripe.saveDraft")}
        </Button>

        {hasStripeStatus(props.invoice, [
          "open",
          "draft",
          "uncollectible",
          "unknown",
          "void",
        ]) &&
          (collectionMethod === "charge_customer" ||
          hasStripeStatus(props.invoice, ["open"]) ? (
            <Button
              type="submit"
              name="mode"
              value="charge"
              variant="primary"
              onClick={handleFormSubmit}
              loading={loading === "charge"}
              disabled={!!loading}
            >
              {translate("partners.stripe.proceedToPayment")}
            </Button>
          ) : (
            <Button
              type="submit"
              name="mode"
              value="finalize"
              variant="primary"
              onClick={handleFormSubmit}
              loading={loading === "finalize"}
              disabled={!!loading}
            >
              {translate("partners.stripe.finalizeAndSendInvoice")}
            </Button>
          ))}
      </div>
    </ThemeProvider>
  );
}
