import React from "react";
import HeroHealthMessageBuilder from "@herohealthsoftware/message-builder-react";
import { Spinner, ThemeProvider } from "@herohealthsoftware/ui";

const MessageBuilderPage = ({
  env,
  practiceGroupId,
  patientId,
  taskId,
  jwtToken,
}) => {
  return (
    <HeroHealthMessageBuilder
      jwtToken={jwtToken}
      env={env}
      tenantId={practiceGroupId}
      patientId={patientId}
      taskId={taskId}
      telemetry={true}
      loader={
        <ThemeProvider>
          <Spinner className="h-12 w-12" />
        </ThemeProvider>
      }
    />
  );
};

export default MessageBuilderPage;
