import React from 'react';

const IconOutcome = () => {
  return (
    <>
      <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.33095 16.8868C7.95024 19.2783 4.89229 20.0976 2.50083 18.7169C0.109372 17.3362 -0.710003 14.2782 0.670707 11.8868C2.05142 9.4953 5.10937 8.67593 7.50083 10.0566C9.89229 11.4374 10.7117 14.4953 9.33095 16.8868Z" fill="#090955"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.277 1.4821C15.4141 0.969451 15.7492 0.532502 16.2084 0.267338C16.6677 0.00217325 17.2136 -0.0694949 17.7261 0.0680935L23.9965 1.75478C24.509 1.89252 24.946 2.22823 25.2115 2.68808C25.477 3.14794 25.5492 3.69428 25.4123 4.20696L23.7173 10.4938C23.573 10.9983 23.2364 11.4257 22.78 11.6839C22.3235 11.9422 21.7838 12.0106 21.277 11.8745C20.7703 11.7383 20.337 11.4085 20.0705 10.9561C19.804 10.5037 19.7257 9.96483 19.8524 9.45559L20.2999 7.8361L8.89266 14.4221C8.43329 14.6873 7.88725 14.759 7.37466 14.6213C6.86206 14.4836 6.42491 14.1478 6.15936 13.6879C5.89381 13.2279 5.82162 12.6815 5.95867 12.1687C6.09572 11.6559 6.43079 11.2189 6.89016 10.9537L18.2974 4.36765L16.6928 3.93428C16.1803 3.79654 15.7433 3.46083 15.4778 3.00098C15.2123 2.54112 15.1401 1.99478 15.277 1.4821Z" fill="#090955"/>
      </svg>
    </>
  )
}

export default IconOutcome;
