import React, { useEffect, useState } from "react";
import { ThemeProvider, Spinner } from "@herohealthsoftware/ui";
import { isEmpty } from "lodash";

import Stripe from "./integrations/Stripe";
import { translate } from "../../../lib/i18n";
import { Integration } from "../../../lib/types";
import IntegrationSidebar from "../../shared/IntegrationSidebar";
import SignatureRx from "./integrations/SignatureRx";
import LegacyInvoicing from "./integrations/LegacyInvoicing";
import HcaBooking from "./integrations/HcaBooking";

type IntegrationsProps = {
  integrations: Integration[];
};

export default function Integrations(props: IntegrationsProps) {
  const [activeIntegration, setActiveIntegration] =
    useState<null | Integration>(null);

  const handleIntegrationClick = (integration: Integration) => {
    if (activeIntegration && integration.key === activeIntegration.key) {
      return setActiveIntegration(null);
    }

    setActiveIntegration(integration);
  };

  const integrationComponent = (integration: Integration) => {
    if (!integration) return null;

    if (!integration.data) {
      return (
        <div
          className="flex flex-col justify-center items-center p-6 m-4
            border border-hero-blue-200 rounded-lg text-hero-blue-700"
        >
          <Spinner className="w-8 h-8 mb-2 fill-hero-blue-700" />

          <div className="leading-6 font-medium">
            {translate("base.loading")} {integration.name}
          </div>
        </div>
      );
    }

    if (integration.key === "stripe") {
      return <Stripe settings={integration.settings} {...integration.data} />;
    }

    if (integration.key === "legacy_invoicing") {
      return <LegacyInvoicing {...integration.data} />;
    }

    if (integration.key === "signature_rx") {
      return (
        <SignatureRx settings={integration.settings} {...integration.data} />
      );
    }

    if (integration.key === "hca_booking") {
      return (
        <HcaBooking {...integration.data} />
      );
    }
  };

  const fetchIntegrationData = async (integration: Integration) => {
    const response = await fetch(integration.path);
    const data = await response.json();

    return data;
  };

  useEffect(() => {
    if (activeIntegration && isEmpty(activeIntegration.data)) {
      fetchIntegrationData(activeIntegration).then((data) => {
        setActiveIntegration({ ...activeIntegration, data });
      });
    }
  }, [activeIntegration]);

  useEffect(() => {
    const initialIntegration = props.integrations.find(
      (integration) => integration.open
    );

    if (initialIntegration) {
      setActiveIntegration(initialIntegration);
    }
  }, []);

  return (
    <ThemeProvider>
      <div
        className="app-integrations fixed top-0 right-0 z-30 h-full
        flex bg-white text-hero-blue-700"
      >
        {activeIntegration && (
          <IntegrationSidebar
            {...activeIntegration}
            position="relative"
            onClose={() => setActiveIntegration(null)}
          >
            {integrationComponent(activeIntegration)}
          </IntegrationSidebar>
        )}

        <div className="flex flex-col gap-2 w-10 h-full px-1 pt-28 border-l border-hero-blue-200">
          {props.integrations.map((integration) => (
            <div
              key={integration.name}
              className="group relative cursor-pointer p-1 rounded bg-white
            hover:bg-hero-blue-900/5"
              onClick={() => handleIntegrationClick(integration)}
            >
              <div
                className="absolute py-1 px-2 right-9 rounded-lg bg-hero-blue-900
              shadow-lg text-xs text-white font-bold hidden group-hover:block"
              >
                {integration.name}
              </div>
              <img src={integration.logo} alt={integration.name} />
            </div>
          ))}
        </div>
      </div>
    </ThemeProvider>
  );
}
