import React, { useEffect, useRef, useState } from "react";

import { translate } from "../../../../lib/i18n";
import {
  calculateTotal,
  formatPence,
  totalInPence,
} from "../../../../lib/money";
import { invoiceItemFieldsFromProduct } from "../../../../lib/stripe";
import {
  Coupon,
  EmisItem,
  InvoiceItem,
  PatientRecord,
  Product,
} from "../../../../lib/types";
import CouponsInput from "./CouponsInput";
import { ItemRow } from "./ItemRow";
import ItemsSearch from "./ItemsSearch";
import { NewItemsFields } from "./NewItemsFields";

export type ItemsInputConfig = {
  resource: "appointmentType" | "invoice" | "membershipScheme";
  inputNamePrefix: string;
  oneTimeItems: boolean;
  coupons: boolean;
  totals: boolean;
  emisItems:
    | false
    | {
        enabled: boolean;
        patient_record: PatientRecord;
      };
};

export type ItemsInputProps = {
  items: InvoiceItem[];
  editable: boolean;
  setItems: (items: InvoiceItem[]) => void;
  coupons?: Coupon[];
  patient_record?: PatientRecord;
  config: ItemsInputConfig;
};

export default function ItemsInput(props: ItemsInputProps) {
  const [coupons, setCoupons] = useState(props.coupons || []);
  const [newItems, setNewItems] = useState<InvoiceItem[]>([]);
  const [showItemSearch, setShowItemSearch] = useState<boolean>(false);
  const itemSearchFocusRef = useRef(null);

  const productToInvoiceItem = (product: Product): InvoiceItem => {
    return {
      ...invoiceItemFieldsFromProduct(product),
      description: product.name || "",
      quantity: 1,
      create: false,
      mode: "create",
    };
  };

  const handleAddItem = (item: Partial<InvoiceItem>) => {
    setShowItemSearch(false);

    setNewItems([
      {
        description: "",
        quantity: 1,
        unit_amount_in_pounds: "",
        create: item.create,
        mode: "create",
      },
    ]);
  };

  const handleAddProduct = (product: Product) => {
    setShowItemSearch(false);

    const productItem = productToInvoiceItem(product);
    setNewItems([productItem]);
  };

  const handleAddEmisItems = (emisItems: EmisItem[]) => {
    const items = emisItems
      .map((emisItem) => {
        if (emisItem.product) {
          return productToInvoiceItem(emisItem.product);
        } else if (emisItem.invoiceItem) {
          emisItem.invoiceItem.mode = "create";
          return emisItem.invoiceItem;
        }
      })
      .filter((item) => item);

    setShowItemSearch(false);
    setNewItems(items);
  };

  const handleRemoveItem = (item: InvoiceItem, index: number) => {
    props.setItems(props.items.filter((_, idx) => idx !== index));
  };

  const handleEditItem = (item: InvoiceItem, index: number) => {
    handleRemoveItem(item, index);
    setNewItems([{ ...item, mode: "edit" }]);
  };

  const handleCancelNewItems = () => {
    newItems.forEach((item) => {
      if (item.mode === "edit") {
        props.setItems([...props.items, item]);
      }
    });

    setShowItemSearch(false);
    setNewItems([]);
  };

  const handleSaveItems = (newItems, addAnother = false) => {
    props.setItems([...props.items, ...newItems]);
    setShowItemSearch(false);
    setNewItems([]);

    if (addAnother) {
      setShowItemSearch(true);
    }
  };

  useEffect(() => {
    if (showItemSearch) {
      itemSearchFocusRef.current.focus();
    }
  }, [showItemSearch]);

  return (
    <div className="text-hero-blue-700">
      {props.items.length > 0 ? (
        <div className="font-medium border border-hero-blue-200 rounded-lg">
          {props.items.map((item, index) => (
            <ItemRow
              key={index}
              index={index}
              item={item}
              editable={props.editable}
              onEditClick={handleEditItem}
              onRemoveClick={handleRemoveItem}
              config={props.config}
            />
          ))}

          {newItems.length > 0 && (
            <div className="p-4 border-b border-blue-200">
              <NewItemsFields
                items={newItems}
                onClose={handleCancelNewItems}
                onSubmit={handleSaveItems}
                config={props.config}
              />
            </div>
          )}

          {newItems.length === 0 && showItemSearch && (
            <div className="p-4 border-b border-blue-200">
              <ItemsSearch
                focusRef={itemSearchFocusRef}
                onNewItemClick={handleAddItem}
                onSelectProductClick={handleAddProduct}
                onSubmitEmisItems={handleAddEmisItems}
                config={props.config}
              />
            </div>
          )}

          <div className="p-4 flex">
            <div className="w-1/4">
              {props.editable && (
                <div
                  onClick={() => setShowItemSearch(true)}
                  className="b b-outline"
                >
                  {translate("partners.stripe.addItem")}
                </div>
              )}
            </div>

            <div
              className={`w-3/4 grid ${
                props.editable ? "grid-cols-6 gap-2" : "grid-cols-5 gap-6"
              }`}
            >
              {props.config.totals && (
                <>
                  <div className="pr-2 text-right col-span-4">
                    {translate("partners.stripe.subtotal")}
                  </div>
                  <div className="col-span-1">
                    {formatPence(totalInPence(props.items))}
                  </div>
                </>
              )}

              {props.config.coupons && (
                <CouponsInput
                  editable={props.editable}
                  items={props.items}
                  coupons={coupons}
                  setCoupons={setCoupons}
                />
              )}

              {props.config.totals && (
                <>
                  <div className="pr-2 text-right font-bold col-span-4">
                    {translate("partners.stripe.total")}
                  </div>
                  <div className="font-bold col-span-1">
                    {formatPence(calculateTotal(props.items, coupons))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : newItems.length > 0 ? (
        <NewItemsFields
          items={newItems}
          onClose={handleCancelNewItems}
          onSubmit={handleSaveItems}
          config={props.config}
        />
      ) : (
        <ItemsSearch
          onNewItemClick={handleAddItem}
          onSelectProductClick={handleAddProduct}
          onSubmitEmisItems={handleAddEmisItems}
          config={props.config}
        />
      )}
    </div>
  );
}
