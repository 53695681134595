import React from "react";
import { Coupon, InvoiceItem } from "../../../../lib/types";

type ItemsFieldsProps = {
  items: InvoiceItem[];
  coupons?: Coupon[];
  namePrefix: {
    items: string;
    coupons?: string;
  };
};

export default function ItemsFields(props: ItemsFieldsProps) {
  return (
    <>
      {props.items.map((item, index) => (
        <React.Fragment key={`item-hidden-field-${index}`}>
          <input
            type="hidden"
            name={`${props.namePrefix.items}[${index}][stripe_id]`}
            value={item.stripe_id || ""}
          />

          <input
            type="hidden"
            name={`${props.namePrefix.items}[${index}][product]`}
            value={item.product?.stripe_id || ""}
          />

          <input
            type="hidden"
            name={`${props.namePrefix.items}[${index}][price]`}
            value={item.price}
          />

          <input
            type="hidden"
            name={`${props.namePrefix.items}[${index}][description]`}
            value={item.description}
          />

          <input
            type="hidden"
            name={`${props.namePrefix.items}[${index}][quantity]`}
            value={item.quantity}
          />

          <input
            type="hidden"
            name={`${props.namePrefix.items}[${index}][unit_amount_in_pounds]`}
            value={item.unit_amount_in_pounds}
          />

          <input
            type="hidden"
            name={`${props.namePrefix.items}[${index}][create]`}
            value={item.create ? "true" : ""}
          />
        </React.Fragment>
      ))}

      {props.coupons &&
        props.coupons.map((coupon, index) => (
          <React.Fragment key={`coupon-hidden-field-${index}`}>
            <input
              type="hidden"
              name={`${props.namePrefix.coupons}[${index}][id]`}
              value={coupon.id}
            />

            <input
              type="hidden"
              name={`${props.namePrefix.coupons}[${index}][name]`}
              value={coupon.name}
            />
          </React.Fragment>
        ))}
    </>
  );
}
