import {
  PatientSearch as HeroPatientSearch,
  ThemeProvider,
  formatPatientDobStd,
  formatDobToApi,
} from "@herohealthsoftware/ui";
import { debounce } from "lodash-es";
import React, { useState } from "react";
import {
  createApiClientWithEnv,
  type PatientDto,
  type CreateApiClientWithEventProps,
} from "@herohealthsoftware/api-sdk-react";
import * as Routes from "../../routes";
import Hero from "../../lib/hero";
import { translate } from "../../lib/i18n";

export type Patient = {
  id: string;
  dob: string | null;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  gender: PatientDto["sex"];
  nhsNumber: string | null | undefined;
  consent: boolean;
  lastSyncAt: Date | null;
};

export type PatientSearchProps = CreateApiClientWithEventProps & {
  ehrEnabled: boolean;
  isRoot?: boolean;
  onChange?: (patient: { value: Patient }) => void;
};

const getDisplaySex = (patient: PatientDto) => patient.sex;

const mapFromApi = (patient: PatientDto): Patient => ({
  id: String(patient.id),
  dob: patient.dob ? formatPatientDobStd({ dob: new Date(patient.dob) }) : null,
  firstName: patient.first_name,
  lastName: patient.last_name,
  gender: getDisplaySex(patient),
  nhsNumber: patient.nhs_number,
  consent: false,
  lastSyncAt: patient.last_sync_at ? new Date(patient.last_sync_at) : null,
});

const PatientSearch = ({ isRoot = false, ...props }: PatientSearchProps) => {
  const [error, setError] = useState<string | null>(null);
  const Provider = isRoot ? ThemeProvider : React.Fragment;
  const apiClient = createApiClientWithEnv(props);

  const onChange =
    props.onChange ??
    (({ value: patient }) => {
      window.location.href = Routes.patient_dashboard_path(patient.id);
    });

  const getOptions = (value: string, filter: string | null) => {
    setError(null);
    if (filter === "dob") {
      try {
        formatDobToApi(value);
      } catch (error) {
        setError(translate("admin.patientSearch.invalidDateFormat"));
        return Promise.resolve([]);
      }
    }
    return apiClient.patients
      .listPatients({
        search: filter === "dob" ? formatDobToApi(value) : value,
        field: filter ?? undefined,
      })
      .then((item) =>
        item.data.map((i) => ({
          value: mapFromApi(i),
          label: `${i.last_name?.toUpperCase()}, ${i.first_name}`,
        }))
      )
      .catch(() => []);
  };

  const fetcher = debounce(
    (
      value: string,
      filter: string | null,
      callback: (options: unknown[]) => void
    ) => {
      getOptions(value, filter).then((results) => callback(results));
    },
    500
  );

  const filters = props.ehrEnabled
    ? [
        {
          label: translate("common.nhsNumber"),
          value: "nhs_number",
          placeholder: "e.g 2949253326",
        },
        {
          label: translate("common.emisId"),
          value: "emis_id",
          placeholder: "e.g. 50003",
        },
        {
          label: translate("base.dateOfBirth"),
          value: "dob",
          placeholder: "e.g. 23-11-1998",
        },
      ]
    : [
        {
          label: translate("common.nhsNumber"),
          value: "nhs_number",
          placeholder: "e.g 2949253326",
        },
        {
          label: translate("base.dateOfBirth"),
          value: "dob",
          placeholder: "e.g. 23-11-1998",
        },
      ];

  return (
    <Provider>
      <HeroPatientSearch
        openMenuOnFocus
        filters={filters}
        fetcher={fetcher as never}
        className="w-96"
        onChange={onChange}
        onAdd={() => {
          props.ehrEnabled
            ? Hero.remote({
                url: Routes.create_patient_sidebar_path({
                  format: "js",
                }),
                type: "GET",
              })
            : Hero.remote({
                url: Routes.existing_patient_search_admin_patients_path({
                  format: "js",
                }),
                type: "GET",
              });
        }}
        addText={translate("common.addPatient")}
        notFoundMessage={error ? error : undefined}
      />
    </Provider>
  );
};

export default PatientSearch;
