import { Button, EntityCard, IconPlus1 } from "@herohealthsoftware/ui";
import React from "react";
import { translate } from "../../../../lib/i18n";
import { PatientRecord } from "../../../../lib/types";
import * as Routes from "../../../../routes";
import PatientEntityCard from "../../../shared/PatientEntityCard"

type HcaBookingProps = {
  patient_record: PatientRecord;
};

export default function HcaBooking(props: HcaBookingProps) {
  return (
    <div className="flex flex-col gap-2 py-4 px-6">
      <PatientEntityCard
        label="Patient"
        {...props}
      />

      <Button
        as="a"
        type="primary"
        href={Routes.partners_hca_admins_booking_index_path({
          patient_id: props.patient_record.client_id,
        })}
      >
        <div className="flex items-center gap-2 no-underline">
          <div className="h-4 w-4">
            <IconPlus1 />
          </div>
          {translate("partners.hca.requestConsultation")}
        </div>
      </Button>
    </div>
  );
}
