import React, { useState, useEffect, useRef } from "react";
import {
  IconPlus,
  IconTrash,
  Checkbox,
  ThemeProvider,
  Button,
  Typography,
  Tooltip,
  ErrorSummary,
} from "@herohealthsoftware/ui";
import TimeInput from "./TimeInput";
import { translate } from "../../lib/i18n";

interface CareNavOpeningHour {
  id: number;
  day_of_week: string;
  in_hours_start_time: string;
  in_hours_end_time: string;
  suspended: boolean;
  _destroy: boolean;
  is_removed: boolean;
}

type DayIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6;

type CareNavOpeningHours = {
  [key in DayIndex]?: CareNavOpeningHour[];
};

interface InHoursScheduleProps {
  careNavOpeningHours: CareNavOpeningHour[];
}

const InHoursSchedule: React.FC<InHoursScheduleProps> = ({
  careNavOpeningHours,
}) => {
  const daysOfWeek = [
    { key: 1, value: "Monday" },
    { key: 2, value: "Tuesday" },
    { key: 3, value: "Wednesday" },
    { key: 4, value: "Thursday" },
    { key: 5, value: "Friday" },
    { key: 6, value: "Saturday" },
    { key: 0, value: "Sunday" },
  ];

  let openingHourIndex = 0;
  const [openingHours, setOpeningHours] = useState<CareNavOpeningHours>({});
  const [checkedDays, setCheckedDays] = useState<DayIndex[]>([]);
  const [emptyFieldErrors, setEmptyFieldErrors] = useState<{
    [key: string]: string;
  }>({});
  const originalOpeningHoursRef = useRef<CareNavOpeningHour[]>([]);
  const [removedOpeningHours, setRemovedOpeningHours] =
    useState<CareNavOpeningHours>({});
  const [emptyScheduleError, setEmptyScheduleError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleCheckboxChange = (dayOfWeek: DayIndex) => {
    if (checkedDays.includes(dayOfWeek)) {
      setCheckedDays(checkedDays.filter((day) => day !== dayOfWeek));
      setOpeningHours((prevState) => ({
        ...prevState,
        [dayOfWeek]: prevState[dayOfWeek]
          .filter(
            ({ in_hours_start_time, in_hours_end_time }) =>
              in_hours_start_time.trim() !== "" &&
              in_hours_end_time.trim() !== ""
          )
          .map((opening_hour) => ({
            ...opening_hour,
            suspended: true,
          })),
      }));
      setEmptyFieldErrors({});
    } else {
      setCheckedDays([...checkedDays, dayOfWeek]);
      setEmptyScheduleError(false);
      if (!(openingHours[dayOfWeek]?.length ?? 0)) {
        setOpeningHours((prevState) => ({
          ...prevState,
          [dayOfWeek]: [
            {
              id: "",
              day_of_week: dayOfWeek.toString(),
              in_hours_start_time: "",
              in_hours_end_time: "",
              suspended: false,
              _destroy: false,
              is_removed: false,
            },
          ],
        }));
      } else {
        setOpeningHours((prevState) => ({
          ...prevState,
          [dayOfWeek]:
            prevState[dayOfWeek]?.map((opening_hour) => ({
              ...opening_hour,
              suspended: false,
            })) || [],
        }));
      }
    }
  };

  const handleTimeChange = (
    dayOfWeek: DayIndex,
    index: number,
    type: "in_hours_start_time" | "in_hours_end_time",
    value: string
  ) => {
    setOpeningHours((prevState) => ({
      ...prevState,
      [dayOfWeek]: prevState[dayOfWeek]?.map((opening_hour, i) =>
        i === index ? { ...opening_hour, [type]: value } : opening_hour
      ) || [
        {
          id: "",
          day_of_week: dayOfWeek.toString(),
          in_hours_start_time: "",
          in_hours_end_time: "",
          suspended: false,
          _destroy: false,
          is_removed: false,
        },
      ],
    }));
  };

  const handleAddOpeningHour = (dayOfWeek: DayIndex) => {
    setOpeningHours((prevState) => ({
      ...prevState,
      [dayOfWeek]: [
        ...(prevState[dayOfWeek] || []),
        {
          id: "",
          day_of_week: dayOfWeek.toString(),
          in_hours_start_time: "",
          in_hours_end_time: "",
          suspended: false,
          _destroy: false,
          is_removed: false,
        },
      ],
    }));
  };

  const handleRemoveOpeningHour = (dayOfWeek: DayIndex, index: number) => {
    const opening_hour_record = openingHours[dayOfWeek][index];
    const { [`${dayOfWeek}_${index}`]: removed, ...rest } = emptyFieldErrors;
    setEmptyFieldErrors(() => rest);
    setRemovedOpeningHours((prevState) => ({
      ...prevState,
      [`${dayOfWeek}_${index}`]: true,
    }));

    if (opening_hour_record?.id) {
      setOpeningHours((prevState) => ({
        ...prevState,
        [dayOfWeek]: prevState[dayOfWeek]?.map((opening_hour, i) =>
          i === index ? { ...opening_hour, _destroy: true } : opening_hour
        ),
      }));
    } else {
      setOpeningHours((prevState) => ({
        ...prevState,
        [dayOfWeek]: prevState[dayOfWeek]?.map((opening_hour, i) =>
          i === index ? { ...opening_hour, is_removed: true } : opening_hour
        ),
      }));
    }
  };

  const handleCancelClick = () => {
    const modalContainer = document.getElementById("inHoursScheduleModal");
    if (modalContainer) {
      modalContainer.classList.add("hidden");
      document.body.style.overflow = "";
    }
    setOpeningHours(getOpeningHoursData(originalOpeningHoursRef.current));
    setCheckedDays(
      getCheckedDaysFromOpeningHours(originalOpeningHoursRef.current)
    );
    setRemovedOpeningHours({});
    setEmptyFieldErrors({});
    setEmptyScheduleError(false);
  };

  const getOpeningHoursData = (
    careNavOpeningHours: CareNavOpeningHour[]
  ): CareNavOpeningHours => {
    const openingHours: CareNavOpeningHours = {};
    careNavOpeningHours.forEach((opening_hour) => {
      const dayOfWeek = parseInt(opening_hour.day_of_week, 10) as DayIndex;
      const existingOpeningHours = openingHours[dayOfWeek] || [];
      openingHours[dayOfWeek] = [...existingOpeningHours, { ...opening_hour }];
    });
    return openingHours;
  };

  const getCheckedDaysFromOpeningHours = (
    careNavOpeningHours: CareNavOpeningHour[]
  ): DayIndex[] => {
    const checked: DayIndex[] = [];
    careNavOpeningHours.forEach((opening_hour) => {
      const dayOfWeek = parseInt(opening_hour.day_of_week, 10) as DayIndex;
      if (!checked.includes(dayOfWeek)) {
        if (!opening_hour.suspended) {
          checked.push(dayOfWeek);
        }
      }
    });
    return checked;
  };

  const validateFields = () => {
    const errors: { [key: string]: string } = {};

    checkedDays.forEach((day) => {
      const dayOpeningHours = openingHours[day] || [];
      dayOpeningHours.forEach((opening_hour, index) => {
        const openingHour = opening_hour.in_hours_start_time.split(":")[0];
        const openingMinute = opening_hour.in_hours_start_time.split(":")[1];
        const closingHour = opening_hour.in_hours_end_time.split(":")[0];
        const closingMinute = opening_hour.in_hours_end_time.split(":")[1];
        if (
          (!opening_hour.id && !opening_hour.is_removed) ||
          (opening_hour.id && !opening_hour._destroy)
        ) {
          if (
            !opening_hour.in_hours_start_time ||
            !opening_hour.in_hours_end_time
          ) {
            return (errors[`${day}_${index}`] = translate(
              "careNavigation.inHoursSchedule.emptyFieldsError"
            ));
          }
          if (openingHour > closingHour) {
            return (errors[`${day}_${index}`] = translate(
              "careNavigation.inHoursSchedule.closingTimeEarlierThanOpeningTimeError"
            ));
          } else if (
            openingHour === closingHour &&
            openingMinute >= closingMinute
          ) {
            return (errors[`${day}_${index}`] = translate(
              "careNavigation.inHoursSchedule.closingTimeEarlierThanOpeningTimeError"
            ));
          }
        }
      });
    });

    setEmptyFieldErrors(errors);
    return Object.keys(errors).length !== 0;
  };

  const handleSubmitClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true);
    e.preventDefault();
    const isEmpty = validateFields();
    const isNotScheduled =
      Object.keys(checkedDays).length === 0 ||
      Object.values(openingHours).every((hours) => hours.length === 0);
    if (isEmpty || isNotScheduled) {
      if (isNotScheduled) {
        setEmptyScheduleError(true);
      }
      setLoading(false);
    } else {
      const form = document.getElementById(
        "in-hours-schedule-form"
      ) as HTMLFormElement;
      if (form) {
        form.submit();
      }
    }
  };

  useEffect(() => {
    const prefilledOpeningHours: CareNavOpeningHours = {};
    const checked: DayIndex[] = [];
    originalOpeningHoursRef.current = careNavOpeningHours;

    careNavOpeningHours.forEach((opening_hour) => {
      const dayOfWeek = parseInt(opening_hour.day_of_week, 10) as DayIndex;
      const existingOpeningHours = prefilledOpeningHours[dayOfWeek] || [];
      prefilledOpeningHours[dayOfWeek] = [
        ...existingOpeningHours,
        { ...opening_hour },
      ];
      if (!opening_hour.suspended) {
        checked.push(dayOfWeek);
      }
    });

    setOpeningHours(prefilledOpeningHours);
    setCheckedDays(checked);

    setOpeningHours(getOpeningHoursData(careNavOpeningHours));
    setCheckedDays(getCheckedDaysFromOpeningHours(careNavOpeningHours));
  }, [careNavOpeningHours]);

  return (
    <ThemeProvider>
      <div className="min-h-[150px] max-h-[calc(100vh-300px)] px-6 py-5 overflow-y-scroll opening-hours-container flex flex-col gap-5">
        {emptyScheduleError && (
          <div className="mx-4 mt-4 mb-1">
            <ErrorSummary
              items={[
                {
                  message: translate(
                    "careNavigation.inHoursSchedule.emptyScheduleError"
                  ),
                },
              ]}
            />
          </div>
        )}
        {daysOfWeek.map((day, index) => (
          <div key={index} className="flex gap-4">
            <div className="flex gap-2 mt-4 items-center h-5 w-28">
              <Checkbox
                checked={checkedDays.includes(day.key  as DayIndex)}
                onChange={() => handleCheckboxChange(day.key as DayIndex)}
              />
              <Typography weight="bold" size="sm" color="secondary">
                <span
                  className="cursor-pointer"
                  onClick={() => handleCheckboxChange(day.key as DayIndex)}
                >
                  {day.value}
                </span>
              </Typography>
            </div>
            <div className="flex flex-col">
              {openingHours[day.key]?.map((opening_hour, i) => (
                <>
                  <div
                    key={i}
                    className={`flex items-center gap-3 mt-2 ${
                      removedOpeningHours[`${day.key}_${i}`] ? "hidden" : ""
                    }`}
                  >
                    {!opening_hour.suspended && (
                      <>
                        <div>
                          <TimeInput
                            value={opening_hour.in_hours_start_time}
                            onChange={(value) =>
                              handleTimeChange(
                                day.key as DayIndex,
                                i,
                                "in_hours_start_time",
                                value
                              )
                            }
                            menuPlacementTop={day.value === "Sunday" || day.value === "Saturday" || day.value === "Friday"}
                          />
                        </div>
                        <div>-</div>
                        <div>
                          <TimeInput
                            value={opening_hour.in_hours_end_time}
                            onChange={(value) =>
                              handleTimeChange(
                                day.key as DayIndex,
                                i,
                                "in_hours_end_time",
                                value
                              )
                            }
                            menuPlacementTop={day.value === "Sunday" || day.value === "Saturday" || day.value === "Friday"}
                          />
                        </div>
                        {i === 0 ? (
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              handleAddOpeningHour(day.key as DayIndex);
                            }}
                            title="Add new interval"
                            variant="white"
                            icon
                          >
                            <IconPlus />
                          </Button>
                        ) : (
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              handleRemoveOpeningHour(
                                day.key as DayIndex,
                                i
                              );
                            }}
                            title="Remove the interval"
                            icon
                            variant="destructive"
                          >
                            <IconTrash />
                          </Button>
                        )}
                      </>
                    )}
                    {!opening_hour.is_removed && (
                      <>
                        {[
                          "id",
                          "in_hours_start_time",
                          "in_hours_end_time",
                          "_destroy",
                        ].map((field) => (
                          <input
                            key={field}
                            type="hidden"
                            name={`care_nav_setting[care_nav_opening_hours_attributes[${openingHourIndex}][${field}]]`}
                            value={opening_hour[field]}
                          />
                        ))}
                        <input
                          type="hidden"
                          name={`care_nav_setting[care_nav_opening_hours_attributes[${openingHourIndex}][day_of_week]]`}
                          value={day.value}
                        />
                        <input
                          type="hidden"
                          name={`care_nav_setting[care_nav_opening_hours_attributes[${openingHourIndex++}][suspended]]`}
                          value={opening_hour.suspended}
                        />
                        <input
                          type="hidden"
                          name="opening_hours_check"
                          value="true"
                        />
                      </>
                    )}
                  </div>
                  <div>
                    {emptyFieldErrors[`${day.key}_${i}`] && (
                      <div className="text-red-500">
                        {emptyFieldErrors[`${day.key}_${i}`]}
                      </div>
                    )}
                  </div>
                </>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-end px-6 py-5 gap-3 border-t">
        <Button variant="white" type="button" onClick={handleCancelClick}>
          {translate("base.cancel")}
        </Button>
        <Button
          variant="primary"
          type="submit"
          onClick={handleSubmitClick}
          loading={loading}
        >
          {translate("base.save")}
        </Button>
      </div>
    </ThemeProvider>
  );
};

export default InHoursSchedule;
