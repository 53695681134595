import React from 'react';
import { Button } from '@herohealthsoftware/ui';
import { IconExclamation } from '@herohealthsoftware/ui';

interface RemoveModalProps {
  onClose: () => void;
  onRemove: () => void;
  close: string;
  remove: string;
  taskCount?: number;
  isFlag?: boolean;
  descriptionIntro?: string;
  descriptionBody?: string;
}

const RemoveModal: React.FC<RemoveModalProps> = ({ onClose, onRemove, close, remove, taskCount, isFlag = true, descriptionIntro, descriptionBody }) => {
  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-50"></div>
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white py-2 rounded-md shadow-md z-50 w-[448px]">
        <div className="modal-content">
          <div className='px-4 py-4'>
            <div className='flex gap-2'>
              <div className="w-12 h-12 p-2.5 bg-yellow-100 rounded-full flex-col justify-center items-center gap-2.5 inline-flex">
                <div className="h-6 w-6 [&_path]:fill-yellow">
                  <IconExclamation />
                </div>
              </div>
              <div>
                <div className="text-hero-blue-700 text-xl font-bold">{ remove }</div>
                {isFlag ? (
                  <>
                    <div className='text-hero-blue-400 text-sm'>
                      {descriptionIntro}
                      {taskCount}
                      {descriptionBody}
                    </div>
                  </>
                ) : (
                  <>
                    <div className='text-hero-blue-400 text-sm'>{descriptionIntro}</div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-end px-4 py-3 border-t mt-4">
            <Button onClick={onClose} variant="white" className="mr-2">{ close }</Button>
            <Button onClick={onRemove} variant="primary">{ remove }</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RemoveModal;
