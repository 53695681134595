import React from "react";
import { translate } from "../../../../../lib/i18n";
import { Table } from "@herohealthsoftware/ui";
import Toggle from "./Toggle";

const Locations = ({ locations }: { locations: any }) => {
  const headers = {
    name: translate(
      "partners.findAPrivateGp.showOnFindAPrivateGp"
    ).toUpperCase(),
    toggle: null,
  };
  const rows = locations.map((location) => {
    return {
      values: {
        name: location.name,
        toggle: <Toggle location={location} />,
      },
    };
  });

  return <Table headers={headers} rows={rows} className="mb-8" />;
};

export default Locations;
