import { IconExternalLink, Link, ThemeProvider } from "@herohealthsoftware/ui";
import React, { useState } from "react";
import { translate } from "../../../../lib/i18n";
import Menu, { MenuOptions } from "../../../atoms/Menu";
import Overview from "./Overview";
import Locations from "./locations";
import Practitioners from "./practitioners";
import Services from "./services";

type SettingsSection = "overview" | "locations" | "practitioners" | "services";

type FindAPrivateGpIndexProps = {
  open: [SettingsSection, number];
  logo: string;
  practiceGroup: any;
  locations: any;
  practitioners: any;
  practiceGroupAppointmentTemplates: any;
};

const sections: MenuOptions = [
  {
    sections: [
      {
        name: "overview",
        label: translate("partners.findAPrivateGp.overview"),
      },
      {
        name: "locations",
        label: translate("partners.findAPrivateGp.locations"),
      },
      {
        name: "practitioners",
        label: translate("partners.findAPrivateGp.practitioners"),
      },
      {
        name: "services",
        label: translate("partners.findAPrivateGp.services"),
      },
    ],
  },
];

const index = ({
  logo,
  practiceGroup,
  locations,
  practitioners,
  practiceGroupAppointmentTemplates,
}: FindAPrivateGpIndexProps) => {
  const [activeSection, setActiveSection] =
    useState<SettingsSection>("overview");
  const [loading, setLoading] = useState(true);
  const handleChangeActiveSection = (section: SettingsSection) => {
    setLoading(true);
    setActiveSection(section);
  };

  return (
    <ThemeProvider>
      <div>
        <h1 className="text-2xl leading-8 font-bold text-hero-blue-700">
          {translate("partners.findAPrivateGp.findAPrivateGp")}
        </h1>

        <Link variant="primary">
          <a
            href={"https://findaprivategp.co.uk/practice_group/1"}
            target="_blank"
            className="flex items-center gap-1 no-underline mb-5 w-fit"
          >
            <span>
              {translate(
                "partners.findAPrivateGp.goToYourFindAPrivateGpShowPage"
              )}
            </span>
            <div className="h-4 w-4 [&_path]:fill-hero-primary-700">
              <IconExternalLink />
            </div>
          </a>
        </Link>

        <div className="flex items-start gap-4">
          <Menu
            selected={activeSection}
            options={sections}
            onClick={handleChangeActiveSection}
            className="min-w-48"
          />

          {activeSection === "overview" && (
            <Overview logo={logo} practiceGroup={practiceGroup} />
          )}

          {activeSection === "locations" && (
            <>
              <Locations locations={locations} />
            </>
          )}

          {activeSection === "practitioners" && (
            <Practitioners practitioners={practitioners} />
          )}

          {activeSection === "services" && (
            <Services appointments={practiceGroupAppointmentTemplates} />
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default index;
