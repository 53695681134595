import React from "react";
import Radio from "../../../atoms/Radio";

type RadioBoxProps = {
  label: string;
  icon: React.ReactNode;
  name: string;
  value: string;
  checked?: boolean;
  onChange?: (value) => void;
};

export default function RadioBox(props: RadioBoxProps) {
  const colors = props.checked
    ? "border border-hero-blue-500 bg-hero-blue-500 text-white"
    : "border border-hero-blue-200 bg-white text-hero-blue-700";

  return (
    <div
      className={`rounded-lg px-3.5 py-2.5 flex justify-between cursor-pointer
        ${colors}`}
      onClick={() => props.onChange({ target: { value: props.value } })}
    >
      <div className="flex flex-col gap-2 select-none">
        {props.icon}
        <div className="text-base leading-6 font-bold">{props.label}</div>
      </div>

      <Radio
        name={props.name}
        value={props.value}
        checked={props.checked}
        onChange={props.onChange}
      />
    </div>
  );
}
