import { IconTrash, Link, Select } from "@herohealthsoftware/ui";
import { uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { translate } from "../../../../lib/i18n";
import { calculateDiscount, formatPence } from "../../../../lib/money";
import { searchStripe } from "../../../../lib/stripe";
import { Coupon, InvoiceItem } from "../../../../lib/types";

export type CouponsInputProps = {
  coupons: Coupon[];
  setCoupons: (coupons: Coupon[]) => void;
  items: InvoiceItem[];
  editable: boolean;
};

export default function CouponsInput(props: CouponsInputProps) {
  const [showSelect, setShowSelect] = useState(false);
  const [couponOptions, setCouponOptions] = useState<Coupon[]>([]);

  const handleOptionClick = (result) => {
    props.setCoupons(uniqBy([...props.coupons, result], (coupon) => coupon.id));
    setShowSelect(false);
  };

  const handleRemoveClick = (removed: Coupon) => {
    props.setCoupons(
      props.coupons.filter((coupon) => coupon.id !== removed.id)
    );
  };

  useEffect(() => {
    searchStripe<Coupon>("", { type: "coupon" }).then((response) =>
      setCouponOptions(response.data)
    );
  }, []);

  return (
    <>
      {props.coupons.map((coupon, index) => (
        <React.Fragment key={coupon.id}>
          <div className="col-span-4 pr-2 flex items-center justify-end">
            <input
              type="hidden"
              name={`partners_stripe_invoice[coupons][${index}][id]`}
              value={coupon.id}
            />

            <input
              type="hidden"
              name={`partners_stripe_invoice[coupons][${index}][name]`}
              value={coupon.name}
            />
            {coupon.name}
          </div>

          <div className="col-span-1 flex items-center">
            {formatPence(calculateDiscount(props.items, [coupon]))}
          </div>

          {props.editable && (
            <div className="col-span-1 flex items-center justify-end">
              <div
                onClick={() => handleRemoveClick(coupon)}
                className="cursor-pointer p-1.5 border border-hero-blue-200 rounded
                  shadow-sm hover:bg-hero-blue-50"
              >
                <div className="[&_path]:fill-hero-red-500 h-4 w-4">
                  <IconTrash />
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      ))}

      {props.editable && showSelect ? (
        <>
          <div className="col-span-5">
            <Select
              className="text-left font-normal"
              placeholder={translate("partners.stripe.selectACoupon")}
              value={null}
              options={couponOptions.map((coupon: Coupon) => {
                return {
                  value: coupon,
                  label: coupon.name,
                };
              })}
              onChange={handleOptionClick}
            />
          </div>

          <div className="col-span-1 flex items-center justify-end">
            <div
              onClick={() => setShowSelect(false)}
              className="cursor-pointer p-1.5 border border-hero-blue-200 rounded
                shadow-sm hover:bg-hero-blue-50"
            >
              <div className="[&_path]:fill-hero-red-500 h-4 w-4">
                <IconTrash />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {props.editable && (
            <>
              <div className="px-2 pb-1 text-right col-span-4">
                <Link
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    setShowSelect(true);
                  }}
                  asChild={false}
                  variant="primary"
                >
                  {props.coupons.length > 0
                    ? translate("partners.stripe.addAnotherCoupon")
                    : translate("partners.stripe.addCoupon")}
                </Link>
              </div>
              <div className="col-span-2"></div>
            </>
          )}
        </>
      )}
    </>
  );
}
