import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import DataTable from "./DataTable";
import { type CreateApiClientWithEventProps } from "@herohealthsoftware/api-sdk-react";

const appointmentsBoookedTable = ({
  ...props
}: CreateApiClientWithEventProps & { ehrEnabled: string }) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <DataTable {...props} />
    </QueryClientProvider>
  );
};

export default appointmentsBoookedTable;
