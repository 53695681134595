import {
  Button,
  ButtonGroup,
  IconPencil,
  IconTrash,
  ObjectSearch,
  StripeSearchResult,
  Product as SearchResultProduct,
  Typography,
} from "@herohealthsoftware/ui";
import React, { useEffect, useState } from "react";

import { translate } from "../../../../lib/i18n";
import { formatProductPrice } from "../../../../lib/money";
import { searchStripe } from "../../../../lib/stripe";
import { Product } from "../../../../lib/types";

type ProductInputProps = {
  product: Product | null;
  editable: boolean;
  loading: boolean;
  onSave: (product: Product | null, create: boolean) => Promise<void>;
  createNewProduct?: boolean;
};

export default function ProductInput(props: ProductInputProps) {
  const [product, setProduct] = useState<Product | null>(props.product);
  const [showProductSearch, setShowProductSearch] = useState(!props.product);

  const handleSelectProduct = (product: Product | null) => {
    setProduct(product);
    setShowProductSearch(!product);
  };

  const handleEditProduct = () => {
    setShowProductSearch(true);
  };

  const handleResetProduct = () => {
    handleSelectProduct(props.product);
  };

  const handleSaveProduct = async () => {
    props.onSave(product, props.createNewProduct);
  };

  const buttonsDisabled = product?.stripe_id === props.product?.stripe_id;

  useEffect(handleResetProduct, [props.product]);

  if (!props.editable && !props.product) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <Typography color="primary" weight="bold" size="lg">
        <h4>{translate("partners.stripe.stripeProduct")}</h4>
      </Typography>

      {showProductSearch && (
        <ObjectSearch<Product>
          onSelect={handleSelectProduct}
          renderOption={(product) => (
            <StripeSearchResult product={product as SearchResultProduct} />
          )}
          fetcher={async (query) =>
            searchStripe<Product>(query, {
              type: "product",
              filters: { type: "recurring" },
            })
          }
          messages={{
            placeholder: translate("partners.stripe.findOrAddAProduct"),
            resultsHeading: translate("partners.stripe.stripeProducts"),
            loading: translate("partners.stripe.loadingStripeProducts"),
            noResults: translate("partners.stripe.noMatchingProductsFound"),
          }}
          className="w-2/3"
        />
      )}

      {product && !showProductSearch && (
        <div
          className="flex flex-row gap-2 justify-between items-center
                  py-3 px-4 border border-hero-blue-200 rounded-lg"
        >
          <div className="flex flex-col min-w-0 gap-1">
            <Typography color="primary" size="base" weight="bold">
              <h5 className="truncate">{product.name}</h5>
            </Typography>

            <Typography color="light" size="sm">
              <p>{product.id}</p>
            </Typography>
          </div>

          <div>
            <Typography color="primary" size="base" weight="medium">
              <p className="text-nowrap">{formatProductPrice(product)}</p>
            </Typography>
          </div>

          {props.editable && (
            <ButtonGroup>
              {props.createNewProduct && (
                <Button
                  variant="white"
                  size="sm"
                  icon
                  disabled={props.loading}
                  onClick={handleEditProduct}
                >
                  <div className="h-4 w-4">
                    <IconPencil />
                  </div>
                </Button>
              )}

              <Button
                variant="white"
                size="sm"
                icon
                disabled={props.loading}
                onClick={() => handleSelectProduct(null)}
              >
                <div className="[&_path]:fill-hero-red-500 h-4 w-4">
                  <IconTrash />
                </div>
              </Button>
            </ButtonGroup>
          )}
        </div>
      )}

      {props.editable && (
        <div className="flex flex-row gap-4 justify-end items-center">
          <Button
            variant="white"
            disabled={buttonsDisabled || props.loading}
            onClick={handleResetProduct}
          >
            {translate("base.cancel")}
          </Button>

          <Button
            variant="primary"
            loading={props.loading}
            disabled={buttonsDisabled}
            onClick={handleSaveProduct}
          >
            {translate("base.save")}
          </Button>
        </div>
      )}
    </div>
  );
}
