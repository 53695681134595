import {
  EntityCard,
  IconCloudConnected,
  Tooltip,
  formatPatientDobStd,
  formatPatientEhrPartnerIdStd,
  formatPatientNameStd,
} from "@herohealthsoftware/ui";
import ld from "lodash";
import { DateTime } from "luxon";
import React from "react";
import { translate } from "../../lib/i18n";
import { PatientRecord } from "../../lib/types";
import * as Routes from "../../routes";

type PatientEntityCardProps = Omit<
  React.ComponentProps<typeof EntityCard>,
  "title" | "information"
> & {
  patient_record: PatientRecord;
};

export default function PatientEntityCard({
  patient_record,
  ...props
}: PatientEntityCardProps) {
  const href = patient_record.client_id
    ? Routes.patient_dashboard_path(patient_record.client_id)
    : undefined;

  // NOTE: GHA numbers have not been implemented here.
  const hasNhsNumber = !ld.isEmpty(patient_record.nhs_number?.trim());

  const ehrPartnerId = hasNhsNumber
    ? patient_record.nhs_number
    : patient_record.ehr_partner_id || patient_record.client_id || "—";

  const ehrPartnerLabel = hasNhsNumber
    ? translate("base.partner.nhs")
    : translate(`base.partner.${patient_record.ehr_partner}`) ||
      translate("base.partner.hero");

  const information = {
    [ehrPartnerLabel]: formatPatientEhrPartnerIdStd({
      ehrPartnerId: String(ehrPartnerId),
      ehrPartner: ehrPartnerLabel,
    }),
    [translate("base.born")]: formatPatientDobStd({
      dob: new Date(DateTime.fromISO(patient_record.dob)),
    }),
    [translate("base.sex")]: ld.capitalize(patient_record.sex),
  };

  return (
    <EntityCard
      title={
        <div className="flex items-center gap-1">
          <div>
            {formatPatientNameStd({
              title: ld.capitalize(patient_record.title),
              firstName: patient_record.first_name,
              lastName: patient_record.last_name,
            })}
          </div>

          {patient_record.last_sync_at_text && (
            <Tooltip content={patient_record.last_sync_at_text}>
              <div className="w-6 h-6 [&_path]:fill-emis-400">
                <IconCloudConnected />
              </div>
            </Tooltip>
          )}
        </div>
      }
      href={href}
      information={information}
      {...props}
    />
  );
}
