import React, { useState } from "react";
import { translate } from "../../../../lib/i18n";
import Toggle from "../../../atoms/Toggle";
import Hero from "../../../../lib/hero";
import * as Routes from "../../../../routes";

const Home = ({
  logo,
  practiceGroup,
}: {
  logo: string;
  practiceGroup: any;
}) => {
  const [checked, setChecked] = useState(
    practiceGroup.find_a_private_gp_enabled
  );

  const handleToggle = async () => {
    const value = !checked;
    setChecked(value);

    const response = await Hero.fetch(
      Routes.practice_group_path(practiceGroup),
      {
        method: "PATCH",
        body: {
          practice_group: {
            find_a_private_gp_enabled: value,
          },
        },
      }
    );

    if (!response.ok) {
      setChecked(!value);
    }
  };
  return (
    <div className="p-6 rounded-lg border border-hero-blue-200 flex gap-3">
      <img src={logo} className="h-24 w-24 object-cover rounded-sm mr-2" />
      <div>
        <div className="text-2xl leading-8 font-bold">
          {translate("partners.findAPrivateGp.privateCareSearchEngine")}
        </div>
        <div className="mt-5">
          <Toggle
            checked={checked}
            onChange={handleToggle}
            label={translate(
              "partners.findAPrivateGp.displayMyPracticeOnFindAPrivateGp"
            )}
          />
        </div>
        <div className="mt-5 text-base leading-6 font-normal">
          {translate("partners.findAPrivateGp.description")}
        </div>
      </div>
    </div>
  );
};

export default Home;
