import React, { useState } from "react";
import { translate } from "../../../../../lib/i18n";
import { Table } from "@herohealthsoftware/ui";
import Select from "./Select";

const services = [
  { label: "Standard consultation", value: "standard_consultation" },
  { label: "Extended consultation", value: "extended_consultation" },
  { label: "Blood test", value: "blood_test" },
  { label: "Sexual health", value: "sexual_health" },
  { label: "Annual medical", value: "annual_medical" },
];

const Services = ({ appointments }: { appointments: any }) => {
  const headers = {
    name: translate(
      "partners.findAPrivateGp.showOnFindAPrivateGp"
    ).toUpperCase(),
    select: null,
  };
  const [unselectedAppointments, setUnselectedAppointments] = useState(
    appointments.filter(
      (appointment) => appointment.find_a_private_gp_service === "undefined"
    )
  );
  const [selectedAppointments, setSelectedAppointments] = useState(
    appointments.filter(
      (appointment) => appointment.find_a_private_gp_service !== "undefined"
    )
  );

  const formattedUnselectedAppointmentsOptions = unselectedAppointments.map(
    (unselectedAppointment) => {
      return {
        label: unselectedAppointment.description,
        value: unselectedAppointment,
      };
    }
  );

  const handleChange = ({ prevOption, newOption, service }) => {
    if (newOption !== "undefined") {
      setUnselectedAppointments(
        unselectedAppointments.filter(
          (appointment) => appointment !== newOption
        )
      );
      if (prevOption) {
        setSelectedAppointments([
          ...selectedAppointments.filter(
            (appointment) => appointment !== prevOption
          ),
          {
            ...newOption,
            find_a_private_gp_service: service,
          },
        ]);
      } else {
        setSelectedAppointments([
          ...selectedAppointments,
          {
            ...newOption,
            find_a_private_gp_service: service,
          },
        ]);
      }
    } else {
      setUnselectedAppointments([...unselectedAppointments, prevOption]);
      setSelectedAppointments(
        selectedAppointments.filter((appointment) => appointment !== prevOption)
      );
    }
  };

  const rows = services.map((service) => {
    return {
      values: {
        name: service.label,
        select: (
          <Select
            service={service.value}
            options={formattedUnselectedAppointmentsOptions}
            selectedAppointment={selectedAppointments.find(
              (appointments) =>
                appointments.find_a_private_gp_service === service.value
            )}
            onChange={handleChange}
          />
        ),
      },
    };
  });

  return <Table headers={headers} rows={rows} className="mb-8" />;
};

export default Services;
