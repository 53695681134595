import React from "react";
import { IconPencil, IconTrash } from "@herohealthsoftware/ui";

import { translate } from "../../../../lib/i18n";
import { InvoiceItem } from "../../../../lib/types";
import { formatPence, formatPounds, totalInPence } from "../../../../lib/money";
import { ItemsInputConfig } from "./ItemsInput";

type ItemRowProps = {
  index: number;
  editable: boolean;
  item: InvoiceItem;
  onEditClick: (item: InvoiceItem, index: number) => void;
  onRemoveClick: (item: InvoiceItem, index: number) => void;
  config: ItemsInputConfig;
};

export function ItemRow(props: ItemRowProps) {
  return (
    <div className="p-4 border-b border-hero-blue-200">
      <input
        type="hidden"
        name={`${props.config.inputNamePrefix}[${props.index}][stripe_id]`}
        value={props.item.stripe_id || ""}
      />

      <input
        type="hidden"
        name={`${props.config.inputNamePrefix}[${props.index}][product]`}
        value={props.item.product?.stripe_id || ""}
      />

      <input
        type="hidden"
        name={`${props.config.inputNamePrefix}[${props.index}][price]`}
        value={props.item.price}
      />

      <input
        type="hidden"
        name={`${props.config.inputNamePrefix}[${props.index}][description]`}
        value={props.item.description}
      />

      <input
        type="hidden"
        name={`${props.config.inputNamePrefix}[${props.index}][quantity]`}
        value={props.item.quantity}
      />

      <input
        type="hidden"
        name={`${props.config.inputNamePrefix}[${props.index}][unit_amount_in_pounds]`}
        value={props.item.unit_amount_in_pounds}
      />

      <input
        type="hidden"
        name={`${props.config.inputNamePrefix}[${props.index}][create]`}
        value={props.item.create ? "true" : ""}
      />

      <div
        className={`grid ${
          props.editable ? "grid-cols-8 gap-2" : "grid-cols-7 gap-6"
        }`}
      >
        <div className="col-span-4">
          <div className="font-bold">{props.item.description}</div>

          <div className="flex gap-1 text-xs text-hero-blue-400">
            <div>{translate("partners.stripe.code")}</div>
            <div className="font-normal mr-1">
              {props.item.product?.code || <>&ndash;</>}
            </div>

            <div>{translate("base.category")}</div>
            <div className="font-normal mr-1">
              {props.item.product?.category || <>&ndash;</>}
            </div>
          </div>
        </div>

        <div>{props.item.quantity}</div>
        <div>{formatPounds(props.item.unit_amount_in_pounds)}</div>
        <div>{formatPence(totalInPence([props.item]))}</div>

        {props.editable && (
          <div className="flex items-center justify-end">
            <div
              onClick={() => props.onEditClick(props.item, props.index)}
              className="cursor-pointer p-1.5 border border-hero-blue-200 rounded-l shadow-sm hover:bg-hero-blue-50"
            >
              <div className="h-4 w-4">
                <IconPencil />
              </div>
            </div>

            <div
              onClick={() => props.onRemoveClick(props.item, props.index)}
              className="cursor-pointer p-1.5 border border-l-0 border-hero-blue-200 rounded-r shadow-sm hover:bg-hero-blue-50"
            >
              <div className="[&_path]:fill-hero-red-500 h-4 w-4">
                <IconTrash />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
