import React from 'react';
import { Button, IconPlus1, IconTrash } from '@herohealthsoftware/ui';
import { AdminRecord, PracticeGroupItem } from '../../lib/types';

interface SaveModalProps {
  isCreateMode: boolean;
  selectedAdmin: PracticeGroupItem | null;
  selectedMembers?: AdminRecord[];
  setNameError: React.Dispatch<React.SetStateAction<string>>;
  setSelectedAdmin: React.Dispatch<React.SetStateAction<PracticeGroupItem>>;
  nameError: string;
  errorMessage?: string;
  memberError?: string;
  handleCloseModal: () => void;
  handleCreateRecord: () => Promise<void>;
  handleUpdateRecord: () => Promise<void>;
  handleRemoveMember?: (memberId: number) => void;
  handleAddMember?: (selectedMember: AdminRecord) => void;
  showAddMemberDropdown?: boolean;
  setShowAddMemberDropdown?: React.Dispatch<React.SetStateAction<boolean>>;
  searchQuery?: string;
  setSearchQuery?: React.Dispatch<React.SetStateAction<string>>;
  filterAdmins?: () => AdminRecord[];
  isFlag?: boolean;
  title: string;
  name: string;
  close: string;
  save: string;
  teamMembers?: string;
  addMember?: string;
}

const SaveModal: React.FC<SaveModalProps> = ({
  isCreateMode,
  selectedAdmin,
  selectedMembers,
  setNameError,
  setSelectedAdmin,
  nameError,
  errorMessage,
  memberError,
  handleCloseModal,
  handleCreateRecord,
  handleUpdateRecord,
  handleRemoveMember,
  handleAddMember,
  showAddMemberDropdown,
  setShowAddMemberDropdown,
  searchQuery,
  setSearchQuery,
  filterAdmins,
  isFlag = true,
  title,
  name,
  close,
  save,
  teamMembers,
  addMember
}) => {
  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-50"></div>
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white py-2 rounded-md shadow-md z-50 w-[448px]">
        <div className={`modal-content`}>
          <div className="w-full justify-start items-start gap-3 inline-flex border-b px-4 py-1">
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
              <div className="self-stretch text-hero-blue-700 text-xl font-bold leading-7">{title}</div>
            </div>
          </div>
          <div className='flex flex-col gap-1 mb-4 px-4 py-2'>
            <div>   
              <label className="text-hero-blue-700 text-sm font-semibold">{ name }</label>
            </div>
            <div>
              <input
                type="text"
                value={selectedAdmin?.name || ''}
                onChange={(e) => {
                  setSelectedAdmin((prev) => ({ ...prev, name: e.target.value }));
                  setNameError('');
                }}
                className={`w-full border border-1 text-hero-blue-700 cursor-pointer rounded py-1 px-1 focus:outline-none ${nameError ? 'bg-red-50 border-red-300' : 'bg-white border-hero-blue-200'}`}
              />
              {nameError && (
                <div className="text-red-700 text-sm mb-2">{nameError}</div>
              )}
            </div>
          </div>
          {isFlag && (
            <>
              <div className='px-4 py-1'>
                {errorMessage && (
                  <div className="text-red-700 text-sm mb-2">{errorMessage}</div>
                )}
                <label className='text-hero-blue-700 text-lg font-bold'>{ teamMembers }</label>
              </div>
              <div className={`${selectedMembers.length ? 'flex flex-col border border-hero-blue-200 rounded-lg mx-4 mt-1' : ''} ${showAddMemberDropdown ? 'pb-8' : ''}`}>
                <div>
                  <ul className='max-h-36 overflow-y-scroll'>
                    {selectedMembers.map((admin) => (
                      <li key={admin.id}>
                        <div className='w-full flex justify-between align-items-center px-4 py-2 border-hero-blue-200 border-b'>
                          <div className='text-hero-blue-700 font-bold text-md'>
                            {admin.first_name} {admin.last_name}
                          </div>
                          <Button
                            type="button"
                            onClick={() => handleRemoveMember(admin.id)}
                            variant='destructive'
                          >
                            <IconTrash />
                          </Button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className='relative'>
                  {!showAddMemberDropdown && (
                    <Button
                      variant='white'
                      type="button"
                      onClick={() => setShowAddMemberDropdown(true)}
                      className={`${selectedMembers.length ? 'ml-2 my-4' : 'ml-4 my-2'}`}
                    >
                      <div className='flex justify-center align-items-center gap-2'>
                        <div><IconPlus1 /></div>
                        <div>{ addMember }</div>
                      </div>
                    </Button>
                  )}
                  {memberError && (
                    <div className="text-red-700 text-sm mb-2 ml-4 mb-2">{memberError}</div>
                  )}
                  {showAddMemberDropdown && (
                    <div className={`absolute -top-3 w-80 z-50 my-1 ${selectedMembers.length ? 'left-3 mt-2' : 'mx-4'}`}>
                      <div className='bg-white border border-hero-blue-100 rounded-md shadow'>
                        <div className="relative">
                          <i className="icon-search text-hero-blue-400 absolute left-3 top-1/2 transform -translate-y-1/2"></i>
                          <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className='w-full px-2 py-1 pl-8 focus:outline-turquoise'
                          />
                        </div>
                      </div>
                      <div className='bg-white border border-hero-blue-100 rounded-md px-2 py-1 shadow max-h-48 overflow-y-auto'>
                        <ul className='mb-0'>
                          {filterAdmins().map((admin_record) => (
                            <li key={admin_record.id}>
                              <button
                                type="button"
                                onClick={() => {
                                  handleAddMember(admin_record)
                                  setShowAddMemberDropdown(false)
                                }}
                                className='w-full flex flex-col py-1'
                              >
                                <div className='text-hero-blue-700 font-bold'>{admin_record.first_name} {admin_record.last_name}</div>
                                <div className='text-hero-blue-500 word-break'>{admin_record.email}</div>
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          <div className="flex justify-end px-4 py-2 border-t mt-4">
            <Button onClick={handleCloseModal} variant="white" className="mr-2">{close }</Button>
            <Button onClick={isCreateMode ? handleCreateRecord : handleUpdateRecord} variant="primary">{ save }</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SaveModal;
