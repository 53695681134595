import { IconX } from "@herohealthsoftware/ui";
import React from "react";

type PageHeaderProps = {
  heading: React.ReactNode;
  subheading?: React.ReactNode;
  backCross?: boolean;
  backPath?: string;
  children?: React.ReactNode;
};

export default function PageHeader(props: PageHeaderProps) {
  // NOTE: based on app/views/layouts/settings/_header.html.erb

  return (
    <div
      className="bg-white px-10 top-0 py-5 border-b border-hero-blue-200 sticky z-30"
      id="settings-header"
      data-test="form-header"
    >
      <div className="flex items-center justify-between">
        <div>
          <div className="flex items-center">
            {props.backCross && (
              <a href={props.backPath} className="leading-none">
                <div className="mr-3 w-4 h-4">
                  <IconX />
                </div>
              </a>
            )}

            <h1
              className={`text-2xl leading-8 font-bold text-hero-blue-700 ${
                props.subheading ? "mb-0.5" : "m-0"
              }`}
            >
              {props.heading}
            </h1>
          </div>

          {props.subheading && (
            <div className={`max-w-3xl ${props.backCross && "ml-7"}`}>
              <p className="leading-6 text-hero-blue-500">{props.subheading}</p>
            </div>
          )}
        </div>

        {props.children}
      </div>
    </div>
  );
}
