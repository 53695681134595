type HeroFetchOptions = {
  method?: string;
  headers?: Record<string, string>;
  body?: Record<string, unknown>;
};

declare const Rails: any;

const Hero = {
  fetch: async (
    url: string,
    options: HeroFetchOptions = {}
  ): Promise<Response> => {
    const csrfToken = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    if (!csrfToken) throw new Error("CSRF token not found");

    const headers = Object.assign({}, options.headers || {}, {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    });

    return fetch(url, {
      method: options.method || "GET",
      body: options.body && JSON.stringify(options.body),
      headers,
    });
  },
  remote: (options) => {
    Rails.ajax(options);
  },
};

export default Hero;
