import React, { useState } from "react";

import * as Routes from "../../../../routes";
import Hero from "../../../../lib/hero";
import Toggle from "../../../atoms/Toggle";

type MetadataSettingsRowProps = {
  metadataField: string;
  value: string;
  sectionKey: string;
  checked: boolean;
};

export default function MetadataSettingsRow(props: MetadataSettingsRowProps) {
  const [checked, setChecked] = useState(
    props.checked === undefined ? true : props.checked
  );

  const handleToggle = async () => {
    const value = !checked;
    setChecked(value);

    const response = await Hero.fetch(
      Routes.partners_stripe_admins_settings_path(),
      {
        method: "POST",
        body: {
          settings: {
            stripe: {
              metadata: {
                [props.sectionKey]: {
                  [props.metadataField]: value,
                },
              },
            },
          },
        },
      }
    );

    if (!response.ok) {
      setChecked(!value);
    }
  };

  return (
    <div key={props.metadataField} className="flex justify-between w-80">
      {props.value}
      <Toggle checked={checked} onChange={handleToggle} />
    </div>
  );
}
