import {
  Badge,
  Button,
  ErrorSummary,
  IconMembership,
  IconPlus1,
  IconTrash,
  Spinner,
  Typography,
} from "@herohealthsoftware/ui";
import React, { useState } from "react";
import Hero from "../../../../lib/hero";
import { translate } from "../../../../lib/i18n";
import {
  Member,
  MembershipPolicy,
  StripeSettings,
} from "../../../../lib/types";
import * as Routes from "../../../../routes";
import PatientEntityCard from "../../../shared/PatientEntityCard";
import PatientSearch, { Patient } from "../../../shared/PatientSearch";

export type MembershipPolicyMembersProps = {
  membershipPolicy: MembershipPolicy;
  settings: StripeSettings;
};

export default function MembershipPolicyMembers(
  props: MembershipPolicyMembersProps
) {
  const [members, setMembers] = useState<Member[]>(
    props.membershipPolicy.members
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Array<{ message: string }>>([]);
  const [showPatientSearch, setShowPatientSearch] = useState<boolean>(false);

  const handleAddMember = async (patient: Patient) => {
    setLoading(true);
    setErrors([]);

    try {
      const response = await Hero.fetch(
        Routes.partners_stripe_admins_members_path(),
        {
          method: "POST",
          body: {
            member: {
              patient_id: patient.id,
              membership_policy_id: props.membershipPolicy.id,
            },
          },
        }
      );
      const data = await response.json();

      if (response.ok) {
        setMembers([...members, data]);
        setShowPatientSearch(false);
      } else {
        setErrors(data.errors.map((error: string) => ({ message: error })));
      }
    } catch (error) {
      console.error(error);
      setErrors([{ message: translate("base.unexpectedError") }]);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveMember = async (member: Member) => {
    setLoading(true);
    setErrors([]);

    try {
      const response = await Hero.fetch(
        Routes.partners_stripe_admins_member_path(member.id),
        {
          method: "DELETE",
          body: {},
        }
      );

      if (response.ok) {
        setMembers(members.filter((m) => m.id !== member.id));
      } else {
        const json = await response.json();
        setErrors(json.errors.map((error: string) => ({ message: error })));
      }
    } catch (error) {
      console.error(error);
      setErrors([{ message: translate("base.unexpectedError") }]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <Typography size="xl" weight="bold">
        <h3>{translate("partners.stripe.patientsLinkedToThisPolicy")}</h3>
      </Typography>

      {errors.length > 0 && <ErrorSummary items={errors} />}

      <div>
        {loading && (
          <div className="flex flex-col justify-center items-center py-5 px-6 border border-hero-blue-200 rounded-t-lg">
            <Spinner className="w-8 h-8 mb-2 fill-hero-blue-700" />

            <div className="leading-6 font-medium">
              {translate("partners.stripe.loadingStripeMembers")}
            </div>
          </div>
        )}

        {!loading && members.length === 0 && (
          <div className="w-full flex flex-col gap-1 justify-center items-center py-5 px-6 border border-hero-blue-200 rounded-t-lg">
            <div className="h-12 w-12 mb-2 flex items-center justify-center bg-hero-blue-100 rounded-lg">
              <div className="w-6 h-6">
                <IconMembership />
              </div>
            </div>

            <Typography size="base" weight="bold">
              <h6>{translate("partners.stripe.noMembers")}</h6>
            </Typography>

            <Typography size="sm" weight="medium" color="light">
              <p>{translate("partners.stripe.noMembersHelp")}</p>
            </Typography>
          </div>
        )}

        {!loading &&
          members.length > 0 &&
          members.map((member) => (
            <div
              key={member.id}
              className="flex flex-row justify-between items-center py-5 px-6
                border border-b-0 last:border-b border-hero-blue-200
                first:rounded-t-lg"
            >
              <div className="flex flex-col items-start">
                <PatientEntityCard
                  patient_record={member.patient_record}
                  size="md"
                />

                {member.type && (
                  <Badge color="neutral" size="sm">
                    {member.type}
                  </Badge>
                )}
              </div>

              <Button
                variant="white"
                size="sm"
                icon
                onClick={() => handleRemoveMember(member)}
              >
                <div className="[&_path]:fill-hero-red-500">
                  <IconTrash />
                </div>
              </Button>
            </div>
          ))}

        <div className="flex py-5 px-6 border border-hero-blue-200 rounded-b-lg">
          {showPatientSearch && (
            <PatientSearch
              onChange={({ value: patient }) => {
                if (patient) {
                  handleAddMember(patient);
                }
              }}
              {...props.settings.patient_search}
            />
          )}

          {!showPatientSearch && (
            <Button variant="white" onClick={() => setShowPatientSearch(true)}>
              <div className="flex flex-row gap-2">
                <IconPlus1 />
                {translate("partners.stripe.addPatient")}
              </div>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
