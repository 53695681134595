import { formatPatientNameStd, Spinner, Table } from "@herohealthsoftware/ui";
import React, { useEffect, useState } from "react";
import { formatISODateTime } from "../../../../lib/datetime";
import Hero from "../../../../lib/hero";
import { translate } from "../../../../lib/i18n";
import { Member, StripeSettings } from "../../../../lib/types";
import * as Routes from "../../../../routes";
import StatusBadge from "../StatusBadge";
import MembershipPolicySidebar from "./MembershipPolicySidebar";

type MembersSettingsProps = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  settings: StripeSettings;
};

export default function MembersSettings(props: MembersSettingsProps) {
  const [members, setMembers] = useState<Member[]>([]);
  const [selectedMember, setSelectedMember] = useState<Partial<Member> | null>(
    null
  );

  const fetchMembers = async () => {
    const response = await Hero.fetch(
      Routes.partners_stripe_admins_members_path()
    );

    if (response.ok) {
      const data = await response.json();
      setMembers(data);
      props.setLoading(false);
    }
  };

  const memberRowMapper = (member: Member) => {
    return {
      values: {
        patient: formatPatientNameStd({
          firstName: member.patient_record.first_name,
          lastName: member.patient_record.last_name,
          title: member.patient_record.title,
        }),
        scheme: member.membership_policy.membership_scheme.name || <>&mdash;</>,
        status: (
          <StatusBadge
            resource="membership_policy"
            status={member.membership_policy.status}
          />
        ),
        created: formatISODateTime(member.created_at, "short-month-name-date"),
      },
      onClick: () => setSelectedMember(member),
      className: "cursor-pointer",
    };
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  return (
    <div className="flex flex-col gap-4 w-full items-start">
      {selectedMember && (
        <MembershipPolicySidebar
          membershipPolicy={selectedMember.membership_policy}
          onClose={(reload) => {
            if (reload) {
              props.setLoading(true);
              fetchMembers();
            }

            setSelectedMember(null);
          }}
          settings={props.settings}
          sidebar={true}
        />
      )}

      {props.loading && (
        <div
          className="w-full h-64 flex flex-col items-center justify-center
                border border-hero-blue-200 rounded p-6"
        >
          <div className="h-8 w-8">
            <Spinner />
          </div>

          <div className="mt-2 font-medium">
            {translate("partners.stripe.tables.members.loadingMessage")}
            <>&hellip;</>
          </div>
        </div>
      )}

      {!props.loading && members.length === 0 && (
        <div
          className="w-full h-64 flex flex-col items-center justify-center
                border border-hero-blue-200 rounded p-6"
        >
          <div className="mt-2 font-medium text-hero-blue-400">
            {translate("partners.stripe.tables.members.noResultsMessage")}
          </div>
        </div>
      )}

      {!props.loading && members.length > 0 && (
        <div className="w-full">
          <Table
            headers={translate("partners.stripe.tables.members.headers")}
            rows={members.map(memberRowMapper)}
            className="w-full"
          />
        </div>
      )}
    </div>
  );
}
