import React from "react";
import { MembershipPolicy } from "../../../lib/types";
import { IconArrowNarrowRight, Spinner } from "@herohealthsoftware/ui";
import { translate } from "../../../lib/i18n";
import { formatPlanPrice, formatProductPrice } from "../../../lib/money";
import StatusBadge from "./StatusBadge";
import { formatISODateTime } from "../../../lib/datetime";

type MembershipPoliciesListProps = {
  membershipPolicies: MembershipPolicy[];
  loading: boolean;
  error: string | null;
  handleMembershipPolicyClick: (policy: MembershipPolicy) => void;
};

export default function MembershipPoliciesList(
  props: MembershipPoliciesListProps
) {
  if (props.error) {
    return (
      <div
        className="w-full flex flex-col items-center justify-center
                border border-hero-blue-200 rounded p-6"
      >
        <div className="font-medium">{props.error}</div>
      </div>
    );
  }

  if (props.loading) {
    return (
      <div
        className="w-full flex flex-col items-center justify-center
                border border-hero-blue-200 rounded p-6"
      >
        <div className="h-8 w-8">
          <Spinner />
        </div>

        <div className="mt-2 font-medium">
          {translate("partners.stripe.loadingStripeMemberships")}
          <>&hellip;</>
        </div>
      </div>
    );
  }

  return (
    <div className="border border-hero-blue-200 rounded-lg">
      {props.membershipPolicies.length < 1 ? (
        <div
          className="flex items-center justify-center font-semibold
                text-hero-blue-700 py-6"
        >
          {translate("partners.stripe.thisPatientHasNoMemberships")}
        </div>
      ) : (
        props.membershipPolicies.map((membershipPolicy) => (
          <div
            key={membershipPolicy.id}
            onClick={(event) => {
              if (event.target instanceof HTMLAnchorElement) return;

              props.handleMembershipPolicyClick(membershipPolicy);
            }}
            className="group px-4 py-3 border-b border-hero-blue-200 last:border-b-0
                cursor-pointer hover:bg-hero-blue-700/5 last:rounded-b-lg"
          >
            <div className="flex justify-between text-hero-blue-700 leading-6 font-bold mb-1">
              <div className="flex flex-row items-center gap-1">
                <div className="border-b border-dashed border-hero-blue-700 group-hover:border-solid">
                  {membershipPolicy.membership_scheme
                    ? membershipPolicy.membership_scheme.name
                    : translate("partners.stripe.noMembershipScheme")}
                </div>

                <div className="h-4 w-4 [&_path]:fill-hero-blue-700 hidden group-hover:block">
                  <IconArrowNarrowRight />
                </div>
              </div>

              {membershipPolicy.subscription?.plan && (
                <div>{formatPlanPrice(membershipPolicy.subscription.plan)}</div>
              )}
            </div>

            <div className="flex justify-between items-center">
              <div>
                <div className="text-hero-blue-400 text-sm leading-5 mb-1">
                  {formatISODateTime(
                    membershipPolicy.created_at,
                    "short-month-name"
                  )}
                </div>

                <StatusBadge
                  resource="membership_policy"
                  status={membershipPolicy.status}
                />
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}
