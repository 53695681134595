import * as Routes from "../routes";
import { Customer } from "./types";
import Hero from "./hero";

export async function fetchCustomer(customer: Customer) {
  const url = Routes.partners_stripe_admins_customer_path(customer.stripe_id);

  const response = await fetch(url);
  const data = await response.json();

  return data;
}

export async function createCustomer(email: string, name: string) {
  const url = Routes.partners_stripe_admins_customers_path();
  const body = { customer: { email, name } };

  const response = await Hero.fetch(url, {
    method: "POST",
    body: body,
  });

  if (response.status !== 200) {
    throw new Error("An error occurred while creating the customer");
  } else {
    const data = await response.json();

    return data;
  }
}

export async function updateCustomer(customer: Customer) {
  const url = Routes.partners_stripe_admins_customer_path(customer.stripe_id);
  const body = { customer };

  const response = await Hero.fetch(url, {
    method: "PATCH",
    body: body,
  });

  if (response.status === 422) {
    throw new Error("Please provide a valid email address");
  } else if (response.status !== 200) {
    throw new Error("An error occurred while updating the customer");
  } else {
    const data = await response.json();

    return data;
  }
}

export function getDefaultSource(customer?: Customer) {
  if (!customer) return;

  return customer.default_source?.id || (customer.sources || [])[0]?.id;
}
