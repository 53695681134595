import React, { useEffect, useState } from "react";

import Hero from "../../../../lib/hero";
import { translate } from "../../../../lib/i18n";
import {
  Appointment,
  Customer,
  Invoice,
  MembershipPolicy,
  PatientRecord,
  StripeSettings,
} from "../../../../lib/types";
import * as Routes from "../../../../routes";
import CustomerSidebar from "../../../partners/stripe/CustomerSidebar";
import ShowInvoice from "../../../partners/stripe/ShowInvoice";
import MembershipPolicySidebar from "../../../partners/stripe/settings/MembershipPolicySidebar";

export type Section = "patient" | "appointment" | "memberships" | "settings";

export type StripeProps = {
  patient_record: PatientRecord;
  appointment: Appointment;
  customer: Customer;
  patient_customer?: Customer;
  invoice?: Invoice;
  invoices: Invoice[];
  page?: number;
  settings: StripeSettings;
};

export default function Stripe(props: StripeProps) {
  const [invoice, setInvoice] = useState<Invoice | null>(props.invoice || null);
  const [membershipPolicy, setMembershipPolicy] =
    useState<MembershipPolicy | null>(null);
  const [customer, setCustomer] = useState<Customer | null>(props.customer);
  const [patientCustomer, setPatientCustomer] = useState<Customer | null>(
    props.patient_customer
  );
  const [invoices, setInvoices] = useState<Invoice[]>(props.invoices || []);
  const [membershipPolicies, setMembershipPolicies] = useState<
    MembershipPolicy[]
  >([]);
  const [activeSection, setActiveSection] = useState<Section>(
    props.appointment ? "appointment" : "patient"
  );
  const [page, setPage] = useState<number>(props.page || 1);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const handleInvoiceClick = (invoice: Invoice) => {
    setInvoice(invoice);
  };

  const handleMembershipPolicyClick = (policy: MembershipPolicy) => {
    setMembershipPolicy(policy);
  };

  const handleSectionChange = (section: Section) => {
    setPage(1);
    setActiveSection(section);
  };

  const pagination = {
    onReset: () => setPage(1),
    onPrevious: () => setPage(Math.max(page - 1, 1)),
    onNext: () => setPage(page + 1),
    disabled: {
      reset: page === 1,
      previous: page === 1,
      next: invoices.length < 6,
    },
  };

  const stripeBaseUrl = props.settings.dashboard_url;

  const fetchInvoices = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await Hero.fetch(
        Routes.partners_stripe_admins_invoices_path({
          page,
          patient_record_id: props.patient_record.id,
          appointment_id: props.appointment?.id,
          filters: { appointment: activeSection === "appointment" ? "1" : "0" },
        }),
        { method: "GET" }
      );

      const data = await response.json();
      setInvoices(data.invoices);
    } catch (error) {
      console.error(error);
      setError(translate("base.unexpectedError"));
    } finally {
      setLoading(false);
    }
  };

  const fetchMembershipPolicies = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await Hero.fetch(
        Routes.partners_stripe_admins_membership_policies_path({
          patient_record_id: props.patient_record.id,
        }),
        { method: "GET" }
      );

      const data = await response.json();
      setMembershipPolicies(data);
    } catch (error) {
      console.error(error);
      setError(translate("base.unexpectedError"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (["appointment", "patient"].includes(activeSection)) {
      fetchInvoices();
    }

    if (activeSection === "memberships") {
      fetchMembershipPolicies();
    }
  }, [page, activeSection]);

  if (invoice) {
    return (
      <ShowInvoice
        invoice={invoice}
        stripeBaseUrl={stripeBaseUrl}
        handleBackClick={() => handleInvoiceClick(null)}
      />
    );
  }

  if (membershipPolicy) {
    return (
      <MembershipPolicySidebar
        membershipPolicy={membershipPolicy}
        handleBackClick={() => handleMembershipPolicyClick(null)}
        settings={props.settings}
        sidebar={false}
      />
    );
  }

  return (
    <CustomerSidebar
      patient_record={props.patient_record}
      appointment={props.appointment}
      invoices={invoices}
      membershipPolicies={membershipPolicies}
      pagination={pagination}
      loading={loading}
      error={error}
      customer={customer}
      setCustomer={setCustomer}
      patientCustomer={patientCustomer}
      setPatientCustomer={setPatientCustomer}
      stripeBaseUrl={stripeBaseUrl}
      handleInvoiceClick={handleInvoiceClick}
      handleMembershipPolicyClick={handleMembershipPolicyClick}
      activeSection={activeSection}
      setActiveSection={handleSectionChange}
    />
  );
}
