import {
  Checkbox,
  DropdownMenuItem,
  IconPlus1,
  IconX,
  ObjectSearch,
  StripeSearchResult,
  Typography,
} from "@herohealthsoftware/ui";
import React, { useEffect, useRef, useState } from "react";

import { translate } from "../../../../lib/i18n";
import { searchStripe } from "../../../../lib/stripe";
import { EmisItem, Product } from "../../../../lib/types";

type EmisItemsInputProps = {
  name: string;
  item: EmisItem;
  onChange: (item: EmisItem) => void;
};

export function EmisItemsInput(props: EmisItemsInputProps) {
  const focusRef = useRef(null);
  const [relevant, setRelevant] = useState([]);

  const selected = !!props.item.selected;

  const actions = [
    {
      key: "create-new-product",
      enabled: true,
      label: translate("partners.stripe.createNewProductForItem"),
      icon: <IconPlus1 />,
      onClick: () => {
        props.onChange({
          ...props.item,
          product: null,
          invoiceItem: {
            description: props.item.product_name,
            quantity: 1,
            unit_amount_in_pounds: "",
            create: true,
            mode: "create",
          },
        });
      },
    },
  ];

  useEffect(() => {
    searchStripe<Product>(props.item.product_name, {
      type: "product",
    }).then((results) => {
      setRelevant(results.data || []);
    });
  }, []);

  useEffect(() => {
    if (selected && !props.item.product && !props.item.invoiceItem) {
      focusRef.current.focus();
    }
  }, [selected, props.item.product, props.item.invoiceItem]);

  return (
    <li>
      <div>
        <label
          className="flex items-center gap-2 font-medium text-hero-blue-700 text-wrap"
          htmlFor={props.name}
        >
          <Checkbox
            id={props.name}
            name={props.name}
            checked={selected}
            onChange={() =>
              props.onChange({ ...props.item, selected: !selected })
            }
          />
          {props.item.product_name}
        </label>

        {selected && (props.item.product || props.item.invoiceItem) && (
          <div
            className="relative flex gap-2 items-center bg-hero-blue-500
              text-xs text-white px-2 py-1 mt-2 rounded-full w-fit max-w-md"
          >
            {props.item.product && (
              <div className="max-w-min truncate pr-4">
                {props.item.product.name}
              </div>
            )}

            {props.item.invoiceItem && (
              <div className="max-w-min truncate pr-4">
                {props.item.invoiceItem.description}
                <span className="text-xs font-bold ml-1 lowercase">
                  {translate("base.new")}
                </span>
              </div>
            )}

            <div
              onClick={() => {
                props.onChange({
                  ...props.item,
                  product: null,
                  invoiceItem: null,
                });
              }}
              className="[&_path]:fill-white h-3 w-3 cursor-pointer absolute right-2"
            >
              <IconX />
            </div>
          </div>
        )}

        {selected && !props.item.product && !props.item.invoiceItem && (
          <ObjectSearch<Product>
            actions={actions}
            focusRef={focusRef}
            messages={{
              placeholder: translate("partners.stripe.findOrAddAnItem"),
              resultsHeading: translate("partners.stripe.searchResults"),
              loading: translate("partners.stripe.loadingStripeProducts"),
              noResults: translate("partners.stripe.noMatchingProductsFound"),
            }}
            // TODO: fix typing [post-monorepo-issue]
            renderOption={(product) => (
              <StripeSearchResult product={product as never} />
            )}
            onSelect={(product) => {
              props.onChange({
                ...props.item,
                product,
                invoiceItem: null,
              });
            }}
            fetcher={async (query) =>
              searchStripe<Product>(query, { type: "product" })
            }
            footer={
              <EmisItemsRelevantProducts
                key="relevant-products"
                products={relevant}
                item={props.item}
                onChange={props.onChange}
              />
            }
            className="my-2"
          />
        )}
      </div>
    </li>
  );
}

type EmisItemsRelevantProductsProps = Pick<
  EmisItemsInputProps,
  "item" | "onChange"
> & { products: Product[] };

function EmisItemsRelevantProducts(props: EmisItemsRelevantProductsProps) {
  return (
    <>
      <hr
        key="relevant-matches-separator"
        className="border-top border-hero-blue-100 my-xs"
      />
      <div className="!px-0 !py-2">
        <Typography>
          <Typography
            key="results-heading"
            size="xs"
            weight="semibold"
            color="secondary"
            className="tracking-wider uppercase px-3"
          >
            <div>{translate("partners.stripe.relevantMatches")}</div>
          </Typography>
        </Typography>

        {props.products.length === 0 && (
          <Typography
            color="light"
            size="sm"
            className="px-3 max-w-[--radix-popover-trigger-width]"
          >
            <div>{translate("partners.stripe.noRelevantMatches")}</div>
          </Typography>
        )}

        {props.products.map((product) => (
          <DropdownMenuItem
            key={product.id}
            onMouseDown={() => {
              props.onChange({
                ...props.item,
                product,
                invoiceItem: null,
              });
            }}
          >
            <StripeSearchResult
              // TODO: fix typing [post-monorepo-issue]
              product={product as never}
            />
          </DropdownMenuItem>
        ))}
      </div>
    </>
  );
}
