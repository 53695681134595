import React, { useState } from "react";
import Toggle from "../../../../atoms/Toggle";
import Hero from "../../../../../lib/hero";
import * as Routes from "../../../../../routes";

const Row = ({ location }: { location: any }) => {
  const [checked, setChecked] = useState(location.visible_on_find_a_private_gp);
  const handleToggle = async () => {
    const value = !checked;
    setChecked(value);

    const response = await Hero.fetch(Routes.admin_location_path(location), {
      method: "PATCH",
      body: {
        location: {
          visible_on_find_a_private_gp: value,
        },
        redirect: "false",
        flash: "false",
      },
    });

    if (!response.ok) {
      setChecked(!value);
    }
  };
  return <Toggle checked={checked} onChange={handleToggle} />;
};

export default Row;
