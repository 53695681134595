import React, { useState, useEffect, useRef } from "react";
import { translate } from "../../lib/i18n";
import TeamDescription from "../shared/DescriptionCard";
import TeamListing from "../shared/ListingCard";
import SaveTeamModal from "../shared/SaveModal";
import RemoveTeamModal from "../shared/RemoveModal";
import { displayFlashMessage, displayErrorMessage} from "../shared/FlashMessages"
import {
  fetchRecords,
  getPageChange,
  createRecord,
  updateRecord,
  removeRecord,
  fetchAssociatedMembersData,
  fetchTaskCountData
} from "../../hooks/useApi";
import { AdminRecord, PracticeGroupItem } from "../../lib/types";
import { ThemeProvider } from "@herohealthsoftware/ui";

interface TeamFormProps {
  admin_records: AdminRecord[];
  practice_group_id: number;
  csrfToken: string;
}

const TeamForm: React.FC<TeamFormProps> = ({
  admin_records,
  practice_group_id,
  csrfToken,
}) => {
  const [currentPage, setCurrentPage] = useState<number | null>(null);
  const [selectedAdmin, setSelectedAdmin] = useState<PracticeGroupItem | null>(null);
  const [selectedRemoveTeam, setSelectedRemoveTeam] =
    useState<PracticeGroupItem | null>(null);
  const [selectedMembers, setSelectedMembers] = useState<AdminRecord[]>([]);
  const [showAddMemberDropdown, setShowAddMemberDropdown] =
    useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [paginatedRecord, setPaginatedRecord] = useState<PracticeGroupItem[]>(null);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [memberError, setMemberError] = useState<string>("");
  const [taskCount, setTaskCount] = useState(null);
  const [membersCounts, setMembersCounts] = useState({});
  const flashMessageRef = useRef<HTMLDivElement | null>(null);
  const teamParentContainer =
    flashMessageRef?.current?.closest(".admin_container");
  const flashMessageContent = isCreateMode
    ? translate("common.createdMessage")
    : translate("common.updatedMessage");
  const [totalPages, setTotalPages] = useState(null);
  const [totalEntries, setTotalEntries] = useState(null);
  const entriesPerPage = 10;

  const handleEditClick = (admin: PracticeGroupItem) => {
    setSelectedAdmin(admin);
    fetchAssociatedMembers(admin.id);
  };

  const handleRemoveRecord = (admin: PracticeGroupItem) => {
    setSelectedRemoveTeam(admin);
    fetchTaskCount(admin.id);
  };

  const handleRemoveAdminModal = () => {
    setSelectedRemoveTeam(null);
  };

  const handleRemoveMember = (memberId: number) => {
    setSelectedMembers((prevMembers) =>
      prevMembers.filter((member) => member.id !== memberId)
    );
  };

  const fetchAssociatedMembers = async (adminId: number) => {
    const url = `/admins/teams/${adminId}/associated_members`;
    try {
      const { error, data } = await fetchAssociatedMembersData(url);

      if (!error) {
        setSelectedMembers(data.associated_members);
      } else {
        return false;
      }
    } catch (error) {
      return error;
    }
  };

  const fetchTaskCount = async (adminId: number) => {
    const url = `/admins/teams/${adminId}/fetch_tasks_count`;
    try {
      const { error, data } = await fetchTaskCountData(url);

      if (!error) {
        setTaskCount(data.tasks_count);
      } else {
        return false;
      }
    } catch (error) {
      return error;
    }
  };

  const handleAddMember = (selectedMember: AdminRecord) => {
    if (selectedMembers.some((member) => member.id === selectedMember.id)) {
      setErrorMessage(translate("adminTeams.teamForm.alreadySelected"));
    } else {
      setSelectedMembers((prevMembers) => [...prevMembers, selectedMember]);
      setErrorMessage("");
      setMemberError("");
    }
  };

  const handleCloseModal = () => {
    setIsCreateMode(false);
    setSelectedAdmin(null);
    setSelectedMembers([]);
    setNameError("");
    setErrorMessage("");
    setMemberError("");
  };

  const filterAdmins = () => {
    return admin_records.filter((admin) =>
      admin.first_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const validateInputs = () => {
    if (!selectedAdmin?.name.trim()) {
      setNameError(translate("adminTeams.teamForm.nameError"));
      return false;
    }

    if (selectedMembers.length === 0) {
      setMemberError(translate("adminTeams.teamForm.memberError"));
      return false;
    }

    return true;
  };

  const handleCreateRecord = async () => {
    const url = '/admins/teams';
    try {
      if (!validateInputs()) {
        return;
      }

      const { error, data } = await createRecord(
        url,
        selectedAdmin,
        practice_group_id,
        csrfToken,
        selectedMembers
      );
      handleCloseModal();

      if (!error) {
        handleRemoveAdminModal();

        if (!data.ok) {
          displayErrorMessage(data.errors.message, teamParentContainer);
          return;
        } else {
          handlePageChange(1);
          displayFlashMessage(
            data.pg_item.name,
            teamParentContainer,
            flashMessageContent,
            selectedRemoveTeam ? true : false
          );
        }
      } else {
        return false;
      }
    } catch (error) {
      return error;
    }
  };

  const handleUpdateRecord = async () => {
    const url = `/admins/teams/${selectedAdmin.id}`;
    try {
      if (!validateInputs()) {
        return;
      }

      const { error, data } = await updateRecord(
        url,
        selectedAdmin,
        practice_group_id,
        csrfToken,
        selectedMembers
      );
      handleCloseModal();

      if (!error) {
        handleRemoveAdminModal();

        if (!data.ok) {
          displayErrorMessage(data.errors.message, teamParentContainer);
          return;
        } else {
          handlePageChange(currentPage);
          displayFlashMessage(
            data.pg_item.name,
            teamParentContainer,
            flashMessageContent,
            selectedRemoveTeam ? true : false
          );
        }
      } else {
        return false;
      }
    } catch (error) {
      return error;
    }
  };

  const handleRemoveTeamRecord = async () => {
    const url = `/admins/teams/${selectedRemoveTeam.id}`;
    try {
      const { error, data } = await removeRecord(
        url,
        csrfToken
      );

      if (!error) {
        handleRemoveAdminModal();

        if (!data.ok) {
          displayErrorMessage(data.errors.message, teamParentContainer);
        } else {
          lastPageRemoval();
          displayFlashMessage(
            selectedRemoveTeam.name,
            teamParentContainer,
            flashMessageContent,
            selectedRemoveTeam ? true : false
          );
        }
      } else {
        return false;
      }
    } catch (error) {
      return error;
    }
  };

  const lastPageRemoval = () => {
    const startIndex = (currentPage - 1) * entriesPerPage + 1;
    const shouldChangePage =
      currentPage === totalPages && startIndex === totalEntries;

    handlePageChange(shouldChangePage ? currentPage - 1 : currentPage);
  };

  const handlePageChange = async (newPage) => {
    const url = '/admins/teams/paginate';
    try {
      const { error, data } = await getPageChange(newPage, url);

      if (!error) {
        setCurrentPage(data.current_page);
        setPaginatedRecord(data.pg_items);
        setTotalPages(data.total_pages);
        setTotalEntries(data.total_entries);
        setMembersCounts(data.members_count);
      } else {
        return false;
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    const url = '/admins/teams/paginate';
    (async () => {
      try {
        const { error, data } = await fetchRecords(url);
        if (!error) {
          if (data.pg_items.length !== 0) {
            setCurrentPage(data.current_page);
            setPaginatedRecord(data.pg_items);
            setTotalPages(data.total_pages);
            setTotalEntries(data.total_entries);
            setMembersCounts(data.members_count);
          }
        } else {
          return false;
        }
      } catch (error) {
        return error;
      }
    })();
  }, []);

  return (
    <ThemeProvider>
      <div ref={flashMessageRef}>
        {currentPage ? (
          <TeamListing
            paginatedRecord={paginatedRecord}
            membersCounts={membersCounts}
            currentPage={currentPage}
            totalPages={totalPages}
            handleEditClick={handleEditClick}
            handleRemoveRecord={handleRemoveRecord}
            handlePageChange={handlePageChange}
            setIsCreateMode={setIsCreateMode}
            setSelectedAdmin={setSelectedAdmin}
            setSelectedMembers={setSelectedMembers}
            entriesPerPage={entriesPerPage}
            totalEntries={totalEntries}
            title={translate('adminTeams.teamForm.teams')}
            btn_title={translate('adminTeams.teamForm.createATeam')}
            membersTitle={translate('adminTeams.teamForm.members')}
          />
        ) : (
          <>
            <TeamDescription
              setIsCreateMode={setIsCreateMode}
              setSelectedAdmin={setSelectedAdmin}
              setSelectedMembers={setSelectedMembers}
              title={translate('adminTeams.teamForm.teams')}
              no_record={translate('adminTeams.teamForm.noTeam')}
              description={translate('adminTeams.teamForm.description')}
              btn_title={translate('adminTeams.teamForm.createATeam')}
            />
          </>
        )}

        {isCreateMode || selectedAdmin ? (
          <>
            <SaveTeamModal
              isCreateMode={isCreateMode}
              selectedAdmin={selectedAdmin}
              selectedMembers={selectedMembers}
              setNameError={setNameError}
              setSelectedAdmin={setSelectedAdmin}
              nameError={nameError}
              errorMessage={errorMessage}
              memberError={memberError}
              handleCloseModal={handleCloseModal}
              handleCreateRecord={handleCreateRecord}
              handleUpdateRecord={handleUpdateRecord}
              handleRemoveMember={handleRemoveMember}
              handleAddMember={handleAddMember}
              showAddMemberDropdown={showAddMemberDropdown}
              setShowAddMemberDropdown={setShowAddMemberDropdown}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              filterAdmins={filterAdmins}
              title={
                isCreateMode
                  ? translate('adminTeams.teamForm.createTeam')
                  : translate('adminTeams.teamForm.editTeam')
              }
              name={translate('adminTeams.teamForm.name')}
              close={translate('common.cancel')}
              save={
                isCreateMode
                  ? translate('adminTeams.teamForm.createTeam') 
                  : translate('adminTeams.teamForm.saveTeam')
              }
              teamMembers={translate('adminTeams.teamForm.teamMembers')}
              addMember={translate('adminTeams.teamForm.addMember')}
            />
          </>
        ) : null}

        {selectedRemoveTeam && (
          <RemoveTeamModal
            onClose={() => handleRemoveAdminModal()}
            onRemove={() => handleRemoveTeamRecord()}
            close={translate('common.cancel')}
            remove={translate('adminTeams.teamForm.removeTeam')}
            taskCount={taskCount}
            descriptionIntro={translate('adminTeams.teamForm.removeDescriptionIntro')}
            descriptionBody={translate('adminTeams.teamForm.removeDescriptionBody')}
          />
        )}
      </div>
    </ThemeProvider>
  );
};

export default TeamForm;
