import { TextInput } from "@herohealthsoftware/ui";
import React from "react";

import { translate } from "../../../../lib/i18n";
import AdditionalOption from "./AdditionalOption";

type DiagnosisInputProps = {
  value: string;
  onChange: (value) => void;
  open: boolean;
  onOpenChange: (e) => void;
  disabled: boolean;
  error: string;
};

export default function DiagnosisInput(props: DiagnosisInputProps) {
  return (
    <div className="mb-6">
      <AdditionalOption
        id="diagnosis"
        name="diagnosis_checkbox"
        value="3"
        label={translate("partners.stripe.addDiagnosis")}
        subtext={translate("partners.stripe.diagnosesMaxCharacters")}
        checked={props.open}
        onChange={(e) => props.onOpenChange(e.target.checked)}
      >
        <div className="ml-6 mb-5">
          <TextInput
            id="partners_stripe_invoice_diagnosis"
            name="partners_stripe_invoice[diagnosis]"
            value={props.value}
            onChange={(event) => props.onChange(event.target.value)}
            className="form-control text optional mt-2"
            maxLength={140}
            error={!!props.error}
            errorText={props.error}
            disabled={props.disabled}
          />
        </div>
      </AdditionalOption>

      {!props.open && (
        <input
          value=""
          type="hidden"
          name="partners_stripe_invoice[diagnosis]"
        />
      )}
    </div>
  );
}
