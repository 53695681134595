import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import {
  Button,
  Badge,
  PatientNameStd,
  IconPencil,
  ButtonGroup,
  IconDotsHorizontal,
  Tooltip,
  IconRefresh,
} from "@herohealthsoftware/ui";
import { DateTime } from "luxon";
import { type AppointmentDto } from "@herohealthsoftware/api-sdk-react";
import * as Routes from "../../../../routes";
import { translate } from "../../../../lib/i18n";
import Hero from "../../../../lib/hero";

const handleEmisResync = async (id: string) => {
  try {
    const response = await Hero.fetch(
      Routes.manual_emis_sync_appointment_path({
        id: id,
      }),
      { method: "POST" }
    );
    if (!response.ok) throw new Error("Failed to resync");
    alert(
      translate(
        "admin.appointments.tables.appointments.actions.resyncWithEmisSuccess"
      )
    );
  } catch (error) {
    alert(
      translate(
        "admin.appointments.tables.appointments.actions.resyncWithEmisError"
      )
    );
  }
};

export const columns: ColumnDef<AppointmentDto & { ehrEnabled: boolean }>[] = [
  {
    accessorKey: "confirmed_at",
    header: translate(
      "admin.appointments.tables.appointments.headers.confirmedAt"
    ),
    enableColumnFilter: false,
    cell: ({ row }) => {
      return (
        <div>{`${DateTime.fromISO(row.getValue("confirmed_at")).toFormat(
          "dd-MMM-yyyy HH:mm"
        )}`}</div>
      );
    },
  },
  {
    accessorKey: "start_time",
    header: translate(
      "admin.appointments.tables.appointments.headers.startTime"
    ),
    enableColumnFilter: false,
    cell: ({ row }) => {
      return (
        <div>{`${DateTime.fromISO(row.getValue("start_time")).toFormat(
          "dd-MMM-yyyy HH:mm"
        )}`}</div>
      );
    },
  },
  {
    accessorKey: "patient",
    header: translate("admin.appointments.tables.appointments.headers.patient"),
    enableSorting: false,
    cell: ({ row }) => {
      const patient = row.getValue("patient") as AppointmentDto["patient"];
      return (
        <PatientNameStd
          title={patient.title}
          firstName={patient.first_name}
          lastName={patient.last_name}
        />
      );
    },
  },
  {
    accessorKey: "practitioner",
    header: translate(
      "admin.appointments.tables.appointments.headers.practitioner"
    ),
    enableSorting: false,
    cell: ({ row }) => {
      const practitioner = row.getValue(
        "practitioner"
      ) as AppointmentDto["practitioner"];
      return (
        <PatientNameStd
          title={practitioner.title}
          firstName={practitioner.first_name}
          lastName={practitioner.last_name}
        />
      );
    },
  },
  {
    accessorKey: "appointment_template_name",
    header: translate("admin.appointments.tables.appointments.headers.service"),
    enableSorting: false,
  },
  {
    accessorKey: "location_name",
    header: translate(
      "admin.appointments.tables.appointments.headers.location"
    ),
    enableSorting: false,
  },
  {
    accessorKey: "appointment_source",
    header: translate("admin.appointments.tables.appointments.headers.source"),
    enableSorting: false,
    enableColumnFilter: false,
    cell: ({ row }) => {
      switch (row.getValue("appointment_source")) {
        case "EMIS":
          return <>{translate("common.emis")}</>;
        case "Hero admin led":
          return <>{translate("common.admin")}</>;
        default:
          return <>{translate("common.online")}</>;
      }
    },
  },
  {
    accessorKey: "cancelled_at",
    header: translate("admin.appointments.tables.appointments.headers.status"),
    enableSorting: false,
    enableColumnFilter: false,
    cell: ({ row }) => {
      return (
        <Badge
          colour={row.getValue("cancelled_at") === null ? "neutral" : "red"}
          size="sm"
        >
          {row.getValue("cancelled_at")
            ? translate("base.cancelled")
            : translate("base.confirmed")}
        </Badge>
      );
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      return (
        <ButtonGroup className="!shadow-none rounded-none group-hover/row:visible relative">
          {row.original.ehrEnabled ? (
            <Tooltip
              content={translate(
                "admin.appointments.tables.appointments.actions.resyncWithEmis"
              )}
            >
              <Button
                icon
                size="xs"
                variant="white"
                onClick={async (e) => {
                  e.stopPropagation();
                  handleEmisResync(row.original.id);
                }}
                className="-herolib-invisible group-hover/row:-herolib-visible -herolib-absolute -herolib-right-full"
              >
                <div className="group-hover/row:!hidden">
                  <IconDotsHorizontal />
                </div>
                <div className="hidden group-hover/row:!flex">
                  <IconRefresh />
                </div>
              </Button>
            </Tooltip>
          ) : null}
          <Tooltip
            content={translate(
              "admin.appointments.tables.appointments.actions.edit"
            )}
          >
            <Button
              icon
              size="xs"
              variant="white"
              onClick={(e) => {
                e.stopPropagation();
                window.location.href = Routes.admin_edit_appointment_path({
                  id: row.original.id,
                });
              }}
              className="!border-transparent group-hover/row:!border-hero-blue-300 !transition-none hover:!border-hero-blue-300"
            >
              <div className="group-hover/row:!hidden">
                <IconDotsHorizontal />
              </div>
              <div className="hidden group-hover/row:!flex">
                <IconPencil />
              </div>
            </Button>
          </Tooltip>
        </ButtonGroup>
      );
    },
  },
];
