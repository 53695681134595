import React from "react";
import HeroCareNavigation from "@herohealthsoftware/care-nav-react";

const SubmissionPage = ({ env, jwtToken, practiceGroupId, patientId }) => {
  return (
    <HeroCareNavigation
      env={env}
      jwtToken={jwtToken}
      tenantId={practiceGroupId}
      patientId={patientId}
    />
  );
};

export default SubmissionPage;
