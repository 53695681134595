import { IconChevronRight } from "@herohealthsoftware/ui";
import { cx } from "class-variance-authority";
import React from "react";

export type MenuItem = {
  name: string;
  label: string;
  path?: string;
};

export type MenuSection = {
  heading?: string;
  sections: MenuItem[];
};

export type MenuOptions = MenuSection[];

type MenuProps = {
  options: MenuOptions;
  selected: string;
  onClick?: (name: string) => void;
  className?: string;
};

type MenuItemProps = MenuItem & {
  onClick?: (name: string) => void;
  selected: boolean;
};

function MenuItem(props: MenuItemProps) {
  const className = cx(
    "px-3 py-1.5 mb-1 last-of-type:mb-0 cursor-pointer",
    props.selected &&
      "flex items-center justify-between bg-hero-blue-700/5 text-hero-blue-700 rounded"
  );
  const handleClick =
    props.onClick && (() => !props.selected && props.onClick(props.name));

  return (
    <li onClick={handleClick} className={className}>
      {props.path && !props.onClick ? (
        <a href={props.path} className="block grow">
          {props.label}
        </a>
      ) : (
        <span>{props.label}</span>
      )}

      {props.selected && (
        <div className="h-4 w-4">
          <IconChevronRight />
        </div>
      )}
    </li>
  );
}

export default function Menu(props: MenuProps) {
  return (
    <div className={cx("bg-hero-blue-50 rounded p-1", props.className)}>
      {props.options.map((section, index) => (
        <React.Fragment key={index}>
          {section.heading && (
            <h3 className="px-3 py-1.5 m-0 text-xs text-hero-blue-700 font-semibold uppercase">
              {section.heading}
            </h3>
          )}

          <ul className={`${index > 0 && "mt-2"} text-sm text-hero-blue-400 leading-4 font-semibold`}>
            {section.sections.map((item) => (
              <MenuItem
                key={item.name}
                {...item}
                selected={props.selected === item.name}
                onClick={props.onClick}
              />
            ))}
          </ul>
        </React.Fragment>
      ))}
    </div>
  );
}
