import React, { useState } from "react";

import {
  Badge,
  ThemeProvider,
  InvoiceItems,
  InvoiceItem,
} from "@herohealthsoftware/ui";
import { translate } from "../../../../lib/i18n";
import { searchStripe } from "../../../../lib/stripe";
import Toggle from "../../../atoms/Toggle";
import IconStripe from "../IconStripe";
import ItemsFields from "../form/ItemsFields";

type AppointmentItemSettingsProps = {
  items: InvoiceItem[];
  admin_booking_invoice_required: boolean;
};

export default function AppointmentItemSettings(
  props: AppointmentItemSettingsProps
) {
  const [items, setItems] = useState(props.items || []);
  const [adminBookingInvoiceRequired, setAdminBookingInvoiceRequired] =
    useState(props.admin_booking_invoice_required);

  const inputNamePrefix =
    "practice_group_appointment_template[appointment_items_attributes]";

  return (
    <ThemeProvider>
      <div className="mb-8">
        <div className="flex flex-col gap-2 mb-4">
          <div className="h-8 w-8">
            <IconStripe />
          </div>

          <h1 className="text-xl leading-7 font-bold mb-0">Stripe</h1>

          <p className="text-base leading-6 font-normal text-hero-blue-500">
            {translate("partners.stripe.appointmentItemSettingsHelp")}
          </p>
        </div>

        <div>
          {items.length === 0 ? (
            <div className="flex gap-2 items-center mb-1">
              <h5 className="text-sm leading-5 font-semibold mb-0">
                {translate("partners.stripe.addItems")}
              </h5>

              <Badge colour="neutral" size="sm" variant="custom">
                {translate("base.optional")}
              </Badge>
            </div>
          ) : (
            <h5 className="text-lg leading-7 font-bold mb-4">
              {translate("partners.stripe.associatedProducts")}
            </h5>
          )}

          <InvoiceItems
            items={items}
            onChange={({ items }) => setItems(items)}
            config={{
              resource: "appointmentType",
              editable: true,
              oneTimeItems: false,
              emisItems: false,
              coupons: false,
              totals: false,
              fetcher: async (search) =>
                searchStripe(search, { type: "product" }),
            }}
          />

          <ItemsFields
            items={items}
            namePrefix={{
              items: inputNamePrefix,
            }}
          />

          {items.length > 0 && (
            <div className="mt-4">
              <Toggle
                onChange={() =>
                  setAdminBookingInvoiceRequired(!adminBookingInvoiceRequired)
                }
                checked={adminBookingInvoiceRequired}
                label={translate("partners.stripe.adminBookingInvoiceRequired")}
              />
            </div>
          )}

          {items.map((_, index) => (
            <>
              <input
                type="hidden"
                name={`${inputNamePrefix}[${index}][patient_booking_payment_required]`}
                value="true"
              />

              <input
                type="hidden"
                name={`${inputNamePrefix}[${index}][admin_booking_invoice_required]`}
                value={adminBookingInvoiceRequired ? "true" : "false"}
              />
            </>
          ))}
        </div>
      </div>
    </ThemeProvider>
  );
}
