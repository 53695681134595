/* eslint no-unused-vars: 0 */

export function translate(keys, options = {}) {
  var translation;

  try {
    translation = keys
      .split(".")
      .reduce((hash, key) => hash[key], translations());

    if (typeof translation === "undefined") {
      console.log("ERROR: translation key not found for " + keys);
    }
  } catch (e) {
    console.log('ERROR: for translation key "' + keys + '" - ' + e);
    throw e;
  }

  if (Object.keys(options).length > 0) {
    translation = translation.replace(/#\{([^}]+)\}/g, function (match, key) {
      return options[key];
    });
  }

  return translation;
}

function translations() {
  return {
    base: {
      back: "Back",
      born: "Born",
      cancel: "Cancel",
      cancelled: "Cancelled",
      category: "Category",
      change: "Change",
      close: "Close",
      copied: "Copied",
      confirmed: "Confirmed",
      continue: "Continue",
      created: "Created",
      dateOfBirth: "Date of birth",
      default: "Default",
      delete: "Delete",
      delete_invoice: "Delete invoice",
      dob: "DOB",
      edit: "Edit",
      email: "Email",
      emailAddress: "Email address",
      for: "for",
      fullName: "Full name",
      issued: "Issued",
      key: "Key",
      loading: "Loading",
      location: "Location",
      mobile: "Mobile",
      name: "Name",
      new: "New",
      next: "Next",
      no: "No",
      optional: "Optional",
      patient: "Patient",
      partner: {
        emis: "EMIS",
        nhs: "NHS",
        hero: "Hero",
        gha: "GHA",
        systm_one: "SystmOne",
      },
      phone: "Phone",
      practitionerAtLocation: "#{practitioner} at #{location}",
      previous: "Previous",
      refresh: "Refresh",
      remove: "Remove",
      reserved: "Reserved",
      save: "Save",
      search: "Search",
      selectADate: "Select a date",
      sent: "Sent",
      sex: "Sex",
      to: "to",
      testingOnly: "Testing only",
      unexpectedError: "Unexpected error",
      unknown: "Unknown",
      with: "with",
      summary: "Summary",
      metaData: "Metadata",
      yes: "Yes",
    },
    admin: {
      appointments: {
        tables: {
          appointments: {
            headers: {
              confirmedAt: "CONFIRMED AT",
              startTime: "START TIME",
              patient: "PATIENT",
              practitioner: "PRACTITIONER",
              service: "SERVICE",
              location: "LOCATION",
              source: "SOURCE",
              status: "STATUS",
              actions: "ACTIONS",
            },
            actions: {
              edit: "Edit",
              joinAVideoCall: "Join a video call",
              resyncWithEmis: "Resync with EMIS",
              resyncWithEmisError:
                "There has been an error whilst syncing. Please try again and if the issue persists, contact support.",
              resyncWithEmisSuccess: "Sync was successful!",
            },
          },
        },
      },
      communications: {
        attachments: "Attachments",
        contactMethod: "Contact method",
        createCampaignHeading: "Create campaign",
        createCampaignSubheading: "Create new campaign",
        createdAt: "Created at",
        createdBy: "Created by",
        savedToRecord: "Saved to record",
        sendMessageHeading: "Send message",
        sendMessageSubheading: "Send new message",
        sentMessage: "Sent message",
        sentTo: "Sent to",
        status: "Status",
        createFormLink: "Create form link",
      },
      patientSearch: {
        couldNotConnectToEmis: "We could not connect to EMIS.",
        couldNotFindPatientFromEmis:
          "We could not find a patient matching the details you entered. Check the patient is registered in your EMIS.",
        couldNotFindPatientInHero:
          "We could not find a patient matching the details you entered. Check the patient is registered in Hero.",
        errorFetchingPatientsFromHeroPublicApi:
          "There has been an error whilst searching for your patient. Please try again. If the problem persists, contact support.",
        invalidDateFormat:
          "Invalid date format. Please enter a date in this format: 23-04-1998",
        searchPatientsInHero: "Search patients in Hero",
        unableToCreatePatientInHero:
          "We were unable to create a patient in Hero with the information from EMIS.",
      },
    },
    partners: {
      findAPrivateGp: {
        description:
          "Find A Private GP connects patients with local private general practitioners. We are the #1 website for finding private GPs near you. You can search, select and book with GPs in your area, and get seen by an expert clinician today. No waiting, no faff. Find a clinician you click with.",
        displayMyPracticeOnFindAPrivateGp: "Display my practice on Find A Private GP",
        findAPrivateGp: "Find A private GP",
        goToYourFindAPrivateGpShowPage:
          "Go to your Find A private GP show page",
        location: "Location",
        locations: "Locations",
        overview: "Overview",
        practitoner: "Practitioner",
        practitioners: "Practitioners",
        privateCareSearchEngine: "Private care search engine",
        services: "Services",
        showOnFindAPrivateGp: "Show on Find A Private GP",
      },
      hca: {
        requestConsultation: "Request consultation",
      },
      signatureRx: {
        createPrescription: "Create prescription",
        createPrescriptionDisabled:
          "Patient must have an EMIS ID to create a SignatureRx prescription.",
        goToDashboard: "Go to SignatureRx dashboard",
      },
      stripe: {
        addAPaymentMethod: "Add new card",
        addCustomerEmail: "Add customer email",
        addEmail: "Add email",
        addAnotherCoupon: "Add another coupon",
        addCoupon: "Add coupon",
        addDiagnosis: "Add diagnosis",
        addOneTimeItem: "Add one-time item",
        addPatient: "Add patient",
        addProductFromEmis: "Add product from EMIS",
        addInsuranceDetails: "Add insurance details",
        addItem: "Add item",
        addItems: "Add items",
        addItemsFromEmis: "Add items from EMIS",
        addMemo: "Add memo",
        addMemoMaxCharacters: "Maximum character count is 500 characters.",
        additionalOptions: "Additional options",
        additionalOptionsWarning:
          "Once the Stripe invoice is finalised, updates to these fields will only be reflected in the Hero insurance invoice.",
        addAndAddAnotherProduct: {
          invoice: "Add to invoice and add another",
          appointmentType: "Save and add another",
        },
        addProduct: {
          invoice: "Add to invoice",
          appointmentType: "Save",
        },
        adminBookingInvoiceRequired:
          "Create a Stripe invoice for bookings made by administrators",
        amountDue: "Amount due",
        alertInfo:
          "These fields are disabled as this invoice was raised as part of an appointment: #{description}",
        appointmentItemSettingsHelp:
          "Require payment when a customer makes an online booking by adding associated Stripe products.",
        appointment: "Appointment",
        associatedProducts: "Associated products",
        authorisationCode: "Authorisation code",
        billingAndCollection: "Billing & collection",
        billedMethod: "Collection method",
        billedTo: "Billed to",
        billpayer: "Billpayer",
        billing: "Billing",
        billingDetails: "Stripe customer",
        billingInformation: "Billing information",
        billingNote: "Billing note",
        billingNoteDetails:
          "Display important information about this patient to administration when creating invoices.",
        cardReaderPayment: "Card reader payment",
        cardReaderPaymentHelp: "Take a payment using your Stripe card reader.",
        cancelInvoice: "Cancel invoice",
        cancelTransaction: "Cancel transaction",
        chargeCustomer: "Charge customer",
        chargeCustomerHelp:
          "Take payment for this invoice now using a saved method on the customer's account.",
        code: "Code",
        confidentialInvoice: "Hide patient information",
        confidentialOnInvoice: "Hide patient information on invoices",
        confidentialDetailsOnInvoice:
          "Always hide this patient's identifiable information on Stripe invoices.",
        copyPaymentLink: "Copy payment link",
        createInvoice: "Create Stripe invoice",
        createCustomer: "Create customer",
        createMembershipScheme: "Create scheme",
        createNewCustomer: "Create new Stripe customer",
        createNewProduct: "Create new Stripe product",
        createNewProductForItem: "Create new product for item",
        createStripeCustomer: "Create Stripe customer",
        creditBalance: "Credit balance",
        creditNote: "Credit note",
        creditNotesAmount: "Credit notes",
        createTheInvoice: "Create invoice",
        currentBillingPeriod: "Current billing period",
        customer: "Customer",
        customerMissingEmail: "Stripe customer is missing an email address",
        customerMissingEmailHelp:
          "You cannot request a payment if the Stripe customer does not have an email.",
        customerNotFound: "Stripe customer no longer available",
        customerSettingsLoading: "Loading customer settings",
        defaultCustomer: "Default Stripe customer",
        defaultCustomerSubtext:
          "When billing this patient, you can set a default Stripe customer to receive the invoice.",
        diagnosis: "Diagnosis",
        diagnosisError: "Enter a diagnosis.",
        diagnosesMaxCharacters: "Maximum character count is 140 characters.",
        downloadInsuranceInvoice: "Download insurance invoice",
        dueDate: "Due date",
        editInvoice: "Edit invoice",
        ending: "••••",
        external: "External",
        externalPayment: "External payment",
        externalPaymentHelp:
          "Mark this invoice as paid via an external method (e.g Cash, Bank transfer, insurance).",
        externalPaymentSource: {
          cash: "Cash",
          cheque: "Cheque",
          bank_transfer: "Bank transfer",
          insurance: "Insurance",
          terminal: "Card reader",
          other: "Other",
        },
        finalizeAndSendInvoice: "Finalize and send invoice",
        findOrAddAnItem: "Find or add an item",
        findOrAddAProduct: "Find or add a product",
        finalizeInvoice: "Finalize invoice",
        footer: "Footer",
        goToCustomerInStripeDashboard: "Go to customer in Stripe dashboard",
        goToInvoiceOnStripe: "Go to invoice on Stripe",
        goToStripe: "Go to Stripe",
        goToStripeDashboard: "Go to Stripe dashboard",
        goToSubscriptionInStripeDashboard: "Go to subscription in Stripe",
        invalidInterval: "Product has an invalid billing interval.",
        invoices: "Invoices",
        item: "Item",
        items: "Items",
        itemDetails: "Item details",
        insuranceDetailsError: "Enter one or more insurance details.",
        insurer: "Insurer",
        invoiceInformation: "Invoice information",
        invoiceWorkflow: "Invoice workflow",
        invoiceWorkflowDetails:
          "Preset repeatable tasks when you raise an invoice for this patient. You can override these per invoice.",
        issueRefund: "Issue refund",
        legacyCreated: "Created (Hero legacy)",
        legacyInvoicing: "Legacy invoicing",
        legacyInvoicingToggle: "Display legacy invoicing",
        linkToAppointment: "Link to appointment",
        listPrice: "List price",
        linkToAService: "Link to a service",
        linkInvoice:
          "Link this invoice to a practitioner and set a service date.",
        loadingEmisItems: "Loading recently issued items from EMIS",
        loadingLegacyInvoices: "Loading legacy Hero invoices",
        loadingStripeCustomers: "Loading Stripe customers",
        loadingStripeInvoices: "Loading Stripe invoices",
        loadingStripeMembers: "Loading policy members",
        loadingStripeMembershipPolicy: "Loading membership policy",
        loadingStripeMemberships: "Loading memberships",
        loadingStripeProducts: "Loading Stripe products",
        memo: "Memo",
        members: "Members",
        membershipNumber: "Membership number",
        memberships: "Memberships",
        memoError: "Enter a memo.",
        metadata: "Metadata",
        metadataSettings: {
          invoices: {
            heading: "Invoice metadata",
            rows: {
              "Patient name": "Patient name",
              "Patient gender": "Patient gender",
              "Patient registered practitioner":
                "Patient registered practitioner",
              "Patient mobile": "Patient mobile",
              "Patient email": "Patient email",
              "Patient postcode": "Patient postcode",
              "Invoice billing and collection":
                "Invoice billing and collection",
              "Appointment location": "Appointment location",
              "Appointment practitioner": "Appointment practitioner",
            },
          },
          customers: {
            heading: "Customer metadata",
            rows: {
              First: "First",
              Last: "Last",
              Name: "Name",
              DOB: "DOB",
              Mobile: "Mobile",
              Email: "Email",
              Postcode: "Postcode",
              Gender: "Gender",
              "Registered practitioner": "Registered practitioner",
            },
          },
        },
        newProduct: "New product",
        newScheme: "New scheme",
        newSchemeHelp:
          "Link a Stripe product with a recurring payment to your membership. A membership policy will be created when a Customer purchases the product. Add patients to a policy to offer appointment and service benefits.",
        nextInvoice: "Next invoice",
        noCardReaders: "No card readers available",
        noCardReadersHelp:
          "Set up your readers in Stripe in order to take card reader payments",
        noDescription: "No  description",
        noEmailAddress: "No email address",
        noEmisItemsFound: "No recently issued items from EMIS found",
        noPaymentMethods: "No payment methods available for this customer",
        noRecentAppointments: "No recent appointments",
        noRelevantMatches:
          "We couldn’t find a match for this item. Try adding a new one using the 'Create new product for item' option.",
        noMatchingCustomersFound: "No matching Stripe customers found",
        noMatchingProductsFound: "No matching Stripe products found",
        noMembers: "There are no members on this policy",
        noMembersHelp: "Add patients to make them members of this policy.",
        noMembershipScheme: "No membership scheme",
        noStripeCustomer: "No Stripe customer",
        noLegacyInvoices: "No legacy Hero invoices found",
        paginateNext: "Next",
        paginatePrevious: "Previous",
        patientInformationWillNotBeShown:
          "Patient information will not be shown on the invoice.",
        diagnosisDetailsOnInvoice:
          "Mark this patient's invoices as requiring a diagnosis by default.",
        patientInformation: "Patient information",
        patientIsDefaultCustomer: "Patient is default Stripe customer",
        payInvoice: "Pay invoice",
        patientBillingInformation: "Patient billing information",
        patientBillingInformationDescription:
          "Set billing preferences for invoices raised for this patient. You can override these per invoice.",
        patientsLinkedToThisPolicy: "Patients linked to this subscription",
        payment: "Payment",
        paymentCollection: "Payment collection",
        paymentDate: "Payment date",
        paymentError: "There was a problem taking payment",
        paymentMethod: "Payment method",
        paymentProcessingCardReader: "Process payment via the card reader",
        paymentProcessingCardReaderHelp:
          "Refer to ‘%label%’ card reader to process your payment with the customer.",
        payNow: "Pay now",
        payNowHelpInvoice:
          "Take a payment using a Stripe card reader, saved card on file or an external method.",
        payNowHelpPayment:
          "Take payment for this invoice now using a method on file",
        policies: "Policies",
        prices: "Prices",
        priceRequired: "Product must have a default price.",
        product: "Product",
        products: "Products",
        productRequired: "Product is required.",
        proceedToPayment: "Proceed to payment",
        practitioner: "Practitioner",
        qty: "Qty",
        referenceNote: "Reference note",
        referenceNoteHelp: "e.g. paid via insurance",
        refreshCustomers: "Refresh to view recently created Stripe customers",
        refund: "Refund",
        refundAmount: "Refund",
        registeredPractitioner: "Registered practitioner",
        relevantMatches: "Relevant matches",
        reloadPaymentMethods: "Refresh cards",
        renewal: "Renewal",
        requestPayment: "Request payment",
        requestPaymentHelp:
          "Create an invoice requesting payment on a specific date.",
        requireDiagnosisOnInvoice: "Require diagnosis",
        saveDraft: "Save draft",
        schemes: "Schemes",
        searchCustomers: "Search Stripe customers",
        searchProducts: "Search Stripe products",
        searchResults: "Search results",
        selectACoupon: "Select a coupon...",
        selectALocation: "Select a location",
        selectAnAppointment: "Select an appointment",
        selectAndMatchEmisWithStripe:
          "Select from recent EMIS events and match with a product in Stripe.",
        selectADefaultCustomer: "Select a default Stripe customer",
        selectAPaymentMethod: "Select a payment method",
        setDefaultCustomer: "Set as default Stripe customer for this patient",
        settings: "Settings",
        settingsSaved: "Settings saved",
        settingsError: "Details failed to save",
        serviceDate: "Service date",
        serviceInformation: "Service information",
        simulatedCardNumber: "Test card number",
        simulatedCardNumberHelp: "Stripe test card documention →",
        started: "Started",
        smartInvoicing: "Smart invoicing",
        smartInvoiceMatching: "Smart invoice matching",
        smartInvoicingEnabled:
          "Smart invoice matching is enabled. We will use these values to attempt to match this invoice to a booking.",
        smartInvoicingInformation:
          "We will use these values to attempt to match this invoice to a booking.",
        smartInvoicingToggle: "Use smart invoice matching",
        stripe: "Stripe",
        stripeProduct: "Stripe product",
        stripeProducts: "Stripe products",
        subtotal: "Subtotal",
        tables: {
          invoice: {
            headers: {
              created: "CREATED",
              invoiceNumber: "INVOICE NO.",
              status: "STATUS",
              amount: "AMOUNT",
              customer: "CUSTOMER",
              patient: "PATIENT",
            },
            searchPlaceholder: "Search Stripe invoices",
            loadingMessage: "Loading Stripe invoices",
            noResultsMessage: "No Stripe invoices found",
          },
          product: {
            headers: {
              name: "NAME",
              created: "CREATED",
              updated: "UPDATED",
            },
            searchPlaceholder: "Search Stripe products",
            loadingMessage: "Loading Stripe products",
            noResultsMessage: "No Stripe products found",
            createButtonText: "Add product",
          },
          members: {
            headers: {
              patient: "Patient",
              scheme: "Policy",
              status: "Status",
              created: "Created",
            },
            loadingMessage: "Loading members",
            noResultsMessage: "No members found",
          },
          membershipSchemes: {
            headers: {
              membership: "Membership",
              product: "Stripe product",
              policies: "Active policies",
              price: "Price",
              created: "Created",
            },
            loadingMessage: "Loading membership schemes",
            noResultsMessage: "No membership schemes found",
          },
          membershipPolicies: {
            headers: {
              customer: "Customer",
              status: "Status",
              members: "Members",
              scheme: "Scheme",
              created: "Created",
            },
            loadingMessage: "Loading membership policies",
            noResultsMessage: "No membership policies found",
          },
        },
        takePayment: "Take payment",
        thisAppointmentHasNoInvoices: "This appointment has no invoices",
        thisAppointmentHasNoInvoicesDescription:
          "Create invoices to charge your customers for appointments. Add one or many items and optionally include discount codes.",
        thisInvoiceHasNoItems: "This invoice has no items",
        thisPatientHasNoInvoices: "This patient has no invoices",
        thisPatientHasNoMemberships: "This patient has no memberships",
        total: "Total",
        unlink: "Unlink",
        updateCustomer: "Update customer",
        updateItem: "Update item",
        updateInvoice: "Update invoice",
        usePatientDefaultStripeCustomer:
          "Use the patient's default Stripe customer",
      },
    },
    careNavigation: {
      pathway: {
        accessSuspended: "Access suspended",
        addOption: "Add option",
        inOrOutOfHoursError:
          "Either in hours, or out of hours must be selected",
        cancel: "Cancel",
        createForm: "+ Create form",
        description: "Description",
        eConsult: "eConsult",
        externalApps: "External apps",
        formPlaceholder: "Search for a form",
        formRequiredError: "A form is required",
        inHours: "In hours",
        loadingForms: "Loading forms",
        name: "Name",
        patientAccess: "Patient access",
        patientAccessSubheading: "Set when patients can make this request",
        pathway_title: "Enter a title for your pathway",
        optional: "Optional",
        options: "Options",
        outOfHours: "Out of hours",
        options_to_save: "Create one or more options to save the pathway",
        request: "Request",
        requests: "Requests",
        requestsSubheading:
          "Direct a patient to make a submission using a form, URL or link to an external app",
        saveAndAddAnother: "Save and add another",
        saveToPathway: "Save to pathway",
        summaryPlaceholder: "Enter a summary",
        suspendAccess: "Suspend access",
        suspendAccessToPathway: "Suspend access to this pathway",
        summary: "Summary",
        title: "Title",
        enter_title: "Enter a title",
        url: "URL",
        urlWebsiteRequiredError: "A URL/Website is required",
        urlWebsiteInvalidError: "A valid URL/Website is required",
        urlWebsiteNamePlaceholder: "Enter a name for the URL/Website",
        urlWebsitePlaceholder: "Enter a URL/Website, https://...",
        urlWebsite: "URL/Website",
        patientEligibilityCheck:
          "Only accept submissions from registered patients",
        patientEligibilityCheckSubtext:
          "Turning this on will prevent submissions from patients not registered on PDS with your practice's ODS code.",
      },
      inHoursSchedule: {
        emptyFieldsError: "Fields cannot be blank.",
        closingTimeEarlierThanOpeningTimeError:
          "Closing time must be after opening time.",
        emptyScheduleError:
          "Provide at least 1 day for your in hours schedule.",
      },
    },
    adminTeams: {
      teamForm: {
        teams: "Teams",
        createTeam: "Create team",
        createATeam: "Create a team",
        editTeam: "Edit team",
        name: "Name",
        teamMembers: "Team members",
        addMember: "Add member",
        saveTeam: "Save team",
        members: "members",
        showing: "Showing",
        removeTeam: "Remove team",
        removeDescriptionIntro: "By removing this team, ",
        removeDescriptionBody:
          " notifications will automatically become unassigned.",
        remove: "Remove",
        noTeam: "You have no teams",
        description:
          "Create teams to group your administrators and give shared ownership when assigning notifications.",
        alreadySelected: "Member is already selected.",
        nameError: "Enter a name for your team",
        memberError: "Add one or more members to save this team",
        setMemberError: "Add one or more members to save this team",
      },
    },
    outcomes: {
      outcomeForm: {
        createAnOutcome: "Create an outcome",
        createOutcome: "Create outcome",
        description:
          "Create outcomes to track and record resolved patient requests. Specify the method of consultation",
        descriptionExample: "(e.g telephone or video consultation)",
        editOutcome: "Edit outcome",
        name: "Name",
        nameError: "Enter a name for your outcome",
        noOutcome: "You have no outcomes",
        outcome: "Outcomes",
        removeDescriptionIntro:
          "By removing this outcome you will no longer be able to close requests with this option.",
        removeOutcome: "Remove outcome",
        saveOutcome: "Save outcome",
      },
    },
    common: {
      addPatient: "Add patient",
      admin: "Admin",
      cancel: "Cancel",
      createdMessage: "was created",
      emis: "EMIS",
      emisId: "EMIS ID",
      online: "Online",
      updatedMessage: "has been updated",
      removeMessage: "has been removed",
      previous: "Previous",
      next: "Next",
      nhsNumber: "NHS Number",
    },
  };
}
