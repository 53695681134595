import React, { useEffect, useState, PropsWithChildren } from "react";
import { IconX } from "@herohealthsoftware/ui";

type SidebarProps = PropsWithChildren<{
  setShowSidebar: (showSidebar: boolean) => void;
  header: React.ReactNode;
}>;

const Sidebar = (props: SidebarProps) => {
  const [open, setOpen] = useState(false);
  const [stopOpen, setStopOpen] = useState(false);

  useEffect(() => {
    if (!stopOpen) {
      setTimeout(() => {
        setOpen(true);
      }, 10);
    } else {
      setTimeout(() => {
        props.setShowSidebar(false);
      }, 500);
    }
  });

  const handleClose = () => {
    setStopOpen(true);
    setOpen(false);
  };

  return (
    <>
      <div
        onClick={handleClose}
        className={`fixed top-0 left-0 transition-all duration-500 ease-in-out h-screen w-screen ${
          open ? "bg-black/40" : "bg-trabsparent"
        } z-50`}
      ></div>
      <div
        className={`fixed top-0 ${
          open ? "right-0" : "-right-[600px]"
        } w-[600px] shadow-2xl transition-all duration-200 ease-in-out bg-white h-screen z-[60]`}
      >
        <div className="flex gap-2 px-6 py-2">
          {props.header && props.header}
          <span
            onClick={handleClose}
            className="ml-auto p-1.5 hover:bg-hero-blue-900/5 w-8 h-8 rounded-md hover:cursor-pointer"
          >
            <IconX />
          </span>
        </div>
        {props.children}
      </div>
    </>
  );
};

export default Sidebar;
