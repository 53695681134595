import React, { useEffect, useState } from "react";
import Hero from "../../../../../lib/hero";
import * as Routes from "../../../../../routes";
import { Select as HeroLibSelect } from "@herohealthsoftware/ui";

const Select = ({
  service,
  options,
  selectedAppointment,
  onChange,
}: {
  service: string;
  options: any;
  selectedAppointment?: any;
  onChange: ({ prevOption, newOption, service }) => void;
}) => {
  const formattedOptions = selectedAppointment
    ? [
        {
          label: "Unselect",
          value: "undefined",
        },
      ].concat(
        [
          {
            label: selectedAppointment.description,
            value: selectedAppointment,
          },
        ].concat(options)
      )
    : options;

  const handleChange = async (option) => {
    if (selectedAppointment) {
      await Hero.fetch(
        Routes.admin_practice_group_appointment_template_path(
          selectedAppointment
        ),
        {
          method: "PATCH",
          body: {
            practice_group_appointment_template: {
              find_a_private_gp_service: "undefined",
            },
            redirect: "false",
            flash: "false",
          },
        }
      );
    }

    if (option !== "undefined") {
      await Hero.fetch(
        Routes.admin_practice_group_appointment_template_path(option),
        {
          method: "PATCH",
          body: {
            practice_group_appointment_template: {
              find_a_private_gp_service: service,
            },
            redirect: "false",
            flash: "false",
          },
        }
      );
    }

    onChange({
      prevOption: selectedAppointment,
      newOption: option,
      service: service,
    });
  };

  return (
    <HeroLibSelect
      key={selectedAppointment}
      options={formattedOptions}
      value={selectedAppointment || null}
      onChange={handleChange}
      isSearchable
    />
  );
};

export default Select;
