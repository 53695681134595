import React from "react";
import PatientSearchElement from "@herohealthsoftware/patient-search-react";
import Hero from "../../lib/hero";
import * as Routes from "../../routes";

const PatientSearch = ({ env, jwtToken, practiceGroupId, ehr, ehrEnabled }) => {
  const handleOnAdd = (dataSourceName: string) => {
    if (dataSourceName === "hero") {
      ehrEnabled
        ? Hero.remote({
            url: Routes.create_patient_sidebar_path({
              format: "js",
            }),
            type: "GET",
          })
        : Hero.remote({
            url: Routes.existing_patient_search_admin_patients_path({
              format: "js",
            }),
            type: "GET",
          });
    }
  };

  return (
    <PatientSearchElement
      env={env}
      tenantId={practiceGroupId}
      jwtToken={jwtToken}
      defaultDataSource={
        localStorage.getItem("patientSearchDatasource")
          ? (localStorage.getItem("patientSearchDatasource") as "ehr" | "hero")
          : ehrEnabled
          ? "ehr"
          : "hero"
      }
      ehr={ehrEnabled ? ehr : undefined}
      onSelect={({ option }) =>
        (window.location.href = Routes.patient_dashboard_path({
          id: option.value.hero_id,
        }))
      }
      onAdd={handleOnAdd}
      onDataSourceChange={(dataSource) => {
        localStorage.setItem("patientSearchDatasource", dataSource.name);
      }}
    />
  );
};

export default PatientSearch;
