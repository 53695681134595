import { Textarea } from "@herohealthsoftware/ui";
import React from "react";

import { translate } from "../../../../lib/i18n";
import AdditionalOption from "./AdditionalOption";

type MemoInputProps = {
  value: string;
  onChange: (value) => void;
  open: boolean;
  onOpenChange: (value) => void;
  disabled: boolean;
  error: string;
};

export default function MemoInput(props: MemoInputProps) {
  return (
    <div className="mb-6">
      <AdditionalOption
        id="memo"
        name="memo_checkbox"
        value="3"
        label={translate("partners.stripe.addMemo")}
        subtext={translate("partners.stripe.addMemoMaxCharacters")}
        checked={props.open}
        onChange={(e) => props.onOpenChange(e.target.checked)}
      >
        <div className="ml-6 mb-5">
          <Textarea
            id="partners_stripe_invoice_memo"
            name="partners_stripe_invoice[memo]"
            rows={5}
            value={props.value}
            onChange={(event) => props.onChange(event.target.value)}
            className="form-control text optional mt-2"
            maxLength={500}
            error={!!props.error}
            errorText={props.error}
            disabled={props.disabled}
          />
        </div>
      </AdditionalOption>

      {!props.open && (
        <input value="" type="hidden" name="partners_stripe_invoice[memo]" />
      )}
    </div>
  );
}
