import { Link, Select, SelectMenu, useSearch } from "@herohealthsoftware/ui";
import ld from "lodash";
import React, { useState } from "react";

import { translate } from "../../../../lib/i18n";
import { formatPence, toPounds, totalInPence } from "../../../../lib/money";
import {
  invoiceItemFieldsFromProduct,
  searchStripe,
  StripeSearchOptions,
} from "../../../../lib/stripe";
import { Customer, InvoiceItem, Price, Product } from "../../../../lib/types";
import { ItemsInputConfig } from "./ItemsInput";

type NewItemsFieldsProps = {
  items: InvoiceItem[];
  onClose: (items: InvoiceItem[]) => void;
  onSubmit: (items: InvoiceItem[], addAnother?: boolean) => void;
  config: ItemsInputConfig;
};

export function NewItemsFields(props: NewItemsFieldsProps) {
  const isEditing = ld.some(props.items, (item) => item.mode === "edit");
  const [items, setItems] = useState<InvoiceItem[]>(props.items);
  const { onSearch, loading, results, resetSearch } = useSearch<
    Customer,
    StripeSearchOptions
  >(async (query, options) =>
    searchStripe<Customer>(query, { ...options, type: "product" })
  );

  const onChange = (fields, index) => {
    const items_ = items.slice();
    items_[index] = { ...items_[index], ...fields };

    setItems(items_);
  };

  const getSelectedPrice = (item) => {
    return (
      item.prices.find((price) => price.id === item.price) || item.prices[0]
    );
  };

  const setSelectedPrice = (value, item, index) => {
    const price = item.prices.find((price) => price.id === value);
    const unit_amount_in_pounds = toPounds(price.unit_amount);

    onChange({ price: price.id, unit_amount_in_pounds }, index);
  };

  const isValid = (items: InvoiceItem[]) => {
    return ld.every(items, (item) => {
      if (!item.description) return false;
      if (!item.quantity || Number(item.quantity) < 1) return false;
      if (
        !item.unit_amount_in_pounds ||
        Number(item.unit_amount_in_pounds) <= 0.0
      )
        return false;

      return true;
    });
  };

  const totalAmount = (items: InvoiceItem[]) => {
    if (!isValid(items)) return "£-.--";

    return formatPence(totalInPence(items));
  };

  return (
    <div className="relative m-0">
      <div
        onClick={() => props.onClose(items)}
        className="z-20 bg-white fixed top-0 left-0 w-screen h-screen opacity-80"
      ></div>

      <div className="z-30 relative grid grid-cols-12 gap-x-2 gap-y-5">
        <div className="col-span-12 flex justify-between">
          <span className="text-base leading-6 font-bold text-hero-blue-700">
            {translate("partners.stripe.itemDetails")}
          </span>

          <span className="text-base leading-6 font-medium text-hero-blue-700">
            {totalAmount(items)}
          </span>
        </div>

        {items.map((item, index) => (
          <React.Fragment key={index}>
            <div className="form-group col-span-8 m-0">
              <label
                htmlFor="item"
                className="text-sm leading-5 font-semibold text-hero-blue-700"
              >
                {translate("partners.stripe.item")}
              </label>

              {item.product ? (
                <SelectMenu
                  searchPlaceholder={translate(
                    "partners.stripe.searchProducts"
                  )}
                  searchLoading={loading}
                  selected={item.product}
                  options={ld
                    .chain([item.product, ...results])
                    .uniqBy("stripe_id")
                    .map((product: Product) => ({
                      label: product.name,
                      value: product,
                    }))
                    .value()}
                  onSearch={onSearch}
                  onSelect={(product: Product) => {
                    onChange(invoiceItemFieldsFromProduct(product), index);
                  }}
                  onClose={resetSearch}
                />
              ) : (
                <input
                  type="text"
                  name="description"
                  value={item.description}
                  onChange={(event) =>
                    onChange({ description: event.target.value }, index)
                  }
                  className="form-control"
                />
              )}
            </div>

            <div className="form-group col-span-1 m-0">
              <label
                htmlFor="quantity"
                className="text-sm leading-5 font-semibold text-hero-blue-700"
              >
                {translate("partners.stripe.qty")}
              </label>

              <input
                type="number"
                name="quantity"
                value={item.quantity}
                onChange={(event) => {
                  const quantity =
                    event.target.value &&
                    Math.round(Number(event.target.value));

                  onChange({ quantity }, index);
                }}
                className="form-control"
              />
            </div>

            {item.product ? (
              <div className="form-group col-span-3 m-0">
                <label
                  htmlFor="price"
                  className="text-sm leading-5 font-semibold text-hero-blue-700"
                >
                  {translate("partners.stripe.listPrice")}
                </label>
                <Select
                  key={item.product.stripe_id}
                  value={getSelectedPrice(item).id}
                  options={priceOptions(item.prices)}
                  onChange={(value) => setSelectedPrice(value, item, index)}
                />
              </div>
            ) : (
              <div className="form-group col-span-3 m-0">
                <label
                  htmlFor="unit_amount_in_pounds"
                  className="text-sm leading-5 font-semibold text-hero-blue-700"
                >
                  {translate("partners.stripe.listPrice")}
                </label>
                <input
                  type="number"
                  name="unit_amount_in_pounds"
                  value={item.unit_amount_in_pounds}
                  onChange={(event) =>
                    onChange(
                      { unit_amount_in_pounds: event.target.value },
                      index
                    )
                  }
                  className="form-control"
                />
              </div>
            )}
          </React.Fragment>
        ))}

        <div className="col-span-12 flex items-center justify-end">
          <Link
            href="#"
            onClick={(event) => {
              event.preventDefault();
              props.onClose(items);
            }}
            asChild={false}
            variant="primary"
            className="mr-3 no-underline"
          >
            {translate("base.cancel")}
          </Link>

          {!isEditing && (
            <button
              onClick={() => {
                props.onSubmit(items, true);
              }}
              className="b b-outline b-sm"
              disabled={!isValid(items)}
            >
              {translate(
                `partners.stripe.addAndAddAnotherProduct.${props.config.resource}`
              )}
            </button>
          )}

          <button
            onClick={() => props.onSubmit(items)}
            className="b b-primary b-sm"
            disabled={!isValid(items)}
          >
            {isEditing
              ? translate("partners.stripe.updateItem")
              : translate(
                  `partners.stripe.addProduct.${props.config.resource}`
                )}
          </button>
        </div>
      </div>
    </div>
  );
}

function priceOptions(prices?: Price[]) {
  if (ld.isEmpty(prices)) return [];

  return prices.map((price) => {
    let label = formatPence(price.unit_amount);
    if (price.nickname) label += ` - ${price.nickname}`;

    return { label, value: price.id };
  });
}
