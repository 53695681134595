import React, { useState } from "react";

import * as Routes from "../../../../routes";
import Hero from "../../../../lib/hero";
import Toggle from "../../../atoms/Toggle";

type SettingsRowProps = {
  name: string;
  label: string;
  checked: boolean;
};

export default function SettingsRow(props: SettingsRowProps) {
  const [checked, setChecked] = useState<boolean>(!!props.checked);

  const handleToggle = async () => {
    const value = !checked;
    setChecked(value);

    const response = await Hero.fetch(
      Routes.partners_stripe_admins_settings_path(),
      {
        method: "POST",
        body: {
          settings: {
            stripe: {
              [props.name]: value,
            },
          },
        },
      }
    );

    if (!response.ok) {
      setChecked(!value);
    }
  };

  return (
    <div
      key={props.name}
      className="flex justify-between text-sm font-medium px-2 mb-8"
    >
      {props.label}
      <Toggle checked={checked} onChange={handleToggle} />
    </div>
  );
}
