import React from "react";
import { Table } from "@herohealthsoftware/ui";

import { translate } from "../../../../lib/i18n";
import MetadataSettingsRow from "./MetadataSettingsRow";
import { StripeSettings } from "../../../../lib/types";
import Toggle from "../../../atoms/Toggle";
import SettingsRow from "./SettingsRow";

type StripePartnerSettingsProps = {
  settings: StripeSettings;
};

export default function StripePartnerSettings(
  props: StripePartnerSettingsProps
) {
  const headers = { key: translate("base.key").toUpperCase() };

  const settings = translate("partners.stripe.metadataSettings");
  const sections = Object.keys(settings).map((sectionKey) => {
    const section = settings[sectionKey];

    return {
      heading: section.heading,
      rows: Object.entries(section.rows).map(
        ([metadataField, value]: [string, string]) => {
          return {
            values: {
              key: (
                <MetadataSettingsRow
                  sectionKey={sectionKey}
                  metadataField={metadataField}
                  value={value}
                  checked={
                    props.settings.metadata?.[sectionKey]?.[metadataField]
                  }
                />
              ),
            },
          };
        }
      ),
    };
  });

  return (
    <div className="flex flex-col w-full">
      {sections.map((section) => (
        <React.Fragment key={section.heading}>
          <h2 className="text-xl text-hero-blue-700 mb-5 font-bold tracking-wide">
            {section.heading}
          </h2>

          <Table headers={headers} rows={section.rows} className="mb-8" />
        </React.Fragment>
      ))}

      <h2 className="text-xl text-hero-blue-700 mb-5 font-bold tracking-wide">
        {translate("partners.stripe.legacyInvoicing")}
      </h2>

      <SettingsRow
        name="show_legacy_invoicing"
        label={translate("partners.stripe.legacyInvoicingToggle")}
        checked={props.settings?.show_legacy_invoicing}
      />

      <h2 className="text-xl text-hero-blue-700 mb-5 font-bold tracking-wide">
        {translate("partners.stripe.smartInvoicing")}
      </h2>

      <SettingsRow
        name="smart_invoice_matching"
        label={translate("partners.stripe.smartInvoicingToggle")}
        checked={props.settings?.smart_invoice_matching}
      />
    </div>
  );
}
