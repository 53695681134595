import React, { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";

import { CardReader, CardReaderLocation } from "../../../../lib/types";
import {
  Button,
  IconArrowRight,
  IconCreditCard,
  IconInformationCircle,
  Link,
  Select,
  TextInput,
} from "@herohealthsoftware/ui";
import { translate } from "../../../../lib/i18n";
import Radio from "../../../atoms/Radio";
import StatusBadge from "../StatusBadge";
import { isEmpty } from "lodash";

type CardReaderSelectProps = {
  card_readers: [CardReaderLocation, CardReader[]][];
  waiting: boolean;
  cancelled: boolean;
  setCancelled: (cancelled: boolean) => void;
  onReaderChange: (reader: CardReader) => void;
  stripeBaseUrl: string;
};

export default function CardReaderSelect(props: CardReaderSelectProps) {
  const locations = props.card_readers.map(([location, _]) => location);
  const [location, setLocation] = useState<CardReaderLocation>(locations[0]);

  const readers =
    props.card_readers.find(([loc, _]) => loc.id === location.id)?.[1] || [];
  const [selected, setSelected] = useState<CardReader | null>(null);

  const isSimulatedReader = selected?.device_type.match(/simulated/i);
  const [simluatedCardNumber, setSimulatedCardNumber] = useState<string>("");

  const onLocationChange = (value: string) => {
    setLocation(locations.find((location) => location.id === value));
  };

  const onReaderChange = (reader: CardReader) => {
    setSimulatedCardNumber("");
    setSelected(reader);
    props.onReaderChange(reader);
  };

  const handleSimulatedCardNumberChange = (event) => {
    if (!isSimulatedReader) return;

    setSimulatedCardNumber(event.target.value);
    props.onReaderChange({
      ...selected,
      simulated_card_number: event.target.value,
    });
  };

  if (isEmpty(props.card_readers.flat())) {
    return <NoCardReadersBox stripeBaseUrl={props.stripeBaseUrl} />;
  }

  return (
    <div className="flex flex-col gap-4">
      <Dialog.Root open={props.waiting}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 z-40" />

          <Dialog.Content
            className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
                z-50 w-fit text-hero-blue-700 bg-white shadow-lg rounded-lg
                border border-hero-blue-200"
          >
            <div className="py-5 px-6 flex gap-4 items-start border-b border-hero-blue-200">
              <div className="p-2.5 rounded-full bg-hero-blue-100">
                <div className="h-6 w-6">
                  <IconCreditCard />
                </div>
              </div>

              <div className="flex flex-col">
                <h3 className="text-xl leading-7 font-bold mb-1 whitespace-nowrap">
                  {translate("partners.stripe.paymentProcessingCardReader")}
                </h3>

                <p className="text-sm leading-5 font-normal text-hero-blue-400">
                  {translate(
                    "partners.stripe.paymentProcessingCardReaderHelp"
                  ).replace("%label%", selected?.label)}
                </p>
              </div>
            </div>

            <div className="flex justify-end py-5 px-6">
              <Button
                variant="primary"
                onClick={() => props.setCancelled(true)}
                loading={props.cancelled}
              >
                {translate("partners.stripe.cancelTransaction")}
              </Button>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      <div className="w-80">
        <label
          htmlFor="price"
          className="text-sm leading-5 font-semibold text-hero-blue-700 mb-1"
        >
          {translate("base.location")}
        </label>

        <Select
          key={location.id || "location"}
          placeholder={translate("partners.stripe.selectALocation")}
          name="card_reader_location"
          value={location.id}
          options={locations.map((location) => ({
            value: location.id,
            label: location.display_name,
          }))}
          onChange={onLocationChange}
        />
      </div>

      <div>
        {readers.length > 0 ? (
          <div className="flex flex-col gap-2">
            {readers.map((reader) => (
              <React.Fragment key={reader.id}>
                <Radio
                  key={reader.id}
                  name="terminal"
                  value={reader.id}
                  label={
                    <div className="flex gap-2 items-center my-0.5">
                      <div className="text-sm leading-5 font-semibold">
                        {reader.label}
                      </div>
                      <StatusBadge
                        resource="reader"
                        status={reader.status}
                        className="m-0 py-0.5 "
                      />
                    </div>
                  }
                  checked={reader.id === selected?.id}
                  onChange={() => onReaderChange(reader)}
                />

                {reader.id === selected?.id && isSimulatedReader && (
                  <div className="form-group w-80">
                    <div className="px-3 py-1 mb-2 bg-yellow-100 rounded text-xs text-yellow-700 font-semibold w-fit">
                      {translate("base.testingOnly")}
                    </div>

                    <label
                      className="control-label"
                      htmlFor="simulated_card_number"
                    >
                      {translate("partners.stripe.simulatedCardNumber")}
                    </label>

                    <TextInput
                      name="simulated_card_number"
                      value={simluatedCardNumber}
                      onChange={handleSimulatedCardNumberChange}
                      className="mb-1"
                    />

                    <Link variant="secondary" sizes="xs">
                      <a href="https://stripe.com/docs/testing" target="_blank">
                        {translate("partners.stripe.simulatedCardNumberHelp")}
                      </a>
                    </Link>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        ) : (
          <NoCardReadersBox stripeBaseUrl={props.stripeBaseUrl} />
        )}
      </div>
    </div>
  );
}

export function NoCardReadersBox(props: { stripeBaseUrl: string }) {
  return (
    <div
      className="flex flex-row items-start justify-between bg-hero-blue-50 rounded
            py-2 pl-2 pr-4 font-medium w-full"
    >
      <div className="flex items-start gap-2">
        <div className="bg-hero-blue-100 rounded p-1">
          <div className="h-4 w-4 [&_path]:fill-blue-500">
            <IconInformationCircle />
          </div>
        </div>

        <div>
          <div className="text-sm leading-5 font-medium text-blue-700">
            {translate("partners.stripe.noCardReaders")}
          </div>

          <p className="text-xs leading-4 font-normal text-blue-700">
            {translate("partners.stripe.noCardReadersHelp")}
          </p>
        </div>
      </div>

      <Link variant="blue" className="no-underline p-2">
        <a
          className="flex items-center gap-1 cursor-pointer"
          href={`${props.stripeBaseUrl}/terminal`}
          target="_blank"
        >
          {translate("partners.stripe.goToStripe")}

          <div className="h-3 w-3 [&_path]:fill-blue-700">
            <IconArrowRight />
          </div>
        </a>
      </Link>
    </div>
  );
}
