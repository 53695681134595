import React from "react";

export type CheckboxProps = {
  id: string;
  name: string;
  value: string;
  label: string;
  subtext?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  handleChange?: (event) => void;
  className?: string;
  disabled?: boolean;
  labelClass?: string;
  checkboxClass?: string;
};

const Checkbox = ({
  id,
  name,
  value,
  label,
  subtext,
  checked,
  defaultChecked,
  handleChange,
  className,
  disabled,
  labelClass,
  checkboxClass
}: CheckboxProps) => {
  return (
    <label htmlFor={id} className={`flex gap-2 items-start ${disabled && "opacity-50"} ${className || ""}`}>
      <input
        type="checkbox"
        name={name}
        id={id}
        value={value}
        checked={checked}
        defaultChecked={defaultChecked}
        className={`icon-tick-indicator appearance-none my-1 ${checkboxClass ? checkboxClass : "w-4 h-4  hover:border-2"} border border-hero-blue-300 rounded bg-white ${!disabled && "hover:border-hero-primary-700"} focus:outline-none focus:ring focus:ring-hero-outline focus:ring-offset-2 focus:border-hero-primary-700 focus:border-2 checked:bg-hero-primary-500 checked:border-hero-primary-700`}
        onChange={handleChange}
        disabled={disabled}
      ></input>

      <div>
        <div className={`${labelClass ? labelClass : "text-base leading-6 font-bold text-hero-blue-700"}`}>
          {label}
        </div>
        <div className="text-sm leading-5 font-normal text-hero-blue-400">
          {subtext}
        </div>
      </div>
    </label>
  );
};

export default Checkbox;
