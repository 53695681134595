import React from "react";
import HeroHealthBookingHcaElement from "@herohealthsoftware/booking-hca-react";
import { Spinner, ThemeProvider } from "@herohealthsoftware/ui";

const BookingHcaElement = ({
  env,
  practiceGroupId,
  patientId,
  jwtToken,
  hcaBookingAuthToken,
}) => {
  const sharedProps = {
    env,
    tenantId: practiceGroupId,
    practiceGroupId: null,
    patientId,
    telemetry: true,
    hcaAuthToken: hcaBookingAuthToken,
    loader: (
      <ThemeProvider>
        <Spinner className="h-12 w-12" />
      </ThemeProvider>
    ),
  };
  return <HeroHealthBookingHcaElement jwtToken={jwtToken} {...sharedProps} />;
};

export default BookingHcaElement;
