import {
  ButtonGroup,
  Button,
  IconChevronDoubleLeft,
} from "@herohealthsoftware/ui";
import React from "react";
import { translate } from "../../../lib/i18n";

export type PaginationButtonsProps = {
  onReset: () => void;
  onPrevious: () => void;
  onNext: () => void;
  disabled: Record<"reset" | "previous" | "next", boolean>;
};

export default function PaginationButtons(props: PaginationButtonsProps) {
  if (props.disabled.reset && props.disabled.previous && props.disabled.next) {
    return null;
  }

  return (
    <div className="flex flex-row justify-end">
      <ButtonGroup>
        <Button
          variant="white"
          size="sm"
          onClick={() => props.onReset()}
          disabled={props.disabled.reset}
          icon
        >
          <IconChevronDoubleLeft />
        </Button>

        <Button
          variant="white"
          size="sm"
          onClick={() => props.onPrevious()}
          disabled={props.disabled.previous}
        >
          {translate("partners.stripe.paginatePrevious")}
        </Button>

        <Button
          variant="white"
          size="sm"
          onClick={() => props.onNext()}
          disabled={props.disabled.next}
        >
          {translate("partners.stripe.paginateNext")}
        </Button>
      </ButtonGroup>
    </div>
  );
}
