import { TextInput } from "@herohealthsoftware/ui";
import React from "react";

import { translate } from "../../../../lib/i18n";
import AdditionalOption from "./AdditionalOption";

type InvoiceBillingDetails = {
  insurer: string;
  authentication_code: string;
  membership_number: string;
};

export type InsuranceDetailsInputProps = {
  values: InvoiceBillingDetails;
  onChange: (fieldName: string, value: string | boolean) => void;
  open: boolean;
  onOpenChange: (value: boolean) => void;
  disabled: boolean;
  error: string;
};

const InsuranceDetailsInput: React.FC<InsuranceDetailsInputProps> = (props) => {
  return (
    <>
      <div className="mb-6">
        <AdditionalOption
          id="insurer_details"
          name="insurer_details_checkbox"
          value="6"
          label={translate("partners.stripe.addInsuranceDetails")}
          onChange={(e) => props.onOpenChange(e.target.checked)}
          checked={props.open}
        >
          <div className="flex flex-col gap-1">
            <div className="grid grid-cols-3 gap-4 ml-6 mt-3">
              <TextInput
                className="form-control text optional"
                name="partners_stripe_invoice[insurer]"
                id="partners_stripe_invoice_insurer"
                label="Insurer"
                value={props.values.insurer}
                onChange={(e) => props.onChange("insurer", e.target.value)}
                disabled={props.disabled}
                error={!!props.error}
              />

              <TextInput
                className="form-control text optional"
                name="partners_stripe_invoice[authentication_code]"
                id="partners_stripe_invoice_authentication_code"
                label="Authentication code"
                value={props.values.authentication_code}
                onChange={(e) =>
                  props.onChange("authentication_code", e.target.value)
                }
                disabled={props.disabled}
                error={!!props.error}
              />

              <TextInput
                className="form-control text optional"
                name="partners_stripe_invoice[membership_number]"
                id="partners_stripe_invoice_membership_number"
                label="Membership number"
                value={props.values.membership_number}
                onChange={(e) =>
                  props.onChange("membership_number", e.target.value)
                }
                disabled={props.disabled}
                error={!!props.error}
              />
            </div>

            {props.error && (
              <div className="col-span-3 text-red-700 ml-6">{props.error}</div>
            )}
          </div>
        </AdditionalOption>

        {!props.open && (
          <>
            <input
              value=""
              type="hidden"
              name="partners_stripe_invoice[insurer]"
            />
            <input
              value=""
              type="hidden"
              name="partners_stripe_invoice[authentication_code]"
            />
            <input
              value=""
              type="hidden"
              name="partners_stripe_invoice[membership_number]"
            />
          </>
        )}
      </div>
    </>
  );
};
export default InsuranceDetailsInput;
