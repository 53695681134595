import { includes, isEmpty, reduce, sum } from "lodash";
import { translate } from "./i18n";
import { Coupon, InvoiceItem } from "./types";

export const toPence = (pounds: string) => {
  pounds = pounds.replace("£", "").replace(",", "");
  const [sign, value] = pounds.startsWith("-")
    ? ["-", pounds.slice(1)]
    : ["", pounds];
  const [pounds_, pence] = value.split(".", 2);

  return Number(`${sign}${pounds_}${pence || "00"}`);
};

export const toPounds = (pence: number) => {
  // convert as string to avoid rounding errors
  const sign = pence < 0 ? "-" : "";
  const value = Math.abs(pence).toFixed();

  return `${sign}${value.slice(0, -2) || "0"}.${value.slice(-2)}`;
};

export const formatPounds = (value: string) => {
  let [pounds, pence] = value.split(".", 2);
  pounds ||= "0";
  pence = (pence || "00").padEnd(2, "0");

  const prefix = pounds.startsWith("-") ? "-£" : "£";
  pounds = pounds.replace("-", "");

  return `${prefix}${pounds}.${pence}`;
};

export const formatPence = (value: number): string => {
  return formatPounds(toPounds(value));
};

export const formatProductPrice = (product) => {
  const prices = product.prices || [];

  if (prices.length > 1)
    return `${prices.length} ${translate("partners.stripe.prices")}`;

  const price = product.default_price || prices[0];

  if (price.recurring?.interval_count) {
    if (price.recurring.interval_count > 1) {
      return `${formatPence(price.unit_amount)} / ${
        price.recurring.interval_count
      } ${price.recurring.interval}s`;
    }

    return `${formatPence(price.unit_amount)} / ${price.recurring.interval}`;
  }

  return formatPence(price.unit_amount);
};

export const formatPlanPrice = (plan) => {
  if (plan.interval_count > 1) {
    return `${formatPence(plan.amount)} / ${plan.interval_count} ${
      plan.interval
    }s`;
  }

  return `${formatPence(plan.amount)} / ${plan.interval}`;
};

export const formatPaymentSource = (source) => {
  if (source.type === "card") {
    return formatCardSource(source);
  }

  if (source.type === "bacs_debit") {
    return formatDirectDebitSource(source);
  }

  return "Unknown source";
};

export const formatDirectDebitSource = (source) => {
  const sortCode = source.sort_code.replace(
    /(\d{2})(\d{2})(\d{2})/,
    "$1-$2-$3"
  );

  return `Direct debit ${sortCode} ${translate("partners.stripe.ending")}${
    source.last4
  }`;
};

export const formatCardSource = (source) => {
  // https://stripe.com/docs/api/charges/object#charge_object-payment_method_details-card-brand
  const brands = {
    amex: "American Express",
    diners: "Diners Club",
    discover: "Discover",
    eftpos_au: "Eftpos Australia",
    jcb: "JCB",
    mastercard: "MasterCard",
    unionpay: "UnionPay",
    visa: "Visa",
    unknown: "Unknown",
  };

  return `${brands[source.brand] || source.brand} ${translate(
    "partners.stripe.ending"
  )}${source.last4} ${source.exp_month}/${source.exp_year}`;
};

export const totalInPence = (items: InvoiceItem[]) => {
  const total = reduce(
    items,
    (acc, item) =>
      acc + Number(item.quantity) * Number(item.unit_amount_in_pounds) * 100,
    0
  );

  return Math.round(total);
};

export const calculateTotal = (items: InvoiceItem[], coupons: Coupon[]) => {
  const withDiscount = totalInPence(items) + calculateDiscount(items, coupons);

  return Math.max(withDiscount, 0);
};

export const calculateDiscount = (items: InvoiceItem[], coupons: Coupon[]) => {
  const forCoupon = (coupon: Coupon) => {
    const appliesTo = coupon.applies_to?.products;

    let items_ = items.slice();
    if (appliesTo) {
      items_ = items_.filter((item) => {
        if (isEmpty(item.prices)) return false;

        const price = item.prices.find((price) => price.id === item.price);
        const product =
          typeof price.product === "string" ? price.product : price.product.id;

        return includes(appliesTo, product);
      });
    }

    if (isEmpty(items_)) return 0;

    if (coupon.amount_off) {
      return coupon.amount_off;
    } else if (coupon.percent_off) {
      return Math.floor(totalInPence(items_) * (coupon.percent_off / 100.0));
    } else {
      return 0;
    }
  };

  return -sum(coupons.map(forCoupon));
};
