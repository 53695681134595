import {
  IconPlus1,
  ObjectSearch,
  StripeSearchResult,
} from "@herohealthsoftware/ui";
import React, { useState } from "react";

import { translate } from "../../../../lib/i18n";
import { searchStripe } from "../../../../lib/stripe";
import { EmisItem, InvoiceItem, Product } from "../../../../lib/types";
import * as Routes from "../../../../routes";
import { EmisItemsModal } from "./EmisItemsModal";
import { ItemsInputConfig } from "./ItemsInput";

type ItemsSearchProps = {
  onNewItemClick: (item: Partial<InvoiceItem>) => void;
  onSelectProductClick: (item: Product) => void;
  onSubmitEmisItems?: (emisItems: EmisItem[]) => void;
  config: Pick<ItemsInputConfig, "oneTimeItems" | "emisItems">;
  focusRef?: React.MutableRefObject<any>;
};

export default function ItemsSearch(props: ItemsSearchProps) {
  const [showEmisProducts, setShowEmisProducts] = useState(false);
  const emisItems = props.config.emisItems || undefined;

  const actions = [
    {
      key: "add-one-off-item",
      enabled: props.config.oneTimeItems,
      label: translate("partners.stripe.addOneTimeItem"),
      icon: <IconPlus1 />,
      onClick: () => props.onNewItemClick({ create: false }),
    },
    {
      key: "create-new-product",
      enabled: true,
      label: translate("partners.stripe.createNewProduct"),
      icon: <IconPlus1 />,
      onClick: () => props.onNewItemClick({ create: true }),
    },
    {
      key: "add-emis-product",
      enabled: !!emisItems?.enabled,
      label: translate("partners.stripe.addProductFromEmis"),
      icon: <IconPlus1 />,
      onClick: () => setShowEmisProducts(true),
    },
  ];

  return (
    <>
      {emisItems?.enabled && (
        <EmisItemsModal
          open={showEmisProducts}
          onOpenChange={setShowEmisProducts}
          onSubmitEmisItems={props.onSubmitEmisItems}
          emisItemsPath={Routes.partners_stripe_admins_emis_path({
            patient_record_id: emisItems.patient_record.id,
          })}
        />
      )}

      <ObjectSearch
        actions={actions}
        focusRef={props.focusRef}
        onSelect={props.onSelectProductClick}
        fetcher={async (query) =>
          searchStripe<Product>(query, { type: "product" })
        }
        // TODO: fix typing [post-monorepo-issue]
        renderOption={(product) => (
          <StripeSearchResult product={product as never} />
        )}
        messages={{
          placeholder: translate("partners.stripe.findOrAddAnItem"),
          resultsHeading: translate("partners.stripe.stripeProducts"),
          loading: translate("partners.stripe.loadingStripeProducts"),
          noResults: translate("partners.stripe.noMatchingProductsFound"),
        }}
        className="md:w-1/2"
      />
    </>
  );
}
