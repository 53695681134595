import { Select, IconCreditCard, Link } from "@herohealthsoftware/ui";
import React, { useEffect, useState } from "react";

import { translate } from "../../../../lib/i18n";
import { Customer } from "../../../../lib/types";
import { getDefaultSource } from "../../../../lib/customer";
import { formatPaymentSource } from "../../../../lib/money";

type PaymentSourceSelectProps = {
  customer: Customer;
  payment_source: string;
  payment_source_field_name: string;
  stripeBaseUrl: string;
  onReloadClick: () => void;
  onChange?: (value: string) => void;
};

export default function PaymentSourceSelect(props: PaymentSourceSelectProps) {
  const [disabled, setDisabled] = useState(false);
  const sources = props.customer?.sources || [];
  const selected = props.payment_source || getDefaultSource(props.customer);

  useEffect(() => {
    setDisabled(false);
  }, [props.customer]);

  return (
    <div className="relative">
      {disabled && (
        <div className="z-40 bg-white absolute top-0 bottom-0 left-0 w-full opacity-80"></div>
      )}

      {sources.length > 0 && (
        <div className="w-80 mb-4">
          <Select
            key={selected || "payment_source"}
            placeholder={translate("partners.stripe.selectAPaymentMethod")}
            name={props.payment_source_field_name}
            value={selected}
            options={sources.map((source) => ({
              value: source.id,
              label: formatPaymentSource(source),
            }))}
            onChange={props.onChange}
          />
        </div>
      )}

      <div
        className="flex items-center gap-4 bg-hero-blue-50 rounded
            py-2 pl-2 pr-4 font-medium max-w-fit"
      >
        <div className="text-blue-700 flex gap-2 items-center">
          <div className="bg-hero-blue-100 rounded p-1">
            <div className="h-4 w-4 [&_path]:fill-blue-500">
              <IconCreditCard />
            </div>
          </div>

          {sources.length < 1 && (
            <div>{translate("partners.stripe.noPaymentMethods")}</div>
          )}
        </div>

        <Link variant="blue">
          <a
            className="flex items-center gap-1 cursor-pointer"
            href={`${props.stripeBaseUrl}/customers/${props.customer.stripe_id}`}
            target="_blank"
          >
            {translate("partners.stripe.addAPaymentMethod")}
          </a>
        </Link>

        <Link
          variant="blue"
          onClick={(event) => {
            setDisabled(true);
            event.preventDefault();
            props.onReloadClick();
          }}
          className="cursor-pointer"
        >
          <div>{translate("partners.stripe.reloadPaymentMethods")}</div>
        </Link>
      </div>
    </div>
  );
}
