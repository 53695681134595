import {
  Button,
  Datepicker,
  InlineAlert,
  Link,
  Modal,
  ModalBody,
  ModalClose,
  ModalContent,
  ModalFooter,
  ModalHeader,
  TextInput,
} from "@herohealthsoftware/ui";
import { DateTime } from "luxon";
import React from "react";
import { updateCustomer } from "../../../../lib/customer";
import { translate } from "../../../../lib/i18n";
import { Customer, Invoice } from "../../../../lib/types";
import Radio from "../../../atoms/Radio";
import { CollectionMethod } from "./InvoiceForm";

export type PaymentFieldsProps = {
  invoice: Invoice;
  stripe_publishable_key: string;
  stripe_account: string;
  customer?: Customer;
  setCustomer: (customer: Customer) => void;
  defaultCustomer?: Customer;
  setDefaultCustomer: (customer: Customer) => void;
  collectionMethod: CollectionMethod;
  setCollectionMethod: (collectionMethod: CollectionMethod) => void;
  collection_method_field_name: string;
  payment_source: string;
  payment_source_field_name: string;
  onReloadSourcesClick: () => void;
  stripeBaseUrl: string;
};

export default function PaymentFields(props: PaymentFieldsProps) {
  const [dueDate, setDueDate] = React.useState<Date>(
    props.invoice?.due_date
      ? new Date(DateTime.fromISO(props.invoice.due_date))
      : new Date()
  );

  const [processingAddEmail, setProcessingAddEmail] =
    React.useState<boolean>(false);
  const [addEmailModalOpen, setAddEmailModalOpen] =
    React.useState<boolean>(false);
  const [customerEmail, setCustomerEmail] = React.useState<string>("");
  const [customerEmailError, setCustomerEmailError] =
    React.useState<string>("");

  const handleUpdateCustomerEmail = async (event) => {
    event.preventDefault();
    setProcessingAddEmail(true);

    if (!customerEmail) {
      setCustomerEmailError("An email address is required");
      setProcessingAddEmail(false);
      return;
    }

    try {
      const response = await updateCustomer({
        ...props.customer,
        email: customerEmail,
      });

      if (
        props.defaultCustomer &&
        props.defaultCustomer.stripe_id === props.customer?.stripe_id
      ) {
        props.setDefaultCustomer(response);
      }

      props.setCustomer(response);
      setAddEmailModalOpen(false);
      setProcessingAddEmail(false);
    } catch (error) {
      setCustomerEmailError(error.message);
      setProcessingAddEmail(false);
    }
  };

  const handleCollectionMethodChange = (event) => {
    const { value } = event.target;

    props.setCollectionMethod(value);
  };

  return (
    <div
      className={`${
        !props.customer &&
        "relative after:content-[''] after:absolute after:w-full after:h-full after:inset-0 after:bg-white after:opacity-80 after:z-40"
      }`}
    >
      <div className={`flex flex-col gap-5 mt-7`}>
        <p className="text-xl leading-7 font-bold text-hero-blue-700">
          {translate("partners.stripe.paymentCollection")}
        </p>

        <Radio
          id="collection_method_request_payment"
          name={props.collection_method_field_name}
          value="request_payment"
          label={translate("partners.stripe.requestPayment")}
          subtext={translate("partners.stripe.requestPaymentHelp")}
          checked={
            props.customer && props.collectionMethod === "request_payment"
          }
          onChange={handleCollectionMethodChange}
        />

        {props.collectionMethod === "request_payment" && props.customer && (
          <>
            {!props.customer.email && (
              <>
                <div className="pl-6">
                  <InlineAlert
                    label={translate("partners.stripe.customerMissingEmail")}
                    variant="warning"
                    action={
                      <Link variant="yellow">
                        <a
                          href="#"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setAddEmailModalOpen(true);
                          }}
                        >
                          {translate("partners.stripe.addEmail")}
                        </a>
                      </Link>
                    }
                  >
                    {translate("partners.stripe.customerMissingEmailHelp")}
                  </InlineAlert>
                </div>

                <Modal open={addEmailModalOpen}>
                  <ModalContent size="lg">
                    <ModalHeader
                      title={translate("partners.stripe.addCustomerEmail")}
                    />
                    <ModalBody>
                      <TextInput
                        error={!!customerEmailError}
                        errorText={customerEmailError}
                        label={translate("base.email")}
                        onChange={(ev) => setCustomerEmail(ev.target.value)}
                        value={customerEmail}
                      />
                    </ModalBody>
                    <ModalFooter className="flex justify-end">
                      <ModalClose
                        className="b b-outline"
                        onClick={() => {
                          setAddEmailModalOpen(false);
                          setCustomerEmailError("");
                          setCustomerEmail("");
                        }}
                      >
                        {translate("base.cancel")}
                      </ModalClose>

                      <Button
                        className="b b-primary"
                        onClick={handleUpdateCustomerEmail}
                        loading={processingAddEmail}
                      >
                        {translate("partners.stripe.addEmail")}
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </>
            )}

            <div className="pl-6 form-group w-1/3">
              <label className="control-label">
                {translate("partners.stripe.dueDate")}
              </label>

              <Datepicker
                name="partners_stripe_invoice[due_date]"
                id="partners_stripe_invoice_due_date"
                onChange={(value) => setDueDate(value)}
                selected={dueDate}
                minDate={new Date()}
              />
            </div>
          </>
        )}

        <Radio
          id="collection_method_charge_customer"
          name={props.collection_method_field_name}
          value="charge_customer"
          label={translate("partners.stripe.payNow")}
          subtext={translate("partners.stripe.payNowHelpInvoice")}
          checked={
            props.customer && props.collectionMethod === "charge_customer"
          }
          onChange={handleCollectionMethodChange}
        />
      </div>
    </div>
  );
}
