import React from "react";
import HeroLinkBuilder from "@herohealthsoftware/link-builder-react";

const LinkBuilder = ({ env, jwtToken, practiceGroupId }) => {
  const sharedProps = {
    env,
    tenantId: practiceGroupId,
    telemetry: true,
  };
  return <HeroLinkBuilder jwtToken={jwtToken} {...sharedProps} />;
};

export default LinkBuilder;
