import React from 'react';
import { Button, IconPlus1} from '@herohealthsoftware/ui';
import { AdminRecord, PracticeGroupItem } from '../../lib/types';

interface DescriptionCardProps {
  setIsCreateMode: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAdmin: React.Dispatch<React.SetStateAction<PracticeGroupItem>>;
  setSelectedMembers?: React.Dispatch<React.SetStateAction<AdminRecord[]>>;
  isFlag?: boolean;
  title: string;
  no_record: string;
  description: string;
  desc_example?: string;
  btn_title: string;
  iconOutcome?: React.ReactNode;
}
const DescriptionCard: React.FC<DescriptionCardProps> = ({
  setIsCreateMode,
  setSelectedAdmin,
  setSelectedMembers,
  isFlag = true,
  title,
  no_record,
  description,
  desc_example,
  btn_title,
  iconOutcome
}) => {
  return (
    <>
      <div className="w-full h-20 py-5 self-stretch flex-col justify-start items-start gap-0.5 flex">
        <div className="text-center text-hero-blue-700 text-2xl font-bold">{ title }</div>
      </div>
      <div className="w-full self-stretch flex flex-col justify-center items-center border border-hero-blue-100 rounded-md gap-3 py-10">
        <div className={`bg-hero-blue-100 rounded-md ${isFlag ? 'p-2' : 'p-4'}`}>
          {isFlag ? (
            <i className="icon-people text-4xl"></i>
          ) : (
            iconOutcome
          )}
        </div>
        <div className="text-center text-hero-blue-700 text-lg font-bold">{no_record}</div>
        <div className="text-center text-hero-blue-500 text-sm">
          {description}
          {!isFlag && (
            <>
              <br/>{desc_example}
            </>
          )}
        </div>
        <Button
          variant='primary'
          type="button"
          onClick={() => {
            setIsCreateMode(true);
            setSelectedAdmin(null);
            isFlag && setSelectedMembers([]);
          }}
          className='my-2'
        >
          <div className='flex justify-center align-items-center gap-2 text-hero-blue-700'>
            <div><IconPlus1 /></div>
            <div>{ btn_title }</div>
          </div>
        </Button>
      </div>
    </>
  );
};

export default DescriptionCard;
