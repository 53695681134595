import React from "react";

export default function IconStripe() {
  return (
    <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.0769043" width="24" height="24" rx="4" fill="#635BFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9972 8.96703C10.9972 8.32418 11.5247 8.07692 12.3983 8.07692C13.6511 8.07692 15.2335 8.45604 16.4862 9.13187V5.25824C15.1181 4.71429 13.7665 4.5 12.3983 4.5C9.05218 4.5 6.8269 6.24725 6.8269 9.16484C6.8269 13.7143 13.0906 12.989 13.0906 14.9505C13.0906 15.7088 12.4313 15.956 11.5082 15.956C10.1401 15.956 8.39284 15.3956 7.00822 14.6374V18.5604C8.54119 19.2198 10.0906 19.5 11.5082 19.5C14.9368 19.5 17.2939 17.8022 17.2939 14.8516C17.2775 9.93956 10.9972 10.8132 10.9972 8.96703Z"
        fill="white"
      />
    </svg>
  );
}
