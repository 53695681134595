import React from 'react';

export const renderPagination = (totalPages, currentPage, handlePageChange) => {
  const visiblePages = 3;
  const pages = [];
  let startPage, endPage;

  if (totalPages <= visiblePages) {
    startPage = 1;
    endPage = totalPages;
  } else {
    if (currentPage <= Math.floor(visiblePages / 2) + 1) {
      startPage = 1;
      endPage = visiblePages;
    } else if (currentPage + Math.floor(visiblePages / 2) >= totalPages) {
      startPage = totalPages - visiblePages + 1;
      endPage = totalPages;
    } else {
      startPage = currentPage - Math.floor(visiblePages / 2);
      endPage = startPage + visiblePages - 1;
    }
  }

  if (startPage > 1) {
    pages.push(
      <button key={1} onClick={() => handlePageChange(1)} className="border border-hero-blue-200 px-4 py-1">
        1
      </button>
    );
    if (startPage > 2) {
      pages.push(<span key="ellipsis1">...</span>);
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    const isActive = i === currentPage;
    pages.push(
      <button
        key={i}
        onClick={() => handlePageChange(i)}
        disabled={isActive}
        className={`border border-hero-blue-200 px-4 py-1 ${isActive ? 'active text-hero-blue-300' : ''}`}
      >
        {i}
      </button>
    );
  }

  if (endPage < totalPages) {
    if (endPage < totalPages - 1) {
      pages.push(<span key="ellipsis2">...</span>);
    }
    pages.push(
      <button key={totalPages} onClick={() => handlePageChange(totalPages)} className="border border-hero-blue-200 px-4 py-1">
        {totalPages}
      </button>
    );
  }

  return pages;
};

export const renderEntryRange = (currentPage, entriesPerPage, totalPages, totalEntries) => {
  const startIndex = (currentPage - 1) * entriesPerPage + 1;
  const endIndex = currentPage === totalPages ? totalEntries : currentPage * entriesPerPage;
  
  return `Showing ${startIndex} to ${endIndex} of ${totalEntries} entries`;
};
