import { Spinner, Table } from "@herohealthsoftware/ui";
import React, { useEffect, useState } from "react";
import Hero from "../../../../lib/hero";
import { translate } from "../../../../lib/i18n";
import { MembershipPolicy, StripeSettings } from "../../../../lib/types";
import * as Routes from "../../../../routes";
import { formatISODateTime } from "../../../../lib/datetime";
import StatusBadge from "../StatusBadge";
import MembershipPolicySidebar from "./MembershipPolicySidebar";

type MembershipPoliciesSettingsProps = {
  selected?: number;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  settings: StripeSettings;
};

export default function MembershipPoliciesSettings(
  props: MembershipPoliciesSettingsProps
) {
  const [membershipPolicies, setMembershipPolicies] = useState<
    MembershipPolicy[]
  >([]);
  const [selectedMembershipPolicy, setSelectedMembershipPolicy] =
    useState<Partial<MembershipPolicy> | null>(null);

  const fetchMembershipPolicies = async () => {
    const response = await Hero.fetch(
      Routes.partners_stripe_admins_membership_policies_path()
    );

    if (response.ok) {
      const data = await response.json();
      setMembershipPolicies(data);
      props.setLoading(false);

      if (props.selected) {
        const membershipPolicy = data.find(
          (policy: MembershipPolicy) => policy.id === props.selected
        );

        if (!selectedMembershipPolicy && membershipPolicy) {
          setSelectedMembershipPolicy(membershipPolicy);
        }
      }
    }
  };

  const membershipPolicyRowMapper = (membershipPolicy: MembershipPolicy) => {
    return {
      values: {
        customer: membershipPolicy.customer?.name || <>&mdash;</>,
        status: (
          <StatusBadge
            resource="membership_policy"
            status={membershipPolicy.status}
          />
        ),
        members: membershipPolicy.members?.length,
        scheme: membershipPolicy.membership_scheme?.name,
        created: formatISODateTime(
          membershipPolicy.created_at,
          "short-month-name-date"
        ),
      },
      onClick: () => setSelectedMembershipPolicy(membershipPolicy),
      className: "cursor-pointer",
    };
  };

  useEffect(() => {
    fetchMembershipPolicies();
  }, []);

  return (
    <div className="flex flex-col gap-4 w-full items-start">
      {selectedMembershipPolicy && (
        <MembershipPolicySidebar
          membershipPolicy={selectedMembershipPolicy}
          onClose={(reload) => {
            if (reload) {
              props.setLoading(true);
              fetchMembershipPolicies();
            }

            setSelectedMembershipPolicy(null);
          }}
          settings={props.settings}
          sidebar={true}
        />
      )}

      {props.loading && (
        <div
          className="w-full h-64 flex flex-col items-center justify-center
                border border-hero-blue-200 rounded p-6"
        >
          <div className="h-8 w-8">
            <Spinner />
          </div>

          <div className="mt-2 font-medium">
            {translate(
              "partners.stripe.tables.membershipPolicies.loadingMessage"
            )}
            <>&hellip;</>
          </div>
        </div>
      )}

      {!props.loading && membershipPolicies.length === 0 && (
        <div
          className="w-full h-64 flex flex-col items-center justify-center
                border border-hero-blue-200 rounded p-6"
        >
          <div className="mt-2 font-medium text-hero-blue-400">
            {translate(
              "partners.stripe.tables.membershipPolicies.noResultsMessage"
            )}
          </div>
        </div>
      )}

      {!props.loading && membershipPolicies.length > 0 && (
        <div className="w-full">
          <Table
            headers={translate(
              "partners.stripe.tables.membershipPolicies.headers"
            )}
            rows={membershipPolicies.map(membershipPolicyRowMapper)}
            className="w-full"
          />
        </div>
      )}
    </div>
  );
}
