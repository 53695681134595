import React from "react";
import { translate } from "../../../../../lib/i18n";
import { Table, PatientNameStd } from "@herohealthsoftware/ui";
import Toggle from "./Toggle";

const Practitioners = ({ practitioners }: { practitioners: any }) => {
  const headers = {
    name: translate(
      "partners.findAPrivateGp.showOnFindAPrivateGp"
    ).toUpperCase(),
    toggle: null,
  };
  const rows = practitioners.map((practitioner) => {
    return {
      values: {
        name: (
          <PatientNameStd
            title={practitioner.title}
            firstName={practitioner.first_name}
            lastName={practitioner.last_name}
          />
        ),
        togge: <Toggle practitioner={practitioner} />,
      },
    };
  });

  return <Table headers={headers} rows={rows} className="mb-8" />;
};

export default Practitioners;
