import * as Routes from "../routes";
import { formatISODateTime } from "./datetime";
import { formatPence, toPounds } from "./money";
import {
  Invoice,
  InvoiceItem,
  InvoiceStatus,
  Product,
  Subscription,
} from "./types";

export type StripeSearchType = "invoice" | "product" | "customer" | "coupon";

export type StripeSearchFilters = Record<string, string>;

export type StripeSearchOptions = {
  cursor?: string;
  filters?: StripeSearchFilters;
};

type SearchStripeResponse<T> = {
  data: T[];
  cursor: string;
};

let currentController: AbortController | null | undefined;
let currentRequest: Promise<SearchStripeResponse<any>> | null | undefined;

export async function searchStripe<T>(
  query?: string,
  options?: StripeSearchOptions & {
    type?: StripeSearchType;
   }
): Promise<SearchStripeResponse<T>> {
  if (currentController) {
    currentController.abort();
  }

  currentController = new AbortController();
  const { signal } = currentController;

  const url = Routes.partners_stripe_admins_search_path({
    query,
    ...options,
  });

  currentRequest = fetch(url, { signal })
    .then(async (response) => {
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${errorText}`);
      }
      return response.json() as Promise<SearchStripeResponse<T>>;
    })
    .catch((error) => {
      if (error.name === 'AbortError') {
        console.warn('Fetch aborted');
      } else {
        console.error('Fetch error:', error);
      }
      throw error;
    })
    .finally(() => {
      currentController = null;
      currentRequest = null;
    });

  return currentRequest;
}

export function invoiceItemFieldsFromProduct(
  product: Product
): Pick<
  InvoiceItem,
  "product" | "description" | "unit_amount_in_pounds" | "price" | "prices"
> {
  const default_price = product.default_price || product.prices[0];

  return {
    product,
    description: product.name || "",
    unit_amount_in_pounds: toPounds(default_price.unit_amount),
    price: default_price.id,
    prices: product.prices,
  };
}

export function formatBillingPeriodForSubscription(
  subscription: Subscription
): string | null {
  if (
    !subscription?.current_period_end_on ||
    !subscription?.current_period_start_on
  ) {
    return null;
  }

  const start_on = formatISODateTime(
    subscription.current_period_start_on,
    "short-month-name-date"
  );
  const end_on = formatISODateTime(
    subscription.current_period_end_on,
    "short-month-name-date"
  );

  return `${start_on} to ${end_on}`;
}

export function formatIntervalForSubscripiton(
  subscription: Subscription
): string {
  if (!subscription?.plan) {
    return null;
  }

  const price = formatPence(subscription.plan.amount);

  if (subscription.plan.interval_count === 1) {
    return `${price} every ${subscription.plan.interval}`;
  }

  return `${price} every ${subscription.plan.interval_count} ${subscription.plan.interval}s`;
}

export function hasStripeStatus(
  invoice: Invoice,
  statuses: string | Array<InvoiceStatus>
): boolean {
  return [statuses].flat().includes(invoice.status);
}
