import {
  Autocomplete,
  Badge,
  Button,
  ButtonCheckbox,
  ButtonGroup,
  Checkbox,
  Dropdown,
  DropdownContent,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  ErrorSummary,
  IconClipboard,
  IconExternalLink,
  IconPencil,
  IconTrash,
  TextInput,
  Tooltip,
  IconExclamation,
  Textarea,
} from "@herohealthsoftware/ui";
import Fuse from "fuse.js";
import _ from "lodash";
import React, { Suspense, useState } from "react";
import { Await } from "react-router-dom";
import * as Yup from "yup";
import "../../../assets/images/logos/econsult-logo.svg";
import { translate } from "../../lib/i18n";
import type {
  CareNavigationPartner,
  CareNavigationPathway,
  CareNavigationSetting,
  CareNavigationSubmissionType,
  TypeOfSubmission,
} from "./Pathway";
import PartnerSubmission from "./PartnerSubmission";
import ExternalAppOptions from "./ExternalAppOptions";
import parse from "html-react-parser";

type PathwayOptionProps = {
  index: number;
  setting: CareNavigationSetting;
  submissionType: CareNavigationSubmissionType;
  pathway: CareNavigationPathway;
  forms: { value: string; label: string }[];
  onOpenClick: (sub: CareNavigationSubmissionType, index: number) => void;
  onSaveClick: (sub: CareNavigationSubmissionType, index: number) => void;
  onRemoveClick: (sub: CareNavigationSubmissionType, index: number) => void;
  onCancelClick: (sub: CareNavigationSubmissionType, index: number) => void;
  onCloseAllClick: () => void;
  onAddAnotherClick: (partnerAndTypeOfSubmission: {
    partner: CareNavigationPartner;
    type: TypeOfSubmission;
  }) => void;
  allClosed: boolean;
  practiceGroupId: string;
  isPathwaySuspended?: boolean;
  createFormPath?: string;
  careNavPartners?: CareNavigationPartner[];
};

const submissionFormSchema = Yup.object({
  form_id: Yup.string().required(
    translate("careNavigation.pathway.formRequiredError")
  ),
  out_of_hours: Yup.boolean().when("in_hours", {
    is: false,
    then: (schema) =>
      schema.isTrue(translate("careNavigation.pathway.inOrOutOfHoursError")),
  }),
});

const submissionUrlSchema = Yup.object({
  external_url: Yup.string()
    .required(translate("careNavigation.pathway.urlWebsiteRequiredError"))
    .url(translate("careNavigation.pathway.urlWebsiteInvalidError")),
  out_of_hours: Yup.boolean().when("in_hours", {
    is: false,
    then: (schema) =>
      schema.isTrue(translate("careNavigation.pathway.inOrOutOfHoursError")),
  }),
});

const validateSubmission = async (submission, sub, setSubmissionErrors) => {
  let validationSchema;

  if (submission.type === "form" || submission.type === undefined) {
    validationSchema = submissionFormSchema;
  } else if (submission.type === "website" || submission.type === "partner") {
    validationSchema = submissionUrlSchema;
  }

  if (validationSchema) {
    try {
      await validationSchema.validate(sub || {}, { abortEarly: false });
      return {};
    } catch ({ inner }) {
      const errors = inner.reduce(
        (memo, { path, message }) => ({
          ...memo,
          [path]: (memo[path] || []).concat(message),
        }),
        {}
      );
      setSubmissionErrors(errors);
      return errors;
    }
  }

  return {};
};

export default function PathwayOption(props: PathwayOptionProps) {
  const {
    index,
    forms,
    submissionType,
    allClosed,
    isPathwaySuspended,
    onOpenClick,
    onRemoveClick,
    careNavPartners,
  } = props;

  const showFields = submissionType.show || false;
  const formNames = {};
  forms.forEach((form) => {
    formNames[form.value] = form.label;
  });

  const setSubmissionTitle = function (sub) {
    if (sub.type === "form") {
      sub.title = formNames[sub.form_id];
    } else if (sub.type === "website" || sub.type === "partner") {
      sub.title = sub.external_url_name || sub.external_url;
    }
  };

  const [tempSubmissionType, setTempSubmissionType] =
    useState<null | CareNavigationSubmissionType>(submissionType);
  const [submissionErrors, setSubmissionErrors] = useState({});
  const [selectKey, setSelectKey] = useState(
    Math.random().toString(36).substring(7)
  );

  const regenSelectKey = function () {
    setSelectKey(Math.random().toString(36).substring(7));
  };

  const onSaveClick = async function (
    submissionType?: CareNavigationSubmissionType
  ) {
    const sub = { ...submissionType, new: false, show: false };
    const valid = await validateSubmission(
      submissionType,
      sub,
      setSubmissionErrors
    );

    if (!_.isEmpty(valid)) {
      return valid;
    }

    setSubmissionTitle(sub);
    setSubmissionErrors({});
    setTempSubmissionType(sub);
    props.onSaveClick(sub, index);
  };

  const displayError = (errorMessage) => {
    if (errorMessage) {
      setSubmissionErrors({
        form_id: [errorMessage],
      });
    } else {
      setSubmissionErrors({});
    }
  };

  const onAddAnotherClick = async function (
    submissionType: CareNavigationSubmissionType,
    partnerAndTypeOfSubmission: {
      type: TypeOfSubmission;
      partner: CareNavigationPartner;
    }
  ) {
    const valid = await onSaveClick(submissionType);

    if (!_.isEmpty(valid)) {
      return valid;
    }

    props.onAddAnotherClick(partnerAndTypeOfSubmission);
  };

  const buildErrorMessages = function (errs) {
    if (_.isEmpty(errs)) {
      return [];
    }

    return Object.keys(errs).map(function (key) {
      return { message: errs[key] };
    });
  };

  return (
    <>
      {submissionType._destroy === undefined && (
        <>
          <div
            className={
              showFields
                ? "hidden"
                : `border-b p-4 flex justify-between ${
                    !allClosed && `opacity-50`
                  }`
            }
          >
            <div className="col-span-2 flex gap-2">
              <div>
                <div
                  className={`bg-hero-blue-100 rounded-lg flex-col justify-center items-center gap-2.5 inline-flex ${
                    tempSubmissionType.type != "partner" && "w-12 h-12 p-2.5"
                  }`}
                >
                  {(tempSubmissionType.type === "form" ||
                    tempSubmissionType.type === undefined) && <IconClipboard />}
                  {tempSubmissionType.type === "partner" && (
                    <i className={tempSubmissionType.partner.icon_class} />
                  )}
                  {tempSubmissionType.type === "website" && <IconExternalLink />}
                </div>
              </div>
              <div className="flex flex-col">
                {tempSubmissionType.title && (
                  <>
                    <div className="text-base leading-6 font-bold">
                      {parse(tempSubmissionType.title)}
                    </div>
                    <div className="text-hero-blue-400 font-medium leading-6">
                      {tempSubmissionType.summary && parse(tempSubmissionType.summary)}
                    </div>
                    <input
                      type="hidden"
                      value={tempSubmissionType?.title}
                      name={`care_nav_pathway[care_nav_submission_types_attributes][${index}][title]`}
                      id={`care_nav_pathway_care_nav_submission_types_attributes_${index}_title`}
                    />
                  </>
                )}
                <div className="flex gap-1.5">
                  {tempSubmissionType?.in_hours && (
                    <div className="status-bubble">
                      {translate("careNavigation.pathway.inHours")}
                    </div>
                  )}
                  {tempSubmissionType?.out_of_hours && (
                    <div className="status-bubble">
                      {translate("careNavigation.pathway.outOfHours")}
                    </div>
                  )}
                  {(isPathwaySuspended || tempSubmissionType?.suspended) && (
                    <div className="status-bubble status-bubble-yellow flex gap-1 items-center">
                      <div className="w-4 h-4 [&_path]:fill-yellow-500">
                        <IconExclamation />
                      </div>
                      {translate("careNavigation.pathway.accessSuspended")}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-span-1 flex justify-end items-center px-2 whitespace-nowrap">
              {isPathwaySuspended ? (
                <Tooltip content="Disabled due to pathway being suspended">
                  <div className="opacity-50 px-3 py-2 inline-flex gap-1.5 items-center text-sm leading-4 font-semibold border border-hero-blue-300 rounded-md cursor-not-allowed">
                    <Checkbox disabled checked={false}></Checkbox>
                    {translate("careNavigation.pathway.suspendAccess")}
                  </div>
                </Tooltip>
              ) : (
                <ButtonCheckbox
                  variant="white"
                  id={`care_nav_pathway_care_nav_submission_types_attributes_${index}_suspended_visible`}
                  checked={tempSubmissionType?.suspended}
                  disabled={isPathwaySuspended}
                  onChange={(ev) => {
                    if (tempSubmissionType.type === "form") {
                      if (tempSubmissionType.form_id) {
                        setTempSubmissionType({
                          ...tempSubmissionType,
                          suspended: ev.currentTarget.checked,
                        });
                      } else {
                        ev.preventDefault();
                        onOpenClick(tempSubmissionType, index);
                        displayError(
                          translate("careNavigation.pathway.formRequiredError")
                        );
                      }
                    } else if (
                      tempSubmissionType.type === "website" ||
                      tempSubmissionType.type === "partner"
                    ) {
                      setTempSubmissionType({
                        ...tempSubmissionType,
                        suspended: ev.currentTarget.checked,
                      });
                    }
                  }}
                >
                  {translate("careNavigation.pathway.suspendAccess")}
                </ButtonCheckbox>
              )}

              <input
                type="hidden"
                value={`${tempSubmissionType?.suspended}`}
                name={`care_nav_pathway[care_nav_submission_types_attributes][${index}][suspended]`}
                id={`care_nav_pathway_care_nav_submission_types_attributes_${index}_suspended`}
              />

              <ButtonGroup className="ml-2">
                <React.Fragment key=".0">
                  <Tooltip content="Edit">
                    <Button
                      icon
                      variant="white"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onOpenClick(tempSubmissionType, index);
                      }}
                      disabled={!allClosed}
                    >
                      <IconPencil />
                    </Button>
                  </Tooltip>
                  <Tooltip content="Delete">
                    <Button
                      icon
                      variant="destructive"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onRemoveClick(submissionType, index);
                      }}
                      disabled={!allClosed}
                    >
                      <IconTrash />
                    </Button>
                  </Tooltip>
                </React.Fragment>
              </ButtonGroup>
            </div>
          </div>

          <div
            className={`flex flex-col text-hero-blue-700 text-sm mb-2 p-4 ${
              showFields ? "" : "hidden"
            }`}
          >
            {submissionErrors && (
              <div className="mb-3">
                <ErrorSummary items={buildErrorMessages(submissionErrors)} />
              </div>
            )}

            {(tempSubmissionType.type === "form" ||
              tempSubmissionType.type === undefined) && (
              <>
                <label
                  className="text-hero-blue-700 text-sm font-bold mb-2"
                  htmlFor={`care_nav_pathway_care_nav_submission_types_attributes_${index}_form_id`}
                >
                  {translate("careNavigation.pathway.request")}
                </label>
                <Suspense
                  fallback={
                    <Autocomplete
                      isLoading={true}
                      isDisabled={true}
                      placeholder={translate(
                        "careNavigation.pathway.loadingForms"
                      )}
                    />
                  }
                >
                  <Await resolve={forms}>
                    {(resolvedForms: { value: string; label: string }[]) => {
                      const searcher = new Fuse(resolvedForms, {
                        keys: ["label"],
                      });
                      return (
                        <Autocomplete
                          key={selectKey}
                          id="form_id"
                          defaultValue={forms.filter((form) => {
                            return form.value === tempSubmissionType.form_id;
                          })}
                          placeholder={translate(
                            "careNavigation.pathway.formPlaceholder"
                          )}
                          defaultOptions={resolvedForms}
                          optionsFooter={
                            <>
                              <div className="border-t border-hero-blue-200 px-3 py-1.5 text-sm text-hero-blue-700 cursor-pointer hover:bg-hero-blue-50 focus:bg-hero-blue-50 group-[.hh-ui-autocomplete-option--focused]:bg-hero-blue-50">
                                <a
                                  rel="nofollow"
                                  data-method="post"
                                  href={props.createFormPath}
                                >
                                  {translate(
                                    "careNavigation.pathway.createForm"
                                  )}
                                </a>
                              </div>
                            </>
                          }
                          isClearable
                          onChange={(target: {
                            value: string;
                            label: string;
                          }) => {
                            setTempSubmissionType({
                              ...tempSubmissionType,
                              form_id: target ? target.value : null,
                            });
                          }}
                          fetcher={(value, cb) => {
                            const results = searcher.search(value);

                            if (results?.length > 0) {
                              cb(results.map((result) => result.item));
                            }
                            cb(
                              resolvedForms.filter((form) => {
                                form.label
                                  ?.toLowerCase()
                                  .includes?.(value.toLowerCase());
                              })
                            );
                          }}
                        />
                      );
                    }}
                  </Await>
                </Suspense>

                <input
                  type="hidden"
                  value={`${tempSubmissionType.form_id}`}
                  name={`care_nav_pathway[care_nav_submission_types_attributes][${index}][form_id]`}
                />
              </>
            )}

            {tempSubmissionType.type === "website" && (
              <>
                <div className="flex-row justify-between gap-2">
                  <div className="w-full">
                    <label
                      className="text-hero-blue-700 text-sm font-bold mb-2"
                      htmlFor={`care_nav_pathway_care_nav_submission_types_attributes_${index}_external_url`}
                    >
                      {translate("careNavigation.pathway.urlWebsite")}
                    </label>
                    <TextInput
                      defaultValue={tempSubmissionType.external_url}
                      error={
                        submissionErrors && submissionErrors["external_url"]
                      }
                      placeholder={translate(
                        "careNavigation.pathway.urlWebsitePlaceholder"
                      )}
                      size="md"
                      name={`care_nav_pathway[care_nav_submission_types_attributes][${index}][external_url]`}
                      id={`care_nav_pathway_care_nav_submission_types_attributes_${index}_external_url`}
                      onChange={(ev) => {
                        setTempSubmissionType({
                          ...tempSubmissionType,
                          external_url: ev.currentTarget.value,
                        });
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      className="text-hero-blue-700 text-sm font-bold mb-[7px]"
                      htmlFor={`care_nav_pathway_care_nav_submission_types_attributes_${index}_external_url`}
                    >
                      {translate("careNavigation.pathway.name")}
                      <Badge
                        colour="neutral"
                        size="sm"
                        variant="custom"
                        className="font-medium ml-2"
                      >
                        {translate("careNavigation.pathway.optional")}
                      </Badge>
                    </label>
                    <TextInput
                      defaultValue={tempSubmissionType.external_url_name}
                      error={
                        submissionErrors &&
                        submissionErrors["external_url_name"]
                      }
                      placeholder={translate(
                        "careNavigation.pathway.urlWebsiteNamePlaceholder"
                      )}
                      size="md"
                      name={`care_nav_pathway[care_nav_submission_types_attributes][${index}][external_url_name]`}
                      id={`care_nav_pathway_care_nav_submission_types_attributes_${index}_external_url_name`}
                      onChange={(ev) => {
                        setTempSubmissionType({
                          ...tempSubmissionType,
                          external_url_name: ev.currentTarget.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            {tempSubmissionType.type === "partner" && (
              <>
                <PartnerSubmission
                  tempSubmissionType={tempSubmissionType}
                  submissionErrors={submissionErrors}
                  index={index}
                  setTempSubmissionType={setTempSubmissionType}
                />
              </>
            )}

            <div className="w-full mt-4">
              <label
                className="text-hero-blue-700 text-sm font-bold mb-[7px]"
                htmlFor={`care_nav_pathway_care_nav_submission_types_attributes_${index}_summary`}
              >
                {translate("careNavigation.pathway.description")}
                <Badge
                  colour="neutral"
                  size="sm"
                  variant="custom"
                  className="font-medium ml-2"
                >
                  {translate("careNavigation.pathway.optional")}
                </Badge>
              </label>
              <Textarea
                defaultValue={tempSubmissionType.summary}
                error={
                  submissionErrors &&
                  submissionErrors["summary"]
                }
                name={`care_nav_pathway[care_nav_submission_types_attributes][${index}][summary]`}
                id={`care_nav_pathway_care_nav_submission_types_attributes_${index}_summary`}
                onChange={(ev) => {
                  setTempSubmissionType({
                    ...tempSubmissionType,
                    summary: ev.currentTarget.value,
                  });
                }}
              />
            </div>

            <div className="flex flex-col mt-3">
              <div className="text-hero-blue-700 text-sm font-bold mr-5">
                {translate("careNavigation.pathway.patientAccess")}
              </div>
              <div className="text-her-blue-700 text-sm">
                {translate("careNavigation.pathway.patientAccessSubheading")}
              </div>
              <div className="flex flex-row mt-2 mb-5">
                <div className="my-auto">
                  <Checkbox
                    value="1"
                    id={`care_nav_pathway_care_nav_submission_types_attributes_${index}_in_hours_visible`}
                    checked={tempSubmissionType?.in_hours}
                    onChange={(ev) => {
                      setTempSubmissionType({
                        ...tempSubmissionType,
                        in_hours: ev.currentTarget.checked,
                      });
                    }}
                  />
                </div>
                <input
                  type="hidden"
                  value={`${tempSubmissionType.in_hours}`}
                  name={`care_nav_pathway[care_nav_submission_types_attributes][${index}][in_hours]`}
                />

                <label
                  className="text-hero-blue-700 text-sm font-bold mr-5 ml-2"
                  htmlFor={`care_nav_pathway_care_nav_submission_types_attributes_${index}_in_hours_visible`}
                >
                  {translate("careNavigation.pathway.inHours")}
                </label>

                <div className="my-auto">
                  <Checkbox
                    value="1"
                    id={`care_nav_pathway_care_nav_submission_types_attributes_${index}_out_of_hours_visible`}
                    checked={tempSubmissionType?.out_of_hours}
                    onChange={(ev) => {
                      setTempSubmissionType({
                        ...tempSubmissionType,
                        out_of_hours: ev.currentTarget.checked,
                      });
                    }}
                  />
                </div>

                <input
                  type="hidden"
                  value={`${tempSubmissionType.out_of_hours}`}
                  name={`care_nav_pathway[care_nav_submission_types_attributes][${index}][out_of_hours]`}
                  id={`care_nav_pathway_care_nav_submission_types_attributes_${index}_out_of_hours`}
                />

                <label
                  className="text-hero-blue-700 text-sm font-bold ml-2"
                  htmlFor={`care_nav_pathway_care_nav_submission_types_attributes_${index}_out_of_hours_visible`}
                >
                  {translate("careNavigation.pathway.outOfHours")}
                </label>

                <input
                  className="mr-2"
                  type="hidden"
                  value={props.practiceGroupId}
                  name={`care_nav_pathway[care_nav_submission_types_attributes][${index}][practice_group_id]`}
                  id={`care_nav_pathway_care_nav_submission_types_attributes_${index}_practice_group_id`}
                />
              </div>
              {
                tempSubmissionType.type === "form" && (
                  <div className="mt-3 flex items-start">
                    <div>
                      <Checkbox
                        value="1"
                        id={`care_nav_pathway_care_nav_submission_types_attributes_${index}_patient_eligibility_check_visible`}
                        checked={tempSubmissionType?.patient_eligibility_check}
                        onChange={(ev) => {
                          setTempSubmissionType({
                            ...tempSubmissionType,
                            patient_eligibility_check: ev.currentTarget.checked,
                          });
                        }}
                      />
                    </div>

                    <input
                      type="hidden"
                      value={`${!!tempSubmissionType.patient_eligibility_check}`}
                      name={`care_nav_pathway[care_nav_submission_types_attributes][${index}][patient_eligibility_check]`}
                      id={`care_nav_pathway_care_nav_submission_types_attributes_${index}_patient_eligibility_check`}
                    />

                    <div className="ml-2">
                      <label
                        className="text-hero-blue-700 text-sm font-bold"
                        htmlFor={`care_nav_pathway_care_nav_submission_types_attributes_${index}_patient_eligibility_check_visible`}
                      >
                        {translate("careNavigation.pathway.patientEligibilityCheck")}
                      </label>
                      <p className="text-sm text-hero-blue-400">
                        {translate("careNavigation.pathway.patientEligibilityCheckSubtext")}
                      </p>
                    </div>
                  </div>
                )
              }
            </div>
          </div>

          <div
            className={`flex justify-end space-x-3 align-middle pb-4 px-4 ${
              showFields ? "" : "hidden"
            }`}
          >
            <button
              className={`border-b border-hero-primary-700 text-hero-primary-700 font-bold my-auto ${
                showFields ? "" : "hidden"
              }`}
              onClick={(ev) => {
                ev.preventDefault();
                regenSelectKey();
                props.onCancelClick(submissionType, index);
                setTempSubmissionType(submissionType);
              }}
            >
              {translate("careNavigation.pathway.cancel")}
            </button>
            <div className={showFields ? "" : "hidden"}>
              <Dropdown>
                <DropdownTrigger asChild>
                  <Button
                    variant="white"
                    prepend={<span>&#43;</span>}
                    size="md"
                  >
                    {translate("careNavigation.pathway.saveAndAddAnother")}
                  </Button>
                </DropdownTrigger>
                <DropdownContent align="start">
                  <DropdownMenu>
                    <div className="max-h-72 overflow-y-scroll external-apps-container">
                      <div>
                        <div className="flex flex-col">
                          <DropdownMenuItem
                            onClick={() => {
                              onAddAnotherClick(tempSubmissionType, {
                                type: "form",
                                partner: null,
                              });
                            }}
                          >
                            {translate("careNavigation.pathway.request")}
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={() => {
                              onAddAnotherClick(tempSubmissionType, {
                                type: "website",
                                partner: null,
                              });
                            }}
                          >
                            {translate("careNavigation.pathway.urlWebsite")}
                          </DropdownMenuItem>
                          {careNavPartners && (
                            <>
                              <div className="w-full border-b" />
                              <div className="text-xs leading-4 font-medium uppercase py-2 px-2 text-hero-blue-500">
                                {translate(
                                  "careNavigation.pathway.externalApps"
                                )}
                              </div>
                            </>
                          )}
                          <ExternalAppOptions
                            onSaveAndAddAnotherClick={onAddAnotherClick}
                            tempSubmissionType={tempSubmissionType}
                            saveAndAdd={true}
                            careNavPartners={careNavPartners}
                          />
                        </div>
                      </div>
                    </div>
                  </DropdownMenu>
                </DropdownContent>
              </Dropdown>
            </div>

            <Button
              as="button"
              size="md"
              variant="primary"
              className={showFields ? "" : "hidden"}
              onClick={(ev) => {
                ev.preventDefault();
                onSaveClick(tempSubmissionType);
              }}
            >
              {translate("careNavigation.pathway.saveToPathway")}
            </Button>
          </div>
        </>
      )}
      {tempSubmissionType.id && (
        <input
          className="mr-2"
          type="hidden"
          value={tempSubmissionType.id}
          name={`care_nav_pathway[care_nav_submission_types_attributes][${index}][id]`}
          id={`care_nav_pathway_care_nav_submission_types_attributes_${index}_id`}
        />
      )}

      {submissionType._destroy && (
        <input
          className="mr-2"
          type="hidden"
          value={`${submissionType._destroy}`}
          name={`care_nav_pathway[care_nav_submission_types_attributes][${index}][_destroy]`}
          id={`care_nav_pathway_care_nav_submission_types_attributes_${index}__destroy`}
        />
      )}
    </>
  );
}
