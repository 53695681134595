import React from "react";

import { formatISODateTime } from "../../../lib/datetime";
import { translate } from "../../../lib/i18n";
import { formatPence } from "../../../lib/money";
import StatusBadge from "./StatusBadge";
import { Invoice } from "../../../lib/types";
import PaginationButtons, { PaginationButtonsProps } from "./PaginationButtons";
import { Spinner } from "@herohealthsoftware/ui";
import InvoiceListItem from "./InvoiceListItem";

type InvoicesListProps = {
  invoices: Invoice[];
  pagination: PaginationButtonsProps;
  loading: boolean;
  error: string | null;
  activeSection: string;
  handleInvoiceClick: (invoice: Invoice) => void;
};

export default function InvoicesList(props: InvoicesListProps) {
  if (props.error) {
    return (
      <div
        className="w-full flex flex-col items-center justify-center
                border border-hero-blue-200 rounded p-6"
      >
        <div className="font-medium">{props.error}</div>
      </div>
    );
  }

  if (props.loading) {
    return (
      <div
        className="w-full flex flex-col items-center justify-center
                border border-hero-blue-200 rounded p-6"
      >
        <div className="h-8 w-8">
          <Spinner />
        </div>

        <div className="mt-2 font-medium">
          {translate("partners.stripe.loadingStripeInvoices")}
          <>&hellip;</>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="border border-hero-blue-200 rounded-lg">
        {props.invoices.length < 1 ? (
          <div
            className="flex items-center justify-center font-semibold
                text-hero-blue-700 py-6"
          >
            {props.activeSection === "appointment"
              ? translate("partners.stripe.thisAppointmentHasNoInvoices")
              : translate("partners.stripe.thisPatientHasNoInvoices")}
          </div>
        ) : (
          props.invoices.map((invoice) => (
            <InvoiceListItem
              key={invoice.id}
              invoice={invoice}
              handleInvoiceClick={props.handleInvoiceClick}
            />
          ))
        )}
      </div>

      <div className="py-4">
        <PaginationButtons {...props.pagination} />
      </div>
    </>
  );
}
