import { ApiResponse, AdminRecord } from '../lib/types';

const headers = {
  'Content-Type': 'application/json',
};

async function handleFetch(url: string, options: RequestInit): Promise<{ error: boolean; data: ApiResponse | null }> {
  try {
    const response = await fetch(url, options);

    if (response.ok) {
      const data = await response.json();
      return { error: false, data };
    } else {
      const errorData = await response.json();
      return { error: true, data: errorData };
    }
  } catch (error) {
    return { error: true, data: null };
  }
}

export const fetchAssociatedMembersData = async (
  url: string,
): Promise<{ error: boolean; data: ApiResponse | null }> => {

  return handleFetch(url, { method: 'GET' });
};

export const fetchTaskCountData = async (
  url: string,
): Promise<{ error: boolean; data: ApiResponse | null }> => {

  return handleFetch(url, { method: 'GET' });
};

export const getPageChange = async (
  newPage: number,
  url: string
): Promise<{ error: boolean; data: ApiResponse | null }> => {
  return handleFetch(`${url}?page=${newPage}`, { method: 'GET' });
};

export const removeRecord = async (
  url: string,
  csrfToken: string
): Promise<{ error: boolean; data: ApiResponse | null }> => {

  return handleFetch(url, {
    method: 'DELETE',
    headers: {
      ...headers,
      'X-CSRF-Token': csrfToken,
    },
  });
};

export const createRecord = async (
  url: string,
  selectedAdmin: { name: string, snomed_code?: string },
  practice_group_id: number,
  csrfToken: string,
  selectedMembers?: AdminRecord[]
): Promise<{ error: boolean; data: ApiResponse | null }> => {

  return handleFetch(url, {
    method: 'POST',
    headers: {
      ...headers,
      'X-CSRF-Token': csrfToken,
    },
    body: JSON.stringify({
      pg_item: {
        name: selectedAdmin?.name,
        snomed_code: selectedAdmin?.snomed_code,
        practice_group_id: practice_group_id,
        admin_ids: selectedMembers?.map(member => member.id),
      },
    }),
  });
};

export const updateRecord = async (
  url: string,
  selectedAdmin: { id: number, name: string, snomed_code?: string },
  practice_group_id: number,
  csrfToken: string,
  selectedMembers?: AdminRecord[]
): Promise<{ error: boolean; data: ApiResponse | null }> => {

  return handleFetch(url, {
    method: 'PATCH',
    headers: {
      ...headers,
      'X-CSRF-Token': csrfToken,
    },
    body: JSON.stringify({
      pg_item: {
        name: selectedAdmin?.name,
        snomed_code: selectedAdmin?.snomed_code,
        practice_group_id: practice_group_id,
        admin_ids: selectedMembers?.map(member => member.id),
      },
    }),
  });
};

export const fetchRecords = async (
  url: string
): Promise<{ error: boolean; data: ApiResponse | null }> => {
  return handleFetch(`${url}?page=1`, { method: 'GET' });
};
