import { ErrorSummary, Product, Typography } from "@herohealthsoftware/ui";
import React, { useEffect, useState } from "react";
import Hero from "../../../../lib/hero";
import { translate } from "../../../../lib/i18n";
import { MembershipScheme, StripeSettings } from "../../../../lib/types";
import * as Routes from "../../../../routes";
import IntegrationSidebar from "../../../shared/IntegrationSidebar";
import ProductInput from "../form/ProductInput";

type MembershipSchemeSidebarProps = {
  membershipScheme: Partial<MembershipScheme>;
  onClose: (reload: boolean) => void;
  settings: StripeSettings;
};

export default function MembershipSchemeSidebar(
  props: MembershipSchemeSidebarProps
) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Array<{ message: string }>>([]);

  const handleSaveMembershipScheme = async (
    membershipScheme: Partial<MembershipScheme>
  ) => {
    setLoading(true);
    setErrors([]);

    const isPersisted = !!props.membershipScheme.id;
    const product = membershipScheme.stripe_product;
    const interval =
      product &&
      { month: "monthly", year: "yearly" }[
        product.default_price?.recurring?.interval
      ];
    const validationErrors = [];

    if (!isPersisted && !product) {
      validationErrors.push(translate("partners.stripe.productRequired"));
    }

    if (product) {
      if (!product.default_price) {
        validationErrors.push(translate("partners.stripe.priceRequired"));
      }

      if (!interval || product.default_price.recurring.interval_count !== 1) {
        validationErrors.push(translate("partners.stripe.invalidInterval"));
      }
    }

    if (validationErrors.length > 0) {
      setErrors(validationErrors.map((error) => ({ message: error })));
      setLoading(false);
      return;
    }

    if (product) {
      membershipScheme.name = product.name;
      membershipScheme.description = product.description;
      membershipScheme.price = product.default_price?.unit_amount;
      membershipScheme.length = interval;
    }

    try {
      const path = isPersisted
        ? Routes.partners_stripe_admins_membership_scheme_path(
            membershipScheme.id
          )
        : Routes.partners_stripe_admins_membership_schemes_path();

      const response = await Hero.fetch(path, {
        method: isPersisted ? "PATCH" : "POST",
        body: { membership_scheme: membershipScheme },
      });
      const data = await response.json();

      if (response.ok) {
        props.onClose(true);
      } else {
        if (data.errors) {
          setErrors(
            data.errors.map((error: string) => ({ message: `${error}.` }))
          );
        } else {
          setErrors([{ message: translate("base.unexpectedError") }]);
        }
      }
    } catch (error) {
      console.error(error);
      setErrors([{ message: translate("base.unexpectedError") }]);
    } finally {
      setLoading(false);
    }
  };

  const handleProductSave = async (
    product: Product | null,
    create: boolean
  ) => {
    await handleSaveMembershipScheme({
      ...props.membershipScheme,
      stripe_product: product && { create, ...product },
    });
  };

  const handleResetMembershipScheme = () => {
    setErrors([]);
    setLoading(false);
  };

  const editable = props.membershipScheme.active_policies_count === 0;
  const isPersisted = !!props.membershipScheme.id;

  useEffect(handleResetMembershipScheme, [props.membershipScheme]);

  return (
    <IntegrationSidebar
      name={translate("partners.stripe.stripe")}
      logo={props.settings.logo}
      position="fixed"
      onClose={() => props.onClose(false)}
    >
      <div className="w-[600px] px-6 py-5 flex flex-col gap-6">
        <div className="flex flex-col gap-1">
          <Typography color="primary" weight="bold" size="xl">
            <h3>
              {isPersisted
                ? props.membershipScheme.name
                : translate("partners.stripe.newScheme")}
            </h3>
          </Typography>

          <Typography color="secondary" size="base">
            <p>
              {isPersisted
                ? props.membershipScheme.description ||
                  translate("partners.stripe.noDescription")
                : translate("partners.stripe.newSchemeHelp")}
            </p>
          </Typography>
        </div>

        <ErrorSummary items={errors} />

        <ProductInput
          product={(props.membershipScheme.stripe_product as Product) || null}
          editable={editable}
          onSave={handleProductSave}
          loading={loading}
        />
      </div>
    </IntegrationSidebar>
  );
}
