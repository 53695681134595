import { translate } from '../../lib/i18n';
  
export const createFlashContainer = (content: string, backgroundColor: string, textColor: string, onClose: () => void) => {
  const container = document.createElement('div');
  container.classList.add('teams_flash', 'flex', 'justify-between', 'items-center', backgroundColor, textColor, 'px-2', 'py-3');
  container.innerHTML = `<p class="w-full flex justify-content-center text-base"><span class="flex items-center gap-1.5">${content}</span></p>`;

  const closeButton = document.createElement('button');
  closeButton.classList.add('px-2');
  closeButton.innerHTML = '✖';

  closeButton.addEventListener('click', onClose);
  container.appendChild(closeButton);

  return container;
};

export const displayFlashMessage = (adminTeam: string, teamParentContainer, flashMessageContent, checkRemove: boolean) => {
  const content = `<b>${adminTeam}</b>${checkRemove ? translate('common.removeMessage') : flashMessageContent}`;
  const flashContainer = createFlashContainer(content, 'bg-hero-primary-500', 'text-hero-blue-700', () => flashContainer.remove());

  removeExistingFlash(teamParentContainer);
  teamParentContainer.prepend(flashContainer);
};

export const displayErrorMessage = (errorMessage: string, teamParentContainer) => {
  const errorContainer = createFlashContainer(errorMessage, 'bg-red-500', 'text-white', () => errorContainer.remove());

  removeExistingFlash(teamParentContainer);
  teamParentContainer.prepend(errorContainer);
};

export const removeExistingFlash = (teamParentContainer) => {
  const existingFlash = teamParentContainer.querySelector('.teams_flash');
  if (existingFlash) {
    existingFlash.remove();
  }
}
