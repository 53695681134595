import {
  Button,
  Checkbox,
  Dropdown,
  DropdownContent,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  ErrorSummary,
  IconX,
  TextInput,
  ThemeProvider,
} from "@herohealthsoftware/ui";
import _ from "lodash";
import React, { useState } from "react";
import * as Yup from "yup";
import { translate } from "../../lib/i18n";
import PathwayOption from "./PathwayOption";
import ExternalAppOptions from "./ExternalAppOptions";
import parse from "html-react-parser";

export type CareNavigationSetting = {
  id: string;
  practice_group_id: string;
  in_hours: boolean;
  suspended: boolean;
};

export type TypeOfSubmission = "form" | "website" | "partner";

export type CareNavigationPartner = {
  id?: string;
  name?: string;
  label?: string;
  external_url?: string;
  external_url_name?: string;
  placeholder?: string;
  icon_class?: string;
};

export type CareNavigationPathway = {
  id: string;
  practice_group_id: string;
  title: string | null;
  summary: string | null;
  oc_submission_type: number;
  enabled: boolean;
  in_hours: boolean;
  out_of_hours: boolean;
  suspended: boolean;
};

export type CareNavigationSubmissionType = {
  id?: string;
  practice_group_id?: string;
  care_nav_pathway_id?: string;
  form_id?: string | null;
  partner_id?: string | null;
  short_link_id?: string | null;
  title?: string | null;
  summary?: string | null;
  internal_url?: string | null;
  external_url?: string | null;
  external_url_name?: string | null;
  enabled?: boolean;
  in_hours?: boolean;
  out_of_hours?: boolean;
  suspended?: boolean;
  show?: boolean;
  _destroy?: boolean;
  partner?: CareNavigationPartner | null;
  type?: TypeOfSubmission | null;
  patient_eligibility_check?: boolean;
};

type PathwayProps = {
  setting: CareNavigationSetting;
  pathway: CareNavigationPathway;
  submissionTypes: CareNavigationSubmissionType[];
  forms: { value: string; label: string }[];
  subHeading?: string;
  previousUrl?: string;
  heading?: string;
  submitButtonText?: string;
  practiceGroupId?: string;
  isPathwaySuspended?: boolean;
  createFormPath?: string;
  careNavPartners?: CareNavigationPartner[];
};

const pathwaySchema = Yup.object({
  title: Yup.string().required(
    translate("careNavigation.pathway.pathway_title")
  ),
  summary: Yup.string().nullable(),
  suspended: Yup.boolean(),
});

export default function Pathway(props: PathwayProps) {
  const {
    setting,
    pathway,
    previousUrl,
    subHeading,
    heading,
    careNavPartners,
  } = props;

  const submissionTypeType = (submissionType) => {
    if (submissionType?.type != undefined) {
      return submissionType.type;
    } else if (submissionType?.form_id != undefined) {
      return "form";
    } else if (submissionType?.partner_id != undefined) {
      return "partner";
    } else if (submissionType?.external_url != undefined) {
      return "website";
    }
  };

  const submissionTypePartner = (submissionType) => {
    let partner = null;
    let partner_id = submissionType?.partner_id;
    if (partner_id) {
      const foundPartner = careNavPartners?.find(
        (partner) => partner.id === partner_id
      );
      if (foundPartner) {
        partner = foundPartner;
      }
    }
    return partner;
  };

  const assignPartnerAndType = (submissionType) => {
    submissionType.partner = submissionTypePartner(submissionType);
    submissionType.type = submissionTypeType(submissionType);
    return submissionType;
  };

  const adaptedSubmissionTypes = props.submissionTypes.map((submissionType) => {
    return assignPartnerAndType(submissionType);
  });

  const [submissionTypes, setSubmissionTypes] = useState(
    adaptedSubmissionTypes
  );
  const [tempPathway, setTempPathway] = useState(pathway);
  const [showDropdown, setShowDropdown] = useState(true);

  const forms = props.forms;
  const submitButtonText = props.submitButtonText;

  const allClosedCheck = (subs) => {
    return _.every(subs, (sub) => {
      return !sub.show;
    });
  };

  const [allClosed, setAllClosed] = useState(allClosedCheck(submissionTypes));
  const [errors, setErrors] = useState([]);

  const allDestroyedCheck = (subs) => {
    return _.every(subs, (sub) => {
      return sub._destroy;
    });
  };

  const validatePathway = async (pathway) => {
    try {
      await pathwaySchema.validate(pathway);
    } catch (err) {
      return { message: err.message, field: err.path };
    }
  };

  const validateMultipleSubmissions = (submissionTypes) => {
    if (!submissionTypes.filter((sub) => !sub._destroy).length) {
      return {
        message: translate("careNavigation.pathway.options_to_save"),
        field: "submissions",
      };
    }

    if (
      submissionTypes.some(
        (submissionType) =>
          submissionType.form_id === null &&
          submissionType.external_url === null &&
          submissionType.internal_url === null
      )
    ) {
      const submissionsWithNullFormIdAndUrl = submissionTypes.filter(
        (submissionType) =>
          submissionType.form_id === null &&
          submissionType.external_url === null &&
          submissionType.internal_url === null
      );

      if (submissionsWithNullFormIdAndUrl.length > 0) {
        const titles = submissionsWithNullFormIdAndUrl.map((submissionType) => (
          <li key={submissionType.id} className="text-xs">
            {submissionType.title || "Untitled"}
          </li>
        ));

        return {
          message: (
            <div>
              <div className={"text-xs font-bold"}>
                Form must exist for the following requests:
              </div>
              <ul className="list-disc pl-4 mt-1.5 text-red-700">{titles}</ul>
            </div>
          ),
          field: "submissions",
        };
      }
    }
  };

  const handleAllClosed = (submissionTypes) => {
    const closed = allClosedCheck(submissionTypes);
    setAllClosed(closed);
  };

  const onSubmitClick = async (ev) => {
    const pathwayError = await validatePathway(tempPathway || pathway);
    const submissionsError = validateMultipleSubmissions(submissionTypes);
    setErrors([]);

    if (pathwayError?.message && submissionsError?.message) {
      setErrors([pathwayError, submissionsError]);
    } else if (pathwayError?.message) {
      setErrors([pathwayError]);
    } else if (submissionsError?.message) {
      setErrors([submissionsError]);
    }

    if (pathwayError?.message || submissionsError?.message) {
      ev.preventDefault();
      return;
    }
  };

  const onCancelClick = (submission, index) => {
    let updatedSubmissions = submissionTypes;
    if (submission.new) {
      updatedSubmissions = updatedSubmissions.filter(
        (sub) => sub !== submission
      );
    } else {
      updatedSubmissions[index] = { ...submission, show: false };
    }

    setSubmissionTypes(updatedSubmissions);
    handleAllClosed(updatedSubmissions);
    setShowDropdown(true);
  };

  const onCloseAllClick = () => {
    const updatedSubmissions = submissionTypes.map((submission) => {
      return {
        ...submission,
        show: false,
      };
    });
    setSubmissionTypes(updatedSubmissions);
    handleAllClosed(updatedSubmissions);
    setShowDropdown(true);
  };

  const onOpenClick = function (sub, index) {
    submissionTypes[index] = { ...sub, show: true };
    setSubmissionTypes([...submissionTypes]);
    handleAllClosed([...submissionTypes]);
    setShowDropdown(false);
  };

  const onRemoveClick = function (sub, index) {
    submissionTypes[index] = { _destroy: true, id: sub.id, show: false };
    setSubmissionTypes([...submissionTypes]);
    handleAllClosed([...submissionTypes]);
    setShowDropdown(true);
  };

  const onSaveClick = function (sub, index) {
    submissionTypes[index] = sub;
    setSubmissionTypes([...submissionTypes]);
    handleAllClosed([...submissionTypes]);
    setShowDropdown(true);
  };

  const onAddAnotherClick = function (partnerAndTypeOfSubmission: {
    type: TypeOfSubmission;
    partner: CareNavigationPartner;
  }) {
    const updatedSubmissions = submissionTypes.map((submission) => {
      return {
        ...submission,
        show: false,
      } as CareNavigationSubmissionType;
    });
    const newSubmission = {
      show: true,
      new: true,
      suspended: false,
      out_of_hours: false,
      in_hours: true,
      form_id: null,
      type: partnerAndTypeOfSubmission.type,
      partner: partnerAndTypeOfSubmission.partner,
      title: null,
    } as CareNavigationSubmissionType;
    updatedSubmissions.push(newSubmission);
    setSubmissionTypes(updatedSubmissions);
    const closed = allClosedCheck(updatedSubmissions);
    setAllClosed(closed);
    setShowDropdown(false);
  };

  const someSubmissionTypesExist =
    submissionTypes.filter((sub) => !sub._destroy).length > 0;

  const closeBtnClass = `w-7 h-7 rounded-md mr-2 text-hero-blue-400 hover:text-hero-blue-700 hover:bg-gray-100
  active:ring-2 active:ring-turquoise-focus active:ring-offset-2 active:ring-offset-hero-blue-600
  focus-within:ring-2 focus-within:ring-turquoise-focus focus-within:ring-offset-2 focus-within:ring-offset-hero-blue-600
  data-tooltip-below relative p-1 border border-transparent hover:border-hero-blue-200`;

  const parseString = (value: string) => {
    const parsedResult = (value && parse(value)) || "";
    return parsedResult as string;
  };

  return (
    <ThemeProvider>
      <div
        className={`bg-white border-b border-hero-blue-200 sticky top-0 z-20`}
      >
        <div
          className={`flex items-center justify-between px-10 py-5 ${
            !showDropdown && "opacity-50"
          }`}
        >
          <div className="flex items-center flex-wrap">
            {!showDropdown ? (
              previousUrl && (
                <div className="w-6 h-6 rounded-md leading-none mr-3">
                  <IconX />
                </div>
              )
            ) : (
              <>
                <a
                  href={previousUrl}
                  className={closeBtnClass}
                  data-tooltip={translate("base.close")}
                >
                  <IconX />
                </a>
              </>
            )}

            <div className="flex flex-col">
              {heading && (
                <h1 className="text-2xl leading-8 font-bold m-0">{heading}</h1>
              )}
              {subHeading && (
                <span className="text-sm text-hero-blue-500">{subHeading}</span>
              )}
            </div>
          </div>
          <div className="flex-end">
            <Button
              as="button"
              size="md"
              variant="primary"
              disabled={!showDropdown}
              type="submit"
              name="commit"
              value={submitButtonText}
              className="b b-primary"
              data-disable-with={submitButtonText}
              onClick={(ev) => {
                onSubmitClick(ev);
              }}
            >
              {submitButtonText}
            </Button>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center py-6">
        <div className="max-w-2xl mx-auto w-full">
          <div
            className={`flex flex-col text-hero-blue-700 text-sm mb-2 mt-4 ${
              !showDropdown ? "opacity-50" : ""
            }`}
          >
            {errors.length > 0 && (
              <ErrorSummary
                items={errors.map((error) => {
                  return { message: error.message };
                })}
                className="mb-4"
              />
            )}
            <label
              className="block text-hero-blue-700 text-sm font-bold mb-2"
              htmlFor={`care_nav_pathway_title`}
            >
              {translate("careNavigation.pathway.title")}
            </label>
            <TextInput
              defaultValue={parseString(tempPathway.title)}
              error={_.some(errors, (error) => error.field === "title")}
              disabled={!showDropdown}
              placeholder={translate("careNavigation.pathway.enter_title")}
              size="md"
              name={`care_nav_pathway[title]`}
              id={`care_nav_pathway_title`}
              onChange={(ev) => {
                setTempPathway({
                  ...tempPathway,
                  title: ev.currentTarget.value,
                });
              }}
            />
            {_.find(errors, (error) => error.field === "title") && (
              <div className="text-sm leading-5 font-normal text-red-700 mt-1">
                {_.find(errors, (error) => error.field === "title").message}
              </div>
            )}

            <label
              className="block text-hero-blue-700 text-sm font-bold mb-2 mt-5"
              htmlFor={`care_nav_pathway_summary`}
            >
              {translate("careNavigation.pathway.summary")}

              <span className="status-bubble font-medium mb-1 ml-2">
                {translate("careNavigation.pathway.optional")}
              </span>
            </label>

            <textarea
              className="shadow appearance-none border border-1 border-hero-blue-300 rounded w-full py-1.5 px-3 text-hero-blue-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
              name={`care_nav_pathway[summary]`}
              id={`care_nav_pathway_summary`}
              placeholder={translate(
                "careNavigation.pathway.summaryPlaceholder"
              )}
              spellCheck="false"
              disabled={!showDropdown}
              defaultValue={parseString(tempPathway.summary)}
            ></textarea>

            <input
              type="hidden"
              name={`care_nav_pathway[oc_submission_type]`}
              value={tempPathway.oc_submission_type || "other_request"}
            />

            <div className="flex items-center mt-4 mb-6">
              <Checkbox
                variant="white"
                id={`care_nav_pathway_suspended_visible`}
                checked={tempPathway.suspended}
                onChange={(ev) => {
                  setTempPathway({
                    ...tempPathway,
                    suspended: ev.currentTarget.checked,
                  });
                }}
                disabled={!showDropdown}
              />
              <label
                className="text-hero-blue-700 text-sm font-bold ml-2"
                htmlFor={`care_nav_pathway_suspended_visible`}
              >
                {translate("careNavigation.pathway.suspendAccessToPathway")}
              </label>
              <input
                type="hidden"
                value={`${tempPathway.suspended}`}
                name={`care_nav_pathway[suspended]`}
                id={`care_nav_pathway_suspended`}
              ></input>
            </div>

            <input
              className="mr-2"
              type="hidden"
              value={props.practiceGroupId}
              name={`care_nav_pathway[practice_group_id]`}
              id={`care_nav_pathway_practice_group_id`}
            ></input>

            {tempPathway.id && (
              <input
                className="mr-2"
                type="hidden"
                value={tempPathway.id}
                name={`care_nav_pathway[id]`}
                id={`care_nav_pathway_id`}
              ></input>
            )}
          </div>

          <div>
            <div
              className={`self-stretch justify-start items-center gap-6 inline-flex mb-3 ${
                !showDropdown ? "opacity-50" : ""
              }`}
            >
              <div className="grow shrink flex-col justify-start items-start gap-0.5 inline-flex">
                <div className="text-center text-hero-blue-700 text-xl font-bold leading-7">
                  {translate("careNavigation.pathway.options")}
                </div>
                <div className="self-stretch justify-start items-start gap-3 inline-flex">
                  <div className="grow shrink text-hero-blue-500 text-base font-normal leading-normal">
                    {translate("careNavigation.pathway.requestsSubheading")}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showDropdown && !someSubmissionTypesExist && (
            <div>
              <Dropdown>
                <DropdownTrigger asChild>
                  <Button
                    variant="white"
                    prepend={<span>&#43;</span>}
                    size="md"
                  >
                    {translate("careNavigation.pathway.addOption")}
                  </Button>
                </DropdownTrigger>
                {_.find(errors, (error) => error.field === "submissions") && (
                  <div className="text-sm leading-5 font-normal text-red-700 mt-1">
                    {
                      _.find(errors, (error) => error.field === "submissions")
                        .message
                    }
                  </div>
                )}
                <DropdownContent align="start">
                  <DropdownMenu>
                    <div className="max-h-72 overflow-y-auto external-apps-container">
                      <div>
                        <div className="flex flex-col">
                          <DropdownMenuItem
                            data-test="option-request"
                            onClick={() => {
                              onAddAnotherClick({
                                type: "form",
                                partner: null,
                              });
                            }}
                          >
                            {translate("careNavigation.pathway.request")}
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            data-test="option-url-website"
                            onClick={() => {
                              onAddAnotherClick({
                                type: "website",
                                partner: null,
                              });
                            }}
                          >
                            {translate("careNavigation.pathway.urlWebsite")}
                          </DropdownMenuItem>
                          {careNavPartners && (
                            <>
                              <div className="w-full border-b" />
                              <div className="text-xs leading-4 font-medium uppercase py-2 px-2 text-hero-blue-500">
                                {translate(
                                  "careNavigation.pathway.externalApps"
                                )}
                              </div>
                            </>
                          )}
                          <ExternalAppOptions
                            onAddAnotherClick={onAddAnotherClick}
                            saveAndAdd={false}
                            careNavPartners={careNavPartners}
                          />
                        </div>
                      </div>
                    </div>
                  </DropdownMenu>
                </DropdownContent>
              </Dropdown>
            </div>
          )}

          <div
            className={
              allDestroyedCheck(submissionTypes)
                ? ""
                : "border rounded-md hover:border-hero-blue-100"
            }
          >
            {submissionTypes.map((submissionType, index) => {
              return (
                <PathwayOption
                  onSaveClick={onSaveClick}
                  onCloseAllClick={onCloseAllClick}
                  onRemoveClick={onRemoveClick}
                  onOpenClick={onOpenClick}
                  onAddAnotherClick={onAddAnotherClick}
                  onCancelClick={onCancelClick}
                  submissionType={submissionType}
                  pathway={pathway}
                  index={index}
                  forms={forms}
                  key={`pathway-option-${index}`}
                  allClosed={allClosed}
                  practiceGroupId={props.practiceGroupId}
                  isPathwaySuspended={tempPathway?.suspended}
                  setting={setting}
                  createFormPath={props.createFormPath}
                  careNavPartners={careNavPartners}
                />
              );
            })}

            {someSubmissionTypesExist && (
              <div className="text-hero-blue-500">
                <div className="flex items-center gap-2 mb-1 p-4">
                  <Dropdown>
                    <DropdownTrigger asChild>
                      <Button
                        variant="white"
                        prepend={<span>&#43;</span>}
                        size="md"
                        className={!showDropdown && "opacity-50"}
                        disabled={!showDropdown}
                      >
                        {translate("careNavigation.pathway.addOption")}
                      </Button>
                    </DropdownTrigger>
                    <DropdownContent align="start">
                      <DropdownMenu>
                        <div className="max-h-72 overflow-y-scroll external-apps-container">
                          <div>
                            <div className="flex flex-col">
                              <DropdownMenuItem
                                onClick={() => {
                                  onAddAnotherClick({
                                    type: "form",
                                    partner: null,
                                  });
                                }}
                              >
                                {translate("careNavigation.pathway.request")}
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={() => {
                                  onAddAnotherClick({
                                    type: "website",
                                    partner: null,
                                  });
                                }}
                              >
                                {translate("careNavigation.pathway.urlWebsite")}
                              </DropdownMenuItem>
                              {careNavPartners && (
                                <>
                                  <div className="w-full border-b" />
                                  <div className="text-xs leading-4 font-medium uppercase py-2 px-2 text-hero-blue-500">
                                    {translate(
                                      "careNavigation.pathway.externalApps"
                                    )}
                                  </div>
                                </>
                              )}
                              <ExternalAppOptions
                                onAddAnotherClick={onAddAnotherClick}
                                saveAndAdd={false}
                                careNavPartners={careNavPartners}
                              />
                            </div>
                          </div>
                        </div>
                      </DropdownMenu>
                    </DropdownContent>
                  </Dropdown>
                </div>
              </div>
            )}
          </div>

          <div className="w-full flex justify-end items-end mt-10">
            <Button
              as="button"
              size="md"
              variant="primary"
              disabled={!showDropdown}
              type="submit"
              name="commit"
              data-test="create-pathway"
              value={submitButtonText}
              className="b b-primary mt-4"
              data-disable-with={submitButtonText}
              onClick={(ev) => {
                onSubmitClick(ev);
              }}
            >
              {submitButtonText}
            </Button>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
