import { Invoice } from "./types";
import { formatISODateTime } from "./datetime";

export function invoiceTransitionTimes(invoice: Invoice) {
  const legacyCreatedAt =
    invoice.stripe_metadata &&
    invoice.stripe_metadata["Hero legacy created at"];
  const createdAt = invoice.created_at;
  const finalizedAt = invoice.finalized_at;

  const formattedFinalizedAt =
    finalizedAt && formatISODateTime(finalizedAt, "short-month-name");
  const formattedCreatedAt = createdAt && formatISODateTime(createdAt, "short");
  const formattedLegacyCreatedAt =
    legacyCreatedAt && formatISODateTime(legacyCreatedAt, "short-month-name");

  const formattedCreatedOrIssuedAt =
    formattedLegacyCreatedAt || formattedFinalizedAt || formattedCreatedAt;

  return {
    formattedCreatedOrIssuedAt,
    formattedFinalizedAt,
    formattedCreatedAt,
    formattedLegacyCreatedAt,
    legacyCreatedAt,
    createdAt,
    finalizedAt,
  };
}
