import {
  Button,
  Checkbox,
  ErrorSummary,
  SelectMenu,
  Textarea,
  TextInput,
  Typography,
  useSearch,
} from "@herohealthsoftware/ui";
import ld from "lodash";
import React, { useState } from "react";

import Hero from "../../../lib/hero";
import { translate } from "../../../lib/i18n";
import { searchStripe, StripeSearchOptions } from "../../../lib/stripe";
import {
  Customer,
  PatientRecord,
  PatientRecordCustomer,
} from "../../../lib/types";
import * as Routes from "../../../routes";

type CustomerSettingsIndexProps = {
  setCustomer: (customer: Customer) => void;
  customer?: Customer;
  patientCustomer?: Customer;
  setPatientCustomer: (customer: Customer) => void;
  patient_record: PatientRecord;
  stripeBaseUrl: string;
};

type SaveAndCancelDisabledType = {
  billing_information: boolean;
  invoice_workflow: boolean;
  billing_note: boolean;
};
const billingNoteCharacterLimit = 250;

export default function CustomerSettingsIndex(
  props: CustomerSettingsIndexProps
) {
  const {
    invoice_setting_insurer,
    invoice_setting_authentication_code,
    invoice_setting_membership_number,
    invoice_setting_billing_note,
    invoice_setting_confidential = false,
    invoice_setting_diagnosis = false,
  } = props.patientCustomer.patient_record_customer || {};

  const initialPatientRecordCustomer = {
    invoice_setting_insurer: invoice_setting_insurer || "",
    invoice_setting_authentication_code:
      invoice_setting_authentication_code || "",
    invoice_setting_membership_number: invoice_setting_membership_number || "",
    invoice_setting_billing_note: invoice_setting_billing_note || "",
    invoice_setting_confidential: invoice_setting_confidential || false,
    invoice_setting_diagnosis:  invoice_setting_diagnosis || false,
  };

  const [patientRecordCustomer, setPatientRecordCustomer] = useState<
    Partial<PatientRecordCustomer>
  >(initialPatientRecordCustomer);
  const [initialCustomer, setInitialCustomer] = useState<
    Customer | null>(props.customer);

  const { onSearch, loading, results, resetSearch } = useSearch<
    Customer,
    StripeSearchOptions
  >(async (query, options) =>
    searchStripe<Customer>(query, { ...options, type: "customer" })
  );

  const defaultCustomerOptions = ld
    .chain([props.customer, ...results])
    .uniqBy("stripe_id")
    .map((customer: Customer) => ({
      label: customer.name,
      value: customer,
    }))
    .value();

  const [recordloading, setRecordLoading] = useState<boolean>(false);
  const [isSaveAndCancelButtonDisabled, setSaveAndCancelButtonDisabled] =
    useState<SaveAndCancelDisabledType>({
      billing_information: true,
      invoice_workflow: true,
      billing_note: true,
    });
  const [flashSuccess, setFlashSuccess] = useState<boolean>(false);
  const [flashError, setFlashError] = useState<boolean>(false);
  const [formType, setFormType] = useState<string>("");

  const updateDefaultCustomer = async (customer: Customer) => {
    const response = await Hero.fetch(
      Routes.partners_stripe_admins_settings_path(),
      {
        method: "POST",
        body: {
          settings: {
            patient_record: {
              id: props.patient_record.id,
              default_customer_stripe_id: customer.stripe_id,
            },
          },
        },
      }
    );

    if (response.ok) {
      setInitialCustomer(customer);
    }

    resetSearch();
  };

  const handleUpdateCustomerDetails = (fieldName: string, value: string) => {
    setFormType("billing_information");
    setSaveAndCancelButtonDisabled(() => ({
      ...isSaveAndCancelButtonDisabled,
      billing_information: false,
    }));
    setFlashSuccess(false);
    setPatientRecordCustomer({
      ...patientRecordCustomer,
      [fieldName]: value,
    });
  };

  const handleUpdateBillingNote = (fieldName: string, value: string) => {
    if (value.length <= billingNoteCharacterLimit) {
      setFormType("billing_note");
      setSaveAndCancelButtonDisabled(() => ({
        ...isSaveAndCancelButtonDisabled,
        billing_note: false,
      }));
      setFlashSuccess(false);
      setPatientRecordCustomer({
        ...patientRecordCustomer,
        [fieldName]: value,
      });
    }
  };

  const updatePatientRecordCustomerDetails = async () => {
    setRecordLoading(true);

    let patient_record_customer_params = [];

    switch (formType) {
      case "invoice_workflow":
        patient_record_customer_params = [
          "invoice_setting_confidential",
          "invoice_setting_diagnosis",
        ];
        break;
      case "billing_note":
        patient_record_customer_params = ["invoice_setting_billing_note"];
        break;
      case "billing_information":
        patient_record_customer_params = [
          "invoice_setting_insurer",
          "invoice_setting_authentication_code",
          "invoice_setting_membership_number",
        ];
        break;
    }

    const response = await Hero.fetch(
      Routes.partners_stripe_admins_patient_record_customer_path(
        props.patientCustomer.patient_record_customer.id
      ),
      {
        method: "PATCH",
        body: {
          patient_record_customer: ld.pick(
            patientRecordCustomer,
            patient_record_customer_params
          ),
        },
      }
    );
    if (response.ok) {
      const data = await response.json();

      props.setPatientCustomer({
        ...props.patientCustomer,
        patient_record_customer: data,
      });
      setSaveAndCancelButtonDisabled((prev) => ({
        ...prev,
        [formType]: true,
      }));
      setRecordLoading(false);
      setFlashSuccess(true);
      setFlashError(false);
    } else {
      setFormType("");
      setFlashError(true);
    }
  };

  const handleCancelChanges = () => {
    setPatientRecordCustomer({
      ...patientRecordCustomer,
      invoice_setting_membership_number:
        initialPatientRecordCustomer.invoice_setting_membership_number,
      invoice_setting_authentication_code:
        initialPatientRecordCustomer.invoice_setting_authentication_code,
      invoice_setting_insurer:
        initialPatientRecordCustomer.invoice_setting_insurer,
    });
    setSaveAndCancelButtonDisabled((prev) => ({
      ...prev,
      [formType]: true,
    }));
  };

  return (
    <div className="relative text-hero-blue-700">
      {props.patientCustomer && (
        <>
          <div className="text-base font-semibold text-hero-blue-700">
            {translate("partners.stripe.patientBillingInformation")}
          </div>
          <div className="text-sm text-hero-blue-500">
            {translate("partners.stripe.patientBillingInformationDescription")}
          </div>
          {flashError && (
            <div className="my-4">
              <ErrorSummary
                items={[
                  {
                    message: translate("partners.stripe.settingsError"),
                  },
                ]}
              />
            </div>
          )}
          <div className="flex flex-col gap-4 mt-3 mb-3">
            <TextInput
              className="form-control text optional mt-2"
              label="Insurer"
              value={patientRecordCustomer.invoice_setting_insurer}
              onChange={(e) =>
                handleUpdateCustomerDetails(
                  "invoice_setting_insurer",
                  e.target.value
                )
              }
            />
            <TextInput
              className="form-control text optional mt-2"
              label="Authentication code"
              value={patientRecordCustomer.invoice_setting_authentication_code}
              onChange={(e) =>
                handleUpdateCustomerDetails(
                  "invoice_setting_authentication_code",
                  e.target.value
                )
              }
            />
            <TextInput
              className="form-control text optional mt-2"
              label="Membership number"
              value={patientRecordCustomer.invoice_setting_membership_number}
              onChange={(e) =>
                handleUpdateCustomerDetails(
                  "invoice_setting_membership_number",
                  e.target.value
                )
              }
            />
            <div className="flex justify-end gap-2">
              {flashSuccess && formType === "billing_information" && (
                <div className="flex items-center gap-2 bg-green-50 text-xs text-hero-primary-900 font-semibold rounded-md px-2">
                  <div className="flex items-center">
                    <i className="icon-solid-check" />
                  </div>
                  {translate("partners.stripe.settingsSaved")}
                </div>
              )}
              <Button
                type="button"
                variant="white"
                onClick={handleCancelChanges}
                disabled={isSaveAndCancelButtonDisabled.billing_information}
              >
                {translate("base.cancel")}
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={() => {
                  setFormType("billing_information");
                  updatePatientRecordCustomerDetails();
                }}
                loading={formType == "billing_information" && recordloading}
                disabled={isSaveAndCancelButtonDisabled.billing_information}
              >
                {translate("base.save")}
              </Button>
            </div>
          </div>
        </>
      )}

      <div>
        <div className="text-base font-semibold text-hero-blue-700">
          {translate("partners.stripe.invoiceWorkflow")}
        </div>
        <div className="text-sm text-hero-blue-500 mb-5">
          {translate("partners.stripe.invoiceWorkflowDetails")}
        </div>
        <SelectMenu
          placeholder={translate("partners.stripe.selectADefaultCustomer")}
          searchPlaceholder={translate("partners.stripe.searchCustomers")}
          searchLoading={loading}
          selected={props.customer}
          options={defaultCustomerOptions}
          onSearch={onSearch}
          onSelect={(customer) => {
            if (customer) {
              setFormType("invoice_workflow");
              props.setCustomer(customer)
              setSaveAndCancelButtonDisabled((prev) => ({
                ...prev,
                invoice_workflow: false,
              }));
            }
          }}
          renderOption={(option) => (
            <div className="flex flex-col items-start">
              <div>{option.value.name}</div>
              <Typography size="xs" color="secondary" weight="normal">
                <div>{option.value.email || <>&mdash;</>}</div>
              </Typography>
            </div>
          )}
          onClose={resetSearch}
          className="relative"
          helpText={translate("partners.stripe.defaultCustomerSubtext")}
        />
        <div className="mt-4 flex items-start">
          <div className="mt-1">
            <Checkbox
              id={`invoice_setting_diagnosis`}
              variant="white"
              checked={patientRecordCustomer.invoice_setting_diagnosis}
              onChange={(e) => {
                setFormType("invoice_workflow");
                setSaveAndCancelButtonDisabled((prev) => ({
                  ...prev,
                  invoice_workflow: false,
                }));
                setFlashSuccess(false);
                setPatientRecordCustomer({
                  ...patientRecordCustomer,
                  invoice_setting_diagnosis: e.target.checked,
                });
              }}
            />
          </div>
          <div className="ml-2">
            <label className="text-hero-blue-700 text-sm font-bold">
              {translate("partners.stripe.requireDiagnosisOnInvoice")}
            </label>
            <p className="text-sm text-hero-blue-400">
              {translate("partners.stripe.diagnosisDetailsOnInvoice")}
            </p>
          </div>
        </div>

        <div className="mt-4 flex items-start">
          <div className="mt-1">
            <Checkbox
              id={`invoice_setting_confidential`}
              variant="white"
              checked={patientRecordCustomer.invoice_setting_confidential}
              onChange={(e) => {
                setFormType("invoice_workflow");
                setSaveAndCancelButtonDisabled((prev) => ({
                  ...prev,
                  invoice_workflow: false,
                }));
                setFlashSuccess(false);
                setPatientRecordCustomer({
                  ...patientRecordCustomer,
                  invoice_setting_confidential: e.target.checked,
                });
              }}
            />
          </div>
          <div className="ml-2">
            <label className="text-hero-blue-700 text-sm font-bold">
              {translate("partners.stripe.confidentialOnInvoice")}
            </label>
            <p className="text-sm text-hero-blue-400">
              {translate("partners.stripe.confidentialDetailsOnInvoice")}
            </p>
          </div>
        </div>
        <div className="flex justify-end gap-2 mt-4">
          {flashSuccess && formType === "invoice_workflow" && (
            <div className="flex items-center gap-2 bg-green-50 text-xs text-hero-primary-900 font-semibold rounded-md px-2">
              <div className="flex items-center">
                <i className="icon-solid-check" />
              </div>
              {translate("partners.stripe.settingsSaved")}
            </div>
          )}
          <Button
            disabled={isSaveAndCancelButtonDisabled.invoice_workflow}
            type="button"
            variant="white"
            onClick={() => {
              setPatientRecordCustomer({
                ...patientRecordCustomer,
                invoice_setting_confidential:
                  initialPatientRecordCustomer.invoice_setting_confidential,
                invoice_setting_diagnosis:
                  initialPatientRecordCustomer.invoice_setting_diagnosis,
              });
              setSaveAndCancelButtonDisabled((prev) => ({
                ...prev,
                [formType]: true,
              }));
              props.setCustomer(initialCustomer);
            }}
          >
            {translate("base.cancel")}
          </Button>
          <Button
            type="button"
            variant="primary"
            loading={formType == "invoice_workflow" && recordloading}
            disabled={isSaveAndCancelButtonDisabled.invoice_workflow}
            onClick={() => {
              setFormType("invoice_workflow");
              updatePatientRecordCustomerDetails();
              updateDefaultCustomer(props.customer);
            }}
          >
            {translate("base.save")}
          </Button>
        </div>
        <div className="mt-8">
          <div className="text-base font-semibold text-hero-blue-700">
            {translate("partners.stripe.billingNote")}
          </div>
          <div className="text-sm text-hero-blue-500 mb-5">
            {translate("partners.stripe.billingNoteDetails")}
          </div>
          <Textarea
            className={`form-control text min-h-24 flex-wrap flex-col optional mt-2 bg-white resize-y border border-gray-300 rounded-md`}
            id="invoice-billing-note"
            value={patientRecordCustomer.invoice_setting_billing_note}
            onChange={(e) => {
              handleUpdateBillingNote(
                "invoice_setting_billing_note",
                e.target.value
              );
            }}
          />
          <div className="text-xs text-gray-500 flex justify-end mt-2">
            {patientRecordCustomer.invoice_setting_billing_note?.length}/
            {billingNoteCharacterLimit}
          </div>
        </div>
        <div className="flex justify-end gap-2 mt-7">
          {flashSuccess && formType === "billing_note" && (
            <div className="flex items-center gap-2 bg-green-50 text-xs text-hero-primary-900 font-semibold rounded-md px-2">
              <div className="flex items-center">
                <i className="icon-solid-check" />
              </div>
              {translate("partners.stripe.settingsSaved")}
            </div>
          )}
          <Button
            disabled={isSaveAndCancelButtonDisabled.billing_note}
            type="button"
            variant="white"
            onClick={() => {
              setPatientRecordCustomer({
                ...patientRecordCustomer,
                invoice_setting_billing_note:
                  initialPatientRecordCustomer.invoice_setting_billing_note,
              });
              setSaveAndCancelButtonDisabled((prev) => ({
                ...prev,
                [formType]: true,
              }));
            }}
          >
            {translate("base.cancel")}
          </Button>
          <Button
            type="button"
            variant="primary"
            loading={formType == "billing_note" && recordloading}
            disabled={isSaveAndCancelButtonDisabled.billing_note}
            onClick={() => {
              setFormType("billing_note");
              updatePatientRecordCustomerDetails();
            }}
          >
            {translate("base.save")}
          </Button>
        </div>
      </div>
    </div>
  );
}
