import React, { useState, useEffect, useRef } from "react";
import OutcomeListing from "../shared/ListingCard";
import OutcomeDescription from "../shared/DescriptionCard";
import { PracticeGroupItem } from "../../lib/types";
import { translate } from "../../lib/i18n";
import SaveOutcomeModal from "../shared/SaveModal";
import RemoveOutcomeModal from "../shared/RemoveModal";
import {
  displayFlashMessage,
  displayErrorMessage,
} from "../shared/FlashMessages";
import IconOutcome from "./IconOutcome";
import {
  fetchRecords,
  getPageChange,
  createRecord,
  updateRecord,
  removeRecord,
} from "../../hooks/useApi";
import { ThemeProvider } from "@herohealthsoftware/ui";

interface OutcomeFormProps {
  practice_group_id: number;
  csrfToken: string;
}

const OutcomeForm: React.FC<OutcomeFormProps> = ({
  practice_group_id,
  csrfToken,
}) => {
  const [currentPage, setCurrentPage] = useState<number | null>(null);
  const [selectedOutcome, setSelectedOutcome] =
    useState<PracticeGroupItem | null>(null);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const flashMessageRef = useRef<HTMLDivElement | null>(null);
  const [paginatedRecord, setPaginatedRecord] =
    useState<PracticeGroupItem[]>(null);
  const [totalPages, setTotalPages] = useState(null);
  const [selectedRemoveOutcome, setSelectedRemoveOutcome] =
    useState<PracticeGroupItem | null>(null);
  const [totalEntries, setTotalEntries] = useState(null);
  const [nameError, setNameError] = useState<string>("");
  const teamParentContainer =
    flashMessageRef?.current?.closest(".admin_container");
  const flashMessageContent = isCreateMode
    ? translate("common.createdMessage")
    : translate("common.updatedMessage");
  const entriesPerPage = 10;

  const handleEditClick = (admin: PracticeGroupItem) => {
    setSelectedOutcome(admin);
  };

  const handleRemoveRecord = (admin: PracticeGroupItem) => {
    setSelectedRemoveOutcome(admin);
  };

  const handleCloseModal = () => {
    setIsCreateMode(false);
    setSelectedOutcome(null);
    setNameError("");
  };

  const handleRemoveAdminModal = () => {
    setSelectedRemoveOutcome(null);
  };

  const validateInputs = () => {
    if (!selectedOutcome?.name.trim()) {
      setNameError(translate("outcomes.outcomeForm.nameError"));
      return false;
    }

    return true;
  };

  const handleCreateRecord = async () => {
    const url = "/admins/outcomes";
    try {
      if (!validateInputs()) {
        return;
      }

      const { error, data } = await createRecord(
        url,
        selectedOutcome,
        practice_group_id,
        csrfToken
      );
      handleCloseModal();

      if (!error) {
        handleRemoveAdminModal();

        if (!data.ok) {
          displayErrorMessage(data.errors.message, teamParentContainer);
          return;
        } else {
          handlePageChange(1);
          displayFlashMessage(
            data.pg_item.name,
            teamParentContainer,
            flashMessageContent,
            selectedRemoveOutcome ? true : false
          );
        }
      } else {
        return false;
      }
    } catch (error) {
      return error;
    }
  };

  const handleUpdateRecord = async () => {
    const url = `/admins/outcomes/${selectedOutcome.id}`;
    try {
      if (!validateInputs()) {
        return;
      }

      const { error, data } = await updateRecord(
        url,
        selectedOutcome,
        practice_group_id,
        csrfToken
      );
      handleCloseModal();

      if (!error) {
        handleRemoveAdminModal();

        if (!data.ok) {
          displayErrorMessage(data.errors.message, teamParentContainer);
          return;
        } else {
          handlePageChange(currentPage);
          displayFlashMessage(
            data.pg_item.name,
            teamParentContainer,
            flashMessageContent,
            selectedRemoveOutcome ? true : false
          );
        }
      } else {
        return false;
      }
    } catch (error) {
      return error;
    }
  };

  const handleRemoveOutcomeRecord = async () => {
    const url = `/admins/outcomes/${selectedRemoveOutcome.id}`;
    try {
      const { error, data } = await removeRecord(url, csrfToken);

      if (!error) {
        handleRemoveAdminModal();

        if (!data.ok) {
          displayErrorMessage(data.errors.message, teamParentContainer);
        } else {
          lastPageRemoval();
          displayFlashMessage(
            selectedRemoveOutcome.name,
            teamParentContainer,
            flashMessageContent,
            selectedRemoveOutcome ? true : false
          );
        }
      } else {
        return false;
      }
    } catch (error) {
      return error;
    }
  };

  const lastPageRemoval = () => {
    const startIndex = (currentPage - 1) * entriesPerPage + 1;
    const shouldChangePage =
      currentPage === totalPages && startIndex === totalEntries;

    handlePageChange(shouldChangePage ? currentPage - 1 : currentPage);
  };

  const handlePageChange = async (newPage) => {
    const url = "/admins/outcomes/paginate";
    try {
      const { error, data } = await getPageChange(newPage, url);

      if (!error) {
        setCurrentPage(data.current_page);
        setPaginatedRecord(data.pg_items);
        setTotalPages(data.total_pages);
        setTotalEntries(data.total_entries);
      } else {
        return false;
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    const url = "/admins/outcomes/paginate";
    (async () => {
      try {
        const { error, data } = await fetchRecords(url);
        if (!error) {
          if (data.pg_items.length !== 0) {
            setCurrentPage(data.current_page);
            setPaginatedRecord(data.pg_items);
            setTotalPages(data.total_pages);
            setTotalEntries(data.total_entries);
          }
        } else {
          return false;
        }
      } catch (error) {
        return error;
      }
    })();
  }, []);

  return (
    <ThemeProvider>
      <div ref={flashMessageRef}>
        {currentPage ? (
          <OutcomeListing
            paginatedRecord={paginatedRecord}
            currentPage={currentPage}
            totalPages={totalPages}
            handleEditClick={handleEditClick}
            handleRemoveRecord={handleRemoveRecord}
            handlePageChange={handlePageChange}
            setIsCreateMode={setIsCreateMode}
            setSelectedAdmin={setSelectedOutcome}
            entriesPerPage={entriesPerPage}
            totalEntries={totalEntries}
            title={translate("outcomes.outcomeForm.outcome")}
            btn_title={translate("outcomes.outcomeForm.createOutcome")}
            isFlag={false}
          />
        ) : (
          <>
            <OutcomeDescription
              setIsCreateMode={setIsCreateMode}
              setSelectedAdmin={setSelectedOutcome}
              isFlag={false}
              title={translate("outcomes.outcomeForm.outcome")}
              no_record={translate("outcomes.outcomeForm.noOutcome")}
              description={translate("outcomes.outcomeForm.description")}
              desc_example={translate(
                "outcomes.outcomeForm.descriptionExample"
              )}
              btn_title={translate("outcomes.outcomeForm.createAnOutcome")}
              iconOutcome={<IconOutcome />}
            />
          </>
        )}
        {isCreateMode || selectedOutcome ? (
          <>
            <SaveOutcomeModal
              isCreateMode={isCreateMode}
              selectedAdmin={selectedOutcome}
              setNameError={setNameError}
              setSelectedAdmin={setSelectedOutcome}
              nameError={nameError}
              handleCloseModal={handleCloseModal}
              handleCreateRecord={handleCreateRecord}
              handleUpdateRecord={handleUpdateRecord}
              isFlag={false}
              title={
                isCreateMode
                  ? translate("outcomes.outcomeForm.createOutcome")
                  : translate("outcomes.outcomeForm.editOutcome")
              }
              name={translate("outcomes.outcomeForm.name")}
              close={translate("common.cancel")}
              save={
                isCreateMode
                  ? translate("outcomes.outcomeForm.createOutcome")
                  : translate("outcomes.outcomeForm.saveOutcome")
              }
            />
          </>
        ) : null}

        {selectedRemoveOutcome && (
          <RemoveOutcomeModal
            onClose={() => handleRemoveAdminModal()}
            onRemove={() => handleRemoveOutcomeRecord()}
            close={translate("common.cancel")}
            remove={translate("outcomes.outcomeForm.removeOutcome")}
            isFlag={false}
            descriptionIntro={translate(
              "outcomes.outcomeForm.removeDescriptionIntro"
            )}
          />
        )}
      </div>
    </ThemeProvider>
  );
};

export default OutcomeForm;
