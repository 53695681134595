import React from "react";
import { Typography, Checkbox } from "@herohealthsoftware/ui";
import { cx } from "class-variance-authority";
import { PropsFrom } from "../../../../lib/types";

export type AdditionalOptionProps = PropsFrom<typeof Checkbox> & {
  label: string;
  subtext?: string;
  onChange?: (event) => void;
  children?: React.ReactNode;
};

export default function AdditionalOption(props: AdditionalOptionProps) {
  return (
    <>
      <label
        htmlFor={props.id}
        className={cx([
          "flex flex-col",
          props.disabled && "opacity-50",
          props.className,
        ])}
      >
        <div className="flex gap-2 items-center">
          <Checkbox
            id={props.id}
            name={props.name}
            value={props.value}
            checked={props.checked}
            defaultChecked={props.defaultChecked}
            onChange={props.onChange}
            disabled={props.disabled}
          />

          <Typography size="sm" color="primary" weight="bold">
            <div>{props.label}</div>
          </Typography>
        </div>

        <Typography size="sm" color="light" weight="normal">
          <p className="pl-6">{props.subtext}</p>
        </Typography>
      </label>

      {props.checked && props.children}
    </>
  );
}
