import {
  Badge,
  Button,
  IconExclamation,
  Link,
  CustomerSearch,
  CreateCustomerFunction,
} from "@herohealthsoftware/ui";
import React, { useState } from "react";

import { createCustomer } from "../../../../lib/customer";
import { translate } from "../../../../lib/i18n";
import { searchStripe } from "../../../../lib/stripe";
import { Customer } from "../../../../lib/types";
import Toggle from "../../../atoms/Toggle";
import CustomerEntityCard from "../CustomerEntityCard";

export type CustomerInputProps = {
  customer?: Customer;
  defaultCustomer?: Customer;
  setCustomer: (customer: Customer) => void;
  name: string;
  defaultFieldName: string;
  defaultFieldValue: boolean;
  editable: boolean;
  stripe_base_url: string;
  isStatusOpenOrPaid?: boolean;
};

export default function CustomerInput(props: CustomerInputProps) {
  const [showCustomerSearch, setShowCustomerSearch] = useState(!props.customer);
  const [usePatientsDefaultCustomer, setUsePatientsDefaultCustomer] = useState(
    props.defaultCustomer &&
      props.defaultCustomer.stripe_id === props.customer?.stripe_id
  );

  const handleUsePatientsDefaultCustomerChange = () => {
    if (usePatientsDefaultCustomer) {
      setUsePatientsDefaultCustomer(false);
      setShowCustomerSearch(true);
      props.setCustomer(undefined);
    } else {
      setUsePatientsDefaultCustomer(true);
      props.setCustomer(props.defaultCustomer);
    }
  };

  const resetCustomerSearch = (customer) => {
    setShowCustomerSearch(!customer);
    setUsePatientsDefaultCustomer(
      !!props.defaultCustomer?.stripe_id
        ? customer?.stripe_id === props.defaultCustomer?.stripe_id
        : false
    );
  };

  const handleResultClick = (customer) => {
    props.setCustomer(customer);
    resetCustomerSearch(customer);
  };

  const searchCustomers = async (query) => {
    // HACK: fix the Rails Customer so it works as a UI library Customer
    const customers = await searchStripe<any>(query, {
      type: "customer",
    });

    customers.data = customers.data.map((customer) => {
      return { ...customer, id: customer.stripe_id };
    });

    return customers;
  };

  const handleCreateCustomer: CreateCustomerFunction = async (customer) => {
    const response = await createCustomer(customer.email, customer.name);
    let errors = null;

    if (response.error) {
      if (response.error.code === "email_invalid") {
        errors = {
          email: "Please provide a valid email address",
        };
      } else {
        errors = {
          other: response.error.message,
        };
      }

      return { errors };
    }

    props.setCustomer(response);
    return response;
  };

  return (
    <>
      {props.defaultCustomer && (
        <Toggle
          checked={usePatientsDefaultCustomer}
          onChange={handleUsePatientsDefaultCustomerChange}
          disabled={props.isStatusOpenOrPaid}
        >
          {translate("partners.stripe.usePatientDefaultStripeCustomer")}
          <span className="font-normal text-hero-blue-400 whitespace-nowrap flex gap-1">
            {translate("base.default")}:
            <Link variant="secondary" className="!text-hero-blue-400">
              <a
                href={`${props.stripe_base_url}/customers/${props.defaultCustomer.stripe_id}`}
                target="_blank"
              >
                {props.defaultCustomer.name}
              </a>
            </Link>
            {!props.defaultCustomer.email ? (
              <Badge size="sm" variant="warning" icon={<IconExclamation />}>
                {translate("partners.stripe.noEmailAddress")}
              </Badge>
            ) : (
              `(${props.defaultCustomer.email})`
            )}
          </span>
        </Toggle>
      )}

      <input
        type="hidden"
        name={props.name}
        value={props.customer ? props.customer?.stripe_id : ""}
      />

      {usePatientsDefaultCustomer || (
        <div className="relative m-0">
          {showCustomerSearch && (
            <CustomerSearch
              customer={{ ...props.customer, id: props.customer?.stripe_id }}
              onSelect={handleResultClick}
              onReset={() => resetCustomerSearch(props.customer)}
              onCreateCustomer={handleCreateCustomer}
              fetcher={searchCustomers}
              className="md:w-1/2 my-4"
              messages={{
                label: translate("partners.stripe.customer"),
                placeholder: translate("partners.stripe.searchCustomers"),
                loading: translate("partners.stripe.loadingStripeCustomers"),
                noResults: translate(
                  "partners.stripe.noMatchingCustomersFound"
                ),
                createNewCustomer: translate(
                  "partners.stripe.createNewCustomer"
                ),
              }}
            />
          )}

          {!showCustomerSearch && (
            <>
              <CustomerEntityCard
                customer={props.customer}
                labelAction={
                  props.editable && (
                    <Button
                      variant="white"
                      size="xs"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setShowCustomerSearch(true);
                      }}
                    >
                      {translate("base.change")}
                    </Button>
                  )
                }
              />

              {props.editable && props.customer?.email && (
                <div className="form-group boolean">
                  <input
                    type="hidden"
                    name={props.defaultFieldName}
                    value="0"
                  />

                  <label
                    className="m-0 boolean optional flex items-center font-medium text-hero-blue-700"
                    htmlFor="set_default_customer"
                  >
                    <input
                      type="checkbox"
                      value="1"
                      name={props.defaultFieldName}
                      id="set_default_customer"
                      className="boolean mr-2"
                      defaultChecked={props.defaultFieldValue}
                    />
                    {translate("partners.stripe.setDefaultCustomer")}
                  </label>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
