import { DateTime } from "luxon";

export type DateTimeFormat =
  | "dob"
  | "date"
  | "short"
  | "short-month-name"
  | "short-month-name-date"
  | "short-dashed-datetime"
  | "short-dashed-date"
  | "time";

export function formatISODateTime(isoString: string, format: DateTimeFormat) {
  const isoStringISO8601 = isoString.includes("T")
    ? isoString
    : isoString.replace(" ", "T");
  const datetime = DateTime.fromISO(isoStringISO8601).setLocale("gb");

  if (format === "dob") {
    const age = DateTime.now().diff(datetime, ["years"]).toObject().years;
    const formatted = datetime.toFormat("dd/MM/yyyy");

    return `${formatted} (${Math.floor(age)}y)`;
  }

  if (format === "date") {
    return datetime.toFormat("dd/MM/yyyy");
  }

  if (format === "short") {
    return datetime.toFormat("dd MMM yyyy 'at' HH:mm");
  }

  if (format === "short-month-name") {
    return datetime.toFormat("d MMM y HH:mm");
  }

  if (format === "short-month-name-date") {
    return datetime.toFormat("dd MMM yyyy");
  }

  if (format === "short-dashed-datetime") {
    return datetime.toFormat("dd-MMM-y HH:mm");
  }

  if (format === "short-dashed-date") {
    return datetime.toFormat("dd-MMM-y");
  }

  if (format === "time") {
    return datetime.toFormat("HH:mm");
  }

  return datetime.toLocaleString(DateTime.DATETIME_FULL, "gb");
}
