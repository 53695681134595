import React from "react";
import { translate } from "../../lib/i18n";
import {
  Button,
  ButtonGroup,
  IconPencil,
  IconTrash,
  IconPlus1,
} from "@herohealthsoftware/ui";
import { renderPagination, renderEntryRange } from "./Pagination";
import { AdminRecord, PracticeGroupItem } from "../../lib/types";

interface ListingCardProps {
  paginatedRecord: PracticeGroupItem[];
  membersCounts?: Record<string, number>;
  currentPage: number;
  totalPages: number;
  handleEditClick: (admin: PracticeGroupItem) => void;
  handleRemoveRecord: (admin: PracticeGroupItem) => void;
  handlePageChange: (newPage: number) => void;
  setIsCreateMode: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAdmin: React.Dispatch<React.SetStateAction<PracticeGroupItem>>;
  setSelectedMembers?: React.Dispatch<React.SetStateAction<AdminRecord[]>>;
  entriesPerPage: number;
  totalEntries: number;
  isFlag?: boolean;
  title: string;
  btn_title: string;
  membersTitle?: string;
}

const ListingCard: React.FC<ListingCardProps> = ({
  paginatedRecord,
  membersCounts,
  currentPage,
  totalPages,
  handleEditClick,
  handleRemoveRecord,
  handlePageChange,
  setIsCreateMode,
  setSelectedAdmin,
  setSelectedMembers,
  entriesPerPage,
  totalEntries,
  isFlag = true,
  title,
  btn_title,
  membersTitle,
}) => {
  return (
    <div>
      <div className="w-full h-30 py-5 self-stretch flex flex-col justify-start items-start gap-4">
        <div className="text-center text-hero-blue-700 text-2xl font-bold">
          {title}
        </div>
        <Button
          variant="primary"
          type="button"
          onClick={() => {
            setIsCreateMode(true);
            setSelectedAdmin(null);
            isFlag && setSelectedMembers([]);
          }}
        >
          <div className="flex justify-center align-items-center gap-2 text-hero-blue-700">
            <div>
              <IconPlus1 />
            </div>
            <div>{btn_title}</div>
          </div>
        </Button>
      </div>
      {paginatedRecord?.map((pg_item: PracticeGroupItem) => {
        const matchingKey = membersCounts
          ? Object.keys(membersCounts).find((key) => Number(key) === pg_item.id)
          : undefined;
        const memberCount = matchingKey
          ? membersCounts[matchingKey]
          : undefined;

        return (
          <div
            key={pg_item.id}
            className="w-full flex justify-between items-center hover:bg-hero-blue-50 hover:border-hero-blue-500 border border-hero-blue-200 rounded-lg px-5 py-4 mb-2 cursor-pointer"
            onClick={() => handleEditClick(pg_item)}
          >
            <div className="flex flex-col gap-1">
              <div className="text-hero-blue-700 text-base font-bold">
                {pg_item.name}
              </div>
              {isFlag && (
                <div className="text-hero-blue-400 text-sm">
                  {memberCount} {membersTitle}
                </div>
              )}
            </div>
            <ButtonGroup>
              <Button
                variant="white"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditClick(pg_item);
                }}
              >
                <div className="flex justify-center items-center gap-2">
                  <IconPencil />
                </div>
              </Button>
              <Button
                variant="destructive"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveRecord(pg_item);
                }}
              >
                <div className="flex justify-center items-center gap-2">
                  <IconTrash />
                </div>
              </Button>
            </ButtonGroup>
          </div>
        );
      })}
      <div className="w-full flex gap-2 align-items-center">
        <div className="py-2">
          <button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            className={`border border-hero-blue-200 rounded-l-md px-3 py-1 ${
              currentPage === 1 ? "text-hero-blue-300" : ""
            }`}
          >
            {translate("common.previous")}
          </button>
          {renderPagination(totalPages, currentPage, handlePageChange)}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`border border-hero-blue-200 rounded-r-md px-3 py-1 ${
              currentPage === totalPages ? "text-hero-blue-300" : ""
            }`}
          >
            {translate("common.next")}
          </button>
        </div>
        <div>
          {renderEntryRange(
            currentPage,
            entriesPerPage,
            totalPages,
            totalEntries
          )}
        </div>
      </div>
    </div>
  );
};

export default ListingCard;
