import {
  IconExclamationCircle,
  Modal,
  ModalBody,
  ModalClose,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  Typography,
} from "@herohealthsoftware/ui";
import ld from "lodash";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { formatISODateTime } from "../../../../lib/datetime";
import { translate } from "../../../../lib/i18n";
import { EmisItem } from "../../../../lib/types";
import { EmisItemsInput } from "./EmisItemsInput";

type EmisItemsModalProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSubmitEmisItems: (emisItems: EmisItem[]) => void;
  emisItemsPath: string;
};

export function EmisItemsModal(props: EmisItemsModalProps) {
  const [loading, setLoading] = useState(true);
  const [emisItems, setEmisItems] = useState<EmisItem[]>([]);

  const fetchEmisItems = async () => {
    const response = await fetch(props.emisItemsPath);
    let data = await response.json();
    data = data || [];
    data = data.map((item, index) => ({ ...item, index }));

    setEmisItems(data);
    setLoading(false);
  };

  const updateEmisItem = (item) => {
    const items = emisItems.slice();
    items[item.index] = item;

    setEmisItems(items);
  };

  useEffect(() => {
    if (props.open) {
      fetchEmisItems();
    } else {
      setLoading(true);
      setEmisItems([]);
    }
  }, [props.open]);

  const isDisabled = (items) => {
    const complete = items.filter((item) => item.invoiceItem || item.product);
    const pending = items.filter(
      (item) => item.selected && !(item.invoiceItem || item.product)
    );

    return complete.length < 1 || pending.length > 0;
  };

  const groupItemsByDate = (items): Array<[string, EmisItem[]]> => {
    return ld
      .chain(items)
      .groupBy("date")
      .entries()
      .sortBy(([_, date]) => DateTime.fromISO(date))
      .value();
  };

  return (
    <Modal open={props.open} onOpenChange={props.onOpenChange}>
      <ModalContent size="lg">
        <ModalHeader
          title={translate("partners.stripe.addItemsFromEmis")}
          lead={translate("partners.stripe.selectAndMatchEmisWithStripe")}
        />
        <ModalBody>
          {loading ? (
            <div className="flex items-center gap-2">
              <Spinner className="h-4 w-4 fill-hero-blue-700" />
              <div>{translate("partners.stripe.loadingEmisItems")}</div>
            </div>
          ) : emisItems.length > 0 ? (
            <ul className="flex flex-col gap-4">
              {groupItemsByDate(emisItems).map(([date, emisItems]) => (
                <div
                  key={`emis-item-group-${date}`}
                  className="flex flex-col gap-2"
                >
                  <Typography
                    size="xs"
                    weight="semibold"
                    color="secondary"
                    className="uppercase"
                  >
                    <p>{formatISODateTime(date, "short-dashed-date")}</p>
                  </Typography>

                  {emisItems.map((emisItem) => (
                    <EmisItemsInput
                      key={`emis-item-${emisItem.index}`}
                      name={`emis-item-${emisItem.index}`}
                      item={emisItem}
                      onChange={(item) => updateEmisItem(item)}
                    />
                  ))}
                </div>
              ))}
            </ul>
          ) : (
            <div className="flex items-center gap-2">
              <div className="h-4 w-4">
                <IconExclamationCircle />
              </div>

              <div className="text-hero-blue-500">
                {translate("partners.stripe.noEmisItemsFound")}
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="flex justify-end">
          <ModalClose className="b b-outline">
            {translate("base.cancel")}
          </ModalClose>

          <button
            className="b b-primary"
            onClick={() => {
              props.onOpenChange(false);
              props.onSubmitEmisItems(emisItems);
            }}
            disabled={isDisabled(emisItems)}
          >
            {translate("partners.stripe.addItems")}
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
