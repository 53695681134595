import React from "react";
import { cx } from "class-variance-authority";

export type RadioProps = {
  id?: string;
  name: string;
  value: string;
  label?: React.ReactNode;
  subtext?: string;
  checked?: boolean;
  onChange?: (event) => void;
  className?: string;
};

const Radio = ({
  id,
  name,
  value,
  label,
  subtext,
  checked,
  onChange,
  className,
}: RadioProps) => {
  return (
    <label htmlFor={id} className={cx("flex gap-2 m-0", className)}>
      <input
        type="radio"
        name={name}
        id={id}
        value={value}
        checked={checked}
        className="icon-selected-indicator appearance-none my-1 w-4 h-4
          border border-hero-blue-300 rounded-full bg-white hover:border-2
          hover:border-hero-primary-700 focus:outline-none focus:ring
          focus:ring-hero-outline focus:ring-offset-2
          focus:border-hero-primary-700 focus:border-2
          checked:bg-hero-primary-500 checked:border-hero-primary-700"
        onChange={onChange}
      ></input>

      {(label || subtext) && (
        <div>
          {typeof label === "string" ? (
            <div className="text-base leading-6 font-bold text-hero-blue-700">
              {label}
            </div>
          ) : (
            label
          )}
          {subtext && (
            <div className="text-sm leading-5 font-normal text-hero-blue-400">
              {subtext}
            </div>
          )}
        </div>
      )}
    </label>
  );
};

export default Radio;
