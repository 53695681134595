import { IconCalendar } from "@herohealthsoftware/ui";
import React, { forwardRef, useState, ReactNode } from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";

// NOTE: styled in app/assets/stylesheets/vendor/react-datepicker.scss

type DatePickerProps = Partial<ReactDatePickerProps> & {
  name?: string;
  id?: string;
  defaultValue?: string;
  onChange?: (date: Date) => void;
  disabled?: boolean;
};

export default function DatePicker(props: DatePickerProps) {
  const [date, setDate] = useState<Date | null | undefined>(
    props.defaultValue ? new Date(props.defaultValue) : new Date()
  );

  const onChange = (date: Date) => {
    setDate(date);
    props.onChange && props.onChange(date);
  };

  return (
    <ReactDatePicker
      name={props.name}
      id={props.id}
      selected={date}
      onChange={onChange}
      calendarStartDay={1}
      dateFormat="dd/MM/yyyy"
      customInput={<DatePickerInput disabled={props.disabled} />}
      disabledKeyboardNavigation
      disabled={props.disabled}
    />
  );
}

type DatePickerInputProps = {
  value?: string;
  name?: string;
  onClick?: () => void;
  disabled?: boolean;
};

const DatePickerInput = forwardRef<HTMLDivElement, DatePickerInputProps>(
  ({ name, value, onClick, disabled }, ref): ReactNode => (
    <div className="relative cursor-pointer" onClick={onClick} ref={ref}>
      <input
        type="text"
        name={name}
        value={value}
        className={`form-control cursor-pointer !text-hero-blue-400 ${disabled && "bg-hero-blue-50"}`}
        readOnly
      />

      <div
        className="text-hero-blue-500 absolute transform z-20
          top-1/2 -translate-y-2/3 right-2 h-4 w-5 mr-1"
      >
      {
        disabled ? (
          <i className="icon-calendar-disabled"></i>
        ) : (
          <IconCalendar/>
        )
      }
      </div>
    </div>
  )
);
